.client-finder-tooltip {
  cursor: pointer;
  display: inline-block;
  margin: 0 1rem;
  vertical-align: text-bottom;
  position: relative;

  &:hover {
    .client-finder-tooltip-content {
      opacity: 1;
    }
  }

  &-info-icon {
    font-size: 1.6rem;
    display: block;
    color: rgba(#100b28, 0.54);
  }
  &.choose-step-tooltip {
    .client-finder-tooltip-content {
      width: 16rem;
      padding-left: 11px;
      padding-right: 11px;
      font-size: 12px;
      .break-line{
        display: block;
      }
      @media screen and (max-width: 1919px) and (min-width: 980px) {
        font-size: 11px;
        .break-line{
          display: inline;
        }
      }
    }
  }

  &-content {
    opacity: 0;
    pointer-events: none;
    position: absolute;
    padding: 0.8rem 1.5rem;
    transition: opacity 240ms ease-in-out;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: calc(100% + 1.5rem);
    background-color: #f7f7f7;
    text-align: center;
    width: 14rem;
    border-radius: 0.4rem;
    filter: drop-shadow(0 10px 25px rgba(#000000, 0.3));
    font-weight: 510;
    &.dark {
      color: #ffffff;
      background-color: rgba(var(--background), 1);
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 1.6rem;
      height: 1.6rem;
      left: 0;
      right: 0;
      bottom: -0.8rem;
      margin: 0 auto;
      transform: rotate(45deg);
      background-color: inherit;
    }
  }
}
