.pdf-confirmation-wrapper {
  width: 100%;
  height: 2500px;
  background-color: #fcfcfc;
  color: rgba(var(--background), 1);
  display: none;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  //esi dnes kereva pdf i ktor@

  //display: block;
  //position: fixed;
  //left: 0;
  //top: 100%;
  //height: 100% !important;
  //width: 100% !important;
  //top: 0;
  //z-index: 999;
  //overflow: auto;

  > div {
    min-width: 1780px;
  }

  .pdf-confirmation-flex-container {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .header {
    position: static;
    height: 15rem;
    padding: 0;
    box-shadow: none;
    justify-content: center;

    .header-content{
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .header-column-right {
      display: none;
    }

    .logo-img {
      max-height: 74px;
      max-width: 100%;
      width: auto;
      height: auto;
    }
  }

  .footer {
    padding: 4.5rem 0;
    text-align: left;
    margin-top: auto;

    .container {
      max-width: 100%;
      .footer-inner{
        justify-content: center;
      }
    }
  }

  .copyright-text {
    text-align: center;
  }

  .copyright-text-info {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }

  .copyright-text-tm {
    transform: scale(1.3);
  }
}


.pdf-confirmation-content {
  text-align: center;
  margin-bottom: 300px;
}

.pdf-confirmation-content-inner {
  background-color: rgba(var(--background), 1);
  padding: 72px;
  height: 664px;
  margin-bottom: 386px;
}

.pdf-confirmation-cnt {
  width: 894px;
  margin: 0 auto;
}

.pdf-confirmation-title {
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 7.2px;
  font-weight: bold;
  color: rgba(var(--brand-color), 1);
  text-transform: uppercase;
  margin-bottom: 24px;
}

.pdf-confirmation-text {
  font-size: 80px;
  line-height: 101px;
  color: #ffffff;
  font-weight: 500;
  padding-bottom: 53px;
}

.pdf-confirmation-card {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  &:before {
    content: "";
    z-index: -1;
    display: block;
    position: absolute;
    left: 0;
    top: 60.5%;
    width: 100%;
    height: 60.5%;
    background-color: #9B9EA3;
  }
}

.pdf-confirmation-card-inner {
  background-color: #ffffff;
  padding: 64px;
  z-index: 10;
  // crash pdf need find new way 
  // box-shadow: 0 8px 0 0 rgba(var(--brand-color), 1), inset;
}

.pdf-confirmation-card-type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 120px;
  border: 1px solid rgba(var(--brand-color), 1);
  min-width: 576px;
  padding: 0 32px;
  margin-bottom: 56px;
}

.pdf-confirmation-card-type-column {
  display: flex;
  align-items: center;
  flex: 0 0 auto;
  white-space: nowrap;

  > i {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    color: #ffffff;
    margin-right: 20px;
    width: 54px;
    height: 54px;
    border-radius: 50%;
    background-color: rgba(var(--brand-color), 1);
  }

  > span {
    font-size: 40px;
    line-height: 44px;
    color: rgba(var(--brand-color), 1);
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 1.6px;
  }

  > p {
    font-size: 72px;
    line-height: 76px;
    letter-spacing: 1.15px;
    color: rgba(var(--background), 1);
    font-weight: bold;
  }

  &:first-child {
    padding-right: 20px;
  }
}

.pdf-confirmation-card-row {
  width: 100%;
  white-space: nowrap;

  > li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 26px;
    line-height: 30px;
    padding-right: 20px;
    font-weight: 500;

    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }

  span {
    color: rgba(#100B28, 0.74);
  }

  p {
    color: rgba(#100B28, 0.9);
    padding-left: 20px;
  }
}

.pdf-confirmation-info-text {
  display: block;
  color: rgba(#100B28, 0.74);
  width: 846px;
  margin: 0 auto;
  text-align: center;
  font-size: 36px;
  line-height: 64px;
  word-break: break-word;
}
