.message-container {
  position: fixed;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: #5A5A5A #DEDEDE;
  scrollbar-width: thin;
  left: 0;
  top: 0;
  background-color: rgba(#100B28, 0.16);
  z-index: 999;
  padding: 1.2rem;
  animation: message-container 240ms ease-in-out;
}

@keyframes message-container {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.message {
  display: flex;
  max-width: 40.6rem;
  width: 100%;
  padding: 1.2rem;
  margin: 0 auto 2rem auto;
  background-color: #ffffff;
  box-shadow: 0 8px 16px rgba(#2D2F31, 0.12);
  border-radius: 8px;
  opacity: 0;
  animation: message 360ms ease-in-out forwards;
  &.error {
    .message-icon {
      background-color: rgba(var(--danger-color), 1);;
    }
  }
  &.success {
    .message-icon {
      background-color: #00bb00;
    }
  }
}

@keyframes message {
  0% {
    opacity: 0;
    transform: translate(0, 1rem);
  }

  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.message-icon {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  font-size: 1rem;
  margin-right: 1rem;
  border-radius: 50%;
  color: #ffffff;
  cursor: pointer;
}

.message-text {
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: rgba(var(--background), 1);
}