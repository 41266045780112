.select-your-goal-step {
  width: 100%;
}

.select-your-goal-step-inner {
  width: 100%;
  height: 100%;
  //overflow-x: hidden;
  //overflow-y: auto;
}
.select-your-goal-form {
  width: 100%;
  max-width: 83.2rem;

  .pre-line-large-screen {
    @media screen and (max-width: 1919px) {
      display: none;
    }
  }
  @media screen and (max-width: 1919px) and (min-width: 768px) {
    max-width: 73rem;
  }
}

.select-your-goal-label {
  display: block;
  width: 100%;
  margin-bottom: 3.2rem;
  position: relative;
  cursor: pointer;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}
.select-your-goal-label-head {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 1.6rem;
}
.select-your-goal-label-text {
  flex: 1;
}
.select-your-goal-name {
  font-size: 1.8rem;
  line-height: 2.3rem;
  color: rgba(var(--background), 1);
  font-weight: 600;
}
.select-your-goal-info {
  font-size: 1.8rem;
  line-height: 3.4rem;
  color: rgba(var(--background), 1);
}
