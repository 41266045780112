.your-ad-details {
  display: grid;
  grid-template-rows: auto 11fr;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  position: relative;
  //animation: your-ad 240ms;

  &:only-child {
    grid-row: span 2;
  }

  .loader {
    z-index: 100;
    position: fixed;

    & ~ div {
      opacity: 0.3;
      pointer-events: none;
    }
  }
}

@keyframes your-ad {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.your-ad-details-head {
  width: 100%;
  background-color: rgba(var(--background), 1);
  padding: 2.4rem 2.4rem 2.7rem 2.4rem;
}

.your-ad-details-head-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.your-ad-details-head-inner-column {
  min-width: 1rem;
  color: #ffffff;
}

.your-ad-details-head-address {
  font-size: 2.4rem;
  line-height: 3rem;
  word-break: break-word;
  margin-bottom: 1.6rem;
  font-style: normal;
  text-transform: capitalize;
  font-weight: 600;
}

.your-ad-details-head-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  .preview-your-ad-block.single-image {
    .preview-your-ad-card-img {
      height: 100% !important;
      transform: initial !important;
    }
  }
}

.your-ad-details-head-info-column {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 4.3rem;
  min-width: 1rem;
  word-break: break-word;
  &:not(:last-child) {
    margin-right: 8.6rem;
  }
}

.your-ad-details-head-info-title {
  display: block;
  font-size: 1.2rem;
  line-height: 1.5rem;
  margin-bottom: 0.9rem;
  color: rgba(var(--brand-color), 1);
  font-weight: 510;
}

.your-ad-details-head-info-title-text {
  text-transform: capitalize;
  font-size: 1.5rem;
  line-height: 1.9rem;

  &.lowerCase{
    text-transform: initial;
  }
}

.your-ad-details-head-link {
  color: #ffffff;
  font-size: 1.5rem;
  line-height: 1.9rem;
  text-transform: capitalize;
  border-bottom: 0.1rem solid #ffffff;
  padding-bottom: 0.1rem;
  cursor: pointer;
}

.your-ad-details-head-buttons {
  display: flex;
  align-items: center;
}

.your-ad-details-head-button {
  // padding: 2.4rem 1.8rem 1.8rem 2.4rem;
  border: none;
  width: 26rem;
  height: 5.6rem;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 0.32px;
  background-color: #ffffff;
  color: rgba(var(--background), 1);
  font-weight: 400;
  transition: background-color 240ms, color 240ms;
  cursor: pointer;
  margin-right: 2rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  //
  //&:hover {
  //  background-color: #ffffff;
  //  color: rgba(var(--background), 1);
  //}

  &:active {
    transform: scale(0.98);
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
.your-ad-details-content-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 3.6rem 0;
}
.back-to-all-ads-mobile-buttons {
  display: grid;
  width: 100%;
  align-items: center;
  grid-template-columns: repeat(auto-fill, minmax(24.4rem, 1fr));
  position: fixed;
  top: 6.7rem;
  left: 0;
  grid-gap: 1rem;
  background-color: #ffffff;
  padding: 2.4rem;
  box-shadow: 0 2rem 2rem 0 rgba(#000000, 0.17);
  border-top: 0.1rem solid #f0eff2;
  transition: opacity 240ms;
  z-index: 400;
  pointer-events: none;
  opacity: 0;
}
.back-to-all-ads-mobile-button {
  outline: none;
  border: none;
  height: 5.6rem;
  padding: 0 1rem;
  width: 100%;
  background-color: rgba(var(--background), 1);
  color: #ffffff;
  font-size: 1.6rem;
  line-height: 2rem;
  &:active {
    transform: scale3d(0.98, 0.98, 0.98);
  }
}
.share-metrics-hidden-button-mobile {
  display: none;
  outline: none;
  background-color: rgba(var(--background), 1);
  padding: 1rem;
  border: none;
  width: 100%;
  height: 5.6rem;
  font-size: 1.5rem;
  line-height: 2rem;
  text-transform: capitalize;
  letter-spacing: 0.3px;
  color: #ffffff;
  cursor: pointer;
  margin-right: 4rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 2.4rem;

  &:active {
    transform: scale(0.98);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.add-preview-hide-mobile {
  display: none;
  outline: none;
  background-color: #ffffff;
  padding: 1rem;
  border: none;
  width: 100%;
  height: 4rem;
  font-size: 1.5rem;
  line-height: 2rem;
  text-transform: capitalize;
  color: rgba(var(--background), 1);
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 600;
  margin: 2.4rem 0 2rem 0;

  &:active {
    transform: scale(0.98);
  }
}

.your-ad-details-download-button {
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  transition: transform 240ms;

  &:hover {
    .analytics-download-button-text {
      color: rgba(#ffffff, 0.8);
    }
  }

  &:active {
    transform: translate(0, 0.6rem);
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.your-ad-details-download-button-icon {
  font-size: 5.6rem;
  display: block;
  //margin-bottom: 0.6rem;
  color: #ffffff;
}

.your-ad-details-download-button-text {
  font-size: 1.2rem;
  line-height: 2rem;
  text-transform: capitalize;
  letter-spacing: 0.24px;
  color: #ffffff;
  transition: color 240ms;
}

.your-ad-details-content {
  padding: 0 2.4rem;

  > .container {
    height: 100%;
  }
}

.your-ad-details-content-title {
  font-size: 2.4rem;
  line-height: 3rem;
  color: rgba(var(--background), 1);
  font-weight: 600;
  text-transform: capitalize;
}

.back-to-all-ads-block {
  flex: 0 0 auto;
}

.back-to-all-ads-btn {
  outline: none;
  background: none;
  border: none;
  font-size: 1.6rem;
  line-height: 2rem;
  cursor: pointer;
  padding: 0;
  color: rgba(var(--brand-color), 1);
  border-bottom: 0.1rem solid rgba(var(--brand-color), 1);
}

.your-ad-details-content-inner {
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 11fr;
  height: 100%;
}

.your-ad-details-container {
  height: 100%;
  position: relative;
}

.your-ad-details-container-scrollable {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.your-ad-details-info-row {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  grid-gap: 3.2rem;
  justify-content: center;
  width: 100%;
  margin-bottom: 3rem;
}

.your-ad-details-info-column {
  min-height: 12rem;
  background-color: #ffffff;
  box-shadow: 0 1rem 2.5rem #f0f0f0;
  padding: 3rem 4rem 3.5rem 4rem;
  outline: 0.1rem solid #efefef;
  outline-offset: -0.1rem;

  &.ad-details {
    padding-top: 0;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    height: 120px;
    @media screen and (max-width: 1919px) {
      height: 99px;
    }
    @media screen and (max-width: 979px) {
      height: 120px;
    }
    @media screen and (max-width: 767px) {
      height: 142px;
    }
  }
}

.reach-and-clicks {
  display: flex;
  align-items: center;
  width: 100%;
}

.reach-and-clicks-column {
  flex: 1;
  padding-right: 1rem;
}

.reach-and-clicks-title {
  font-size: 2.4rem;
  line-height: 3rem;
  color: rgba(var(--background), 1);
  font-weight: 600;
  text-transform: capitalize;
}

.reach-and-clicks-text {
  font-size: 1.4rem;
  line-height: 2rem;
  color: rgba(#100b28, 0.64);
  white-space: pre-line;
}

.your-ad-details-point {
  display: block;
  font-size: 4.4rem;
  line-height: 5.6rem;
  letter-spacing: 0.66px;
  color: rgba(var(--background), 1);
  font-weight: 600;
  text-transform: uppercase;
}

.your-ad-details-min {
  width: 100%;
  position: relative;
}

.your-ad-details-min-title {
  font-size: 1.8rem;
  line-height: 2.3rem;
  color: rgba(var(--background), 1);
  font-weight: 600;
  padding-right: 1rem;
  margin-bottom: 0.6rem;
}

.your-ad-details-min-info {
  position: absolute;
  right: -2rem;
  top: -1.5rem;
  cursor: pointer;
  //&:before{
  //  content: "";
  //  display: block;
  //  position: absolute;
  //  width: 14rem;
  //  height: 2rem;
  //  top: -2rem;
  //  left: 50%;
  //  transform: translate(-50%, 0);
  //}

  &:hover {
    .your-ad-details-min-info-content {
      opacity: 1;
    }
  }
}

.your-ad-details-min-info-icon {
  font-size: 1.6rem;
  display: block;
  color: rgba(#100b28, 0.54);
}

.your-ad-details-min-info-content {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  padding: 0.8rem 1.5rem;
  background-color: rgba(var(--background), 1);
  transition: opacity 240ms ease-in-out;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: calc(100% + 1.5rem);

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 1.6rem;
    height: 1.6rem;
    left: 0;
    right: 0;
    bottom: -0.8rem;
    margin: 0 auto;
    transform: rotate(45deg);
    background-color: inherit;
  }
}

.your-ad-details-min-info-text {
  white-space: nowrap;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #ffffff;
  letter-spacing: 0.18px;
  text-align: center;

  > span {
    display: block;
  }
}

// map chart START
.map-chart-row {
  width: 100%;
  margin-bottom: 4rem;
  box-shadow: 0 1rem 2.5rem #f0f0f0;
  background-color: #ffffff;
  border: 0.1rem solid #efefef;
}

.map-chart-head {
  padding: 2.5rem 4rem 3rem 4rem;
  border-bottom: 0.1rem solid #f1f0f2;
}

.map-chart-content {
  width: 100%;
  display: flex;
  padding-right: 5px;
}

.map-chart-content-column {
  height: auto;
  &.chart-column {
    flex: 0 0 auto;
    width: 57.3rem;
    padding: 4.8rem 5.4rem 3rem 4.8rem;
  }

  &.table-column {
    flex: 1;
    max-height: 409px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 5px;
  }
}

.map-chart {
  width: 443px;
  height: 27.2rem;
  @media screen and (max-width: 1919px) {
    height: 260px;
    width: 424px;
  }
  @media screen and (max-width: 979px) {
    height: 302px;
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    height: 180px;
    width: 100%;
  }

  > div {
    height: 100%;

    > div {
      width: 100% !important;
      height: 100% !important;
    }
  }

  svg {
    height: 100%;
    width: 100%;
  }
}

.map-chart-dots-mobile {
  display: none;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem 0;

  > span {
    flex: 0 0 auto;
    width: 0.6rem;
    height: 0.6rem;
    border-radius: 50%;
    background-color: rgba(#100b28, 0.16);
    margin: 0 0.6rem;
    box-sizing: content-box;
    transition: background-color 240ms;

    &.active {
      background-color: rgba(var(--background), 1);
    }
  }
}

.map-chart-info {
  display: flex;
  align-items: center;
  padding-top: 6rem;
}

.map-chart-point {
  font-size: 1.6rem;
  line-height: 2rem;
  color: rgba(var(--background), 1);
}

.map-chart-gr {
  width: 17.3rem;
  height: 1.5rem;
  background: linear-gradient(270deg, rgba(var(--brand-color), 1), #fff);
  margin: 0 0.6rem;
  @media screen and (max-width: 1919px) {
    height: 1.3rem;
    width: 12.7rem;
  }
  @media screen and (max-width: 979px) and (min-width: 768px) {
    width: 17.3rem;
    height: 1.5rem;
  }
}

.map-chart-table {
  display: flex;
  align-items: center;
  width: 100%;
  padding-right: 5rem;
  border-right: 0.1rem solid #ecebee;
}

.map-chart-table-cs {
  flex: 1;
  &.sticky {
    flex: 0 0 auto;
    z-index: 10;
  }
  &:first-child {
    width: 38.819%;
    .map-chart-table-row {
      padding-left: 3.2rem;
      text-align: left;
    }
  }
}

.map-chart-table-row {
  display: flex;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 5.4rem;

  &:not(:last-child) {
    border-bottom: 0.1rem solid rgba(var(--border-color), 1);
  }

  &.head {
    height: 6.4rem;
    background-color: #ffffff;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
    box-shadow: 0px -10px 0 0 #ffffff;

    .map-chart-table-text {
      font-size: 1.2rem;
      color: rgba(#100b28, 0.72);
      letter-spacing: 0.6px;
      text-transform: uppercase;
      font-weight: 510;
    }
  }
}

.map-chart-table-column {
  flex: 1;
}

.map-chart-table-text {
  font-size: 1.4rem;
  line-height: 2rem;
  color: rgba(var(--background), 1);
  word-break: break-word;
  text-transform: capitalize;
}

// map chart END

.reports-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3.2rem;
  width: 100%;
}

.reports-column {
  background-color: #ffffff;
  box-shadow: 0 1rem 2.5rem #f0f0f0;
  border: 0.1rem solid #efefef;
}

.reports-head {
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 0.1rem solid rgba(var(--border-color), 1);
  padding: 2.5rem 4rem 2.9rem;
}

.reports-head-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 1rem;
}

.reports-head-typ {
  display: grid;
  grid-auto-flow: column;
  grid-gap: 3.2rem;
}

.reports-head-typ-text {
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  line-height: 2rem;
  color: rgba(var(--background), 1);
  text-transform: capitalize;

  &:before {
    content: "";
    display: block;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    margin-right: 0.8rem;
  }

  &.clicks {
    &:before {
      background-color: rgba(var(--background), 1);
    }
  }

  &.conversions {
    &:before {
      background-color: rgba(var(--brand-color), 1);
    }
  }
}

.reports-content {
  width: 100%;
  position: relative;

  .empty-card {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    z-index: 10;
    min-height: 290px;
  }
}

.staked-bar-chart {
  width: 100%;
  padding: 0 4rem;
  height: 33.3rem;
  padding-bottom: 40px;

  > div {
    height: 100%;

    > div {
      width: 100% !important;
      height: 100% !important;
    }
  }

  svg {
    width: 100%;
    height: 100%;
  }
}

.reports-content-table {
  width: 100%;
}

.reports-content-table-scrollable {
  height: 33.3rem;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.reports-content-table-row {
  display: flex;
  align-items: center;
  padding: 0 6.4rem;
  margin-bottom: 1.6rem;

  &.head {
    height: 6.4rem;
    background-color: #fcfcfc;
    border-bottom: 0.1rem solid rgba(var(--border-color), 1);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    flex: 0 0 auto;
    z-index: 10;

    .reports-content-table-text {
      font-size: 1.2rem;
      line-height: 1.5rem;
      color: rgba(#100b28, 0.72);
      text-transform: uppercase;
      letter-spacing: 0.6px;
    }
  }
}

.reports-content-table-column {
  display: flex;
  align-items: center;
  flex: 1;

  &:first-child {
    flex: 0 0 auto;
    width: 22%;
  }

  &:last-child {
    flex: 0 0 auto;
    width: 15%;
    justify-content: flex-end;
  }
}

.reports-content-table-text {
  font-size: 1.6rem;
  line-height: 2rem;
  color: rgba(var(--background), 1);
  text-transform: capitalize;
}

.reports-content-table-total {
  display: flex;
  flex: 0 0 auto;
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  padding: 2rem 6.4rem 3.5rem 6.4rem;
  justify-content: space-between;
  border-top: 0.1rem solid rgba(var(--border-color), 1);
  width: 100%;
  background: white;
}

.reports-content-table-total-text {
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 600;
  text-transform: capitalize;
}
@media screen and (max-width: 1400px) {
  .your-ad-details-info-row {
    grid-template-columns: repeat(auto-fit, minmax(24rem, 1fr));
  }
  .your-ad-details-min-info-content {
    left: auto;
    transform: none;
    right: -2.6rem;

    &:before {
      left: auto;
      margin: 0;
      right: 2.5rem;
    }
  }
}
