.header {
  width: 100%;
  background-color: rgba(#fcfcfc, 0.9);
  backdrop-filter: blur(6px);
  position: -webkit-sticky;
  position: sticky;
  height: 9rem;
  padding: 0;
  top: 0;
  left: 0;
  z-index: 200;
  box-shadow: 0 0 1rem 0 rgba(#000000, 0.2);
  @media (max-width: 1919px) {
    padding: 0;
    height: 7.2rem;
    .logo-img {
      max-width: 93px;
      max-height: 30px;
    }
    .avatar-column {
      width: 4rem;
      height: 4rem;
      margin-left: 1.2rem;
    }
    .user-info {
      font-size: 1rem;
    }
    .user-name {
      font-size: 1.3rem;
      line-height: 1.6rem;
    }
  }
  @media (max-width: 979px) {
    padding: 0;
    .logo-img {
      max-width: 89px;
      max-height: 26px;
    }
    .avatar-column {
      width: 3.6rem;
      height: 3.6rem;
    }
  }
  @media (max-width: 767px) {
    padding: 0;
    .avatar-column {
      width: 3.2rem;
      height: 3.2rem;

      .avatar-img.placeholder {
        font-size: 1.6rem;
      }
    }
    .logo-img {
      max-width: 150px;
      max-height: 28px;
    }
  }
  &.active-delegate {
    height: 11.6rem;
    padding: 0;
    @media (max-width: 1919px) {
      height: 9.6rem;
      padding: 0;
    }

    @media (max-width: 979px) {
      height: 9.2rem;
      padding: 0;
    }
    @media (max-width: 767px) {
      height: 7.8rem;
      padding: 0;
    }
  }
  .header-content {
    display: flex;
    align-items: center;
    padding: 2rem 2.4rem 2.2rem 2.4rem;
    justify-content: space-between;
    width: 100%;
    @media (max-width: 1919px) {
      padding: 1.6rem 2.4rem;
      height: 7.2rem;
    }
    @media (max-width: 1610px) {
      padding: 2.2rem 2.4rem 2.3rem 2.4rem;
      height: 7.2rem;
    }

    @media (max-width: 979px) {
      padding: 1.6rem 2.4rem;
      height: 6.8rem;
    }
    @media (max-width: 767px) {
      padding: 1.1rem 1.4rem;
      height: 5.4rem;
    }
  }

  .active-delegate-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 2.6rem;
    outline: none;
    border: none;
    background-color: rgba(var(--background), 1);
    font-size: 14px;
    line-height: 20px;
    padding: 0;
    color: #ffffff;
    cursor: pointer;
    span {
      font-size: 14px;
      line-height: 20px;
    }
    i {
      margin-right: 8px;
      font-size: 10px;
      &::before {
        vertical-align: revert;
      }
    }
    @media (max-width: 1919px) {
      height: 24px;
      font-size: 13px;
      span {
        font-size: 13px;
        line-height: 16px;
      }
      i {
        margin-right: 10px;
        font-size: 8px;
        padding-top: 1px;
        &::before {
          max-height: 8px;
        }
      }
    }
  }
}

.header-column-left {
  flex: 0 0 auto;
}

.header-column-right {
  position: relative;
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.logo-block {
  cursor: pointer;
}

.logo-img {
  display: block;
  object-fit: contain;
  object-position: left;
  max-width: 234px;
  max-height: 34px;
}

.user-block {
  display: flex;
  align-items: center;

  &.pointer {
    cursor: pointer;
  }
}

.user-name-column {
  text-align: right;
}

.user-name {
  font-size: 1.6rem;
  line-height: 1.6rem;
  color: rgba(var(--background), 1);
  font-weight: 600;
  text-transform: capitalize;
}

.avatar-column {
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 1.6rem;

  .avatar-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.placeholder {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(var(--brand-color), 1);
      color: #ffffff;
      font-size: 2.4rem;
      font-weight: 410;
    }
  }
}

.avatar-settings {
  font-size: 1.2rem;
  margin-left: 0.7rem;
}

.user-info {
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: rgba(var(--brand-color), 1);
  text-transform: capitalize;
}
