.listing-page {
  display: grid;
  grid-template-rows: 11fr auto;
  width: 100%;
  height: 100%;
}

.listing-page-inner {
  display: grid;
  grid-template-rows: auto 11fr;
  width: 100%;
}

.presentation-row {
  width: 100%;
  padding: 2.5rem;
  background-color: rgba(var(--background), 1);
}

.presentation-row-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.presentation-row-texts {
  position: relative;
  padding-left: 7.8rem;
}
.presentation-user-name-column {
  display: inline-block;
}
.presentation-row-logo {
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  left: 0;
  width: 6.4rem;
  height: 6.4rem;
  object-fit: cover;
}

.presentation-view-all-button {
  outline: none;
  padding: 1rem;
  border: none;
  min-width: 24.4rem;
  height: 5.6rem;
  font-size: 1.6rem;
  line-height: 2rem;
  text-transform: capitalize;
  letter-spacing: 0.32px;
  background-color: #ffffff;
  color: rgba(var(--background), 1);
  transition: background-color 240ms, color 240ms;
  cursor: pointer;
  font-weight: 500;

  //&:hover {
  //  background-color: #ffffff;
  //  color: rgba(var(--background), 1);
  //}
  &:active {
    transform: scale(0.98);
  }
}

.presentation-user-name {
  font-size: 4.8rem;
  line-height: 6.1rem;
  font-weight: 600;
  text-transform: capitalize;
  color: #ffffff;
}

.presentation-info {
  word-break: break-word;
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: rgba(#ffffff, 0.8);
}

.listing-page-content {
  position: relative;
  padding: 3.6rem 4.8rem 0 4.8rem;
  background-color: #fcfcfc;
}

.listing-page-search-row {
  display: flex;
  align-items: center;
  width: 100%;
  padding-bottom: 1.6rem;
  border-bottom: 0.1rem solid #eff0f1;
}

.listing-page-search-input-column {
  display: flex;
  align-items: center;
  flex: 1;
  position: relative;
}

.listing-page-search-icon {
  flex: 0 0 auto;
  margin-right: 1.8rem;
  cursor: pointer;
  font-size: 1.6rem;
}

.listing-page-search-column {
  flex: 1;
}

.listing-page-search {
  width: 100%;
  outline: none;
  border: none;
  color: rgba(#100b28, 0.72);
  font-size: 1.6rem;
  background: transparent;
  font-weight: inherit;
  line-height: 2rem;

  &::placeholder {
    color: rgba(#100b28, 0.54);
  }

  &:focus {
    &::placeholder {
      color: rgba(var(--background), 1);
    }
  }
}

.search-sorting-column {
  flex: 0 0 auto;
}

.sorting-dropdown {
  min-width: 13.2rem;
  position: relative;
  color: rgba(var(--background), 1);
  letter-spacing: 0.26px;
  text-transform: capitalize;
}

.sorting-dropdown-head {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: pointer;
  padding-left: 1.8rem;

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}

.sort-name {
  font-size: 1.6rem;
  line-height: 2rem;
}

.sort-icon {
  font-size: 0.8rem;
  margin-left: 1rem;
}

.sorting-dropdown-content {
  position: absolute;
  width: 100%;
  left: 0;
  top: calc(100% + 1rem);
  background-color: #ffffff;
  border-radius: 0.2rem;
  box-shadow: 0 1rem 2.5rem 0 rgba(#2f2e31, 0.2);
  padding: 0.8rem;
  z-index: 100;
  //
  transform: translate(1rem, 0);
  //
  animation: sorting-dropdown-show 240ms linear;
}

@keyframes sorting-dropdown-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.sorting-dropdown-c-row {
  cursor: pointer;
  height: 3.5rem;
  line-height: 3.5rem;
  padding: 0 1rem;
  font-size: 1.3rem;
  text-transform: capitalize;
  border-radius: 0.2rem;
  color: rgba(var(--background), 0.72);
  transition: background-color 240ms, color 240ms;

  &:not(:last-child) {
    margin-bottom: 0.1rem;
  }

  &:hover,
  &.active {
    background-color: #f8f7f8;
    color: #100b28;
  }

  &.active {
    cursor: default;
  }
}

.listing-page-home-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(35.2rem, 1fr));
  grid-gap: 3.2rem;
  width: 100%;
  margin-top: 2.4rem;
  justify-content: center;
  position: relative;

  .loader {
    bottom: -6rem;
    top: auto;
    margin: 0 auto;
  }
}

.list-card {
  display: flex;
  flex-direction: column;
  box-shadow: 0 1rem 2.5rem #f0f0f0;
  background-color: #ffffff;
  transition: all 240ms;
  &:not(.inactive):hover {
    .list-card-img {
      transform: scale(1.06);
    }
  }
  &.inactive {
    .list-card-img {
      filter: grayscale(1);
    }
  }

  .list-card-coming-soon {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 106px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #100B28;
    font-size: 11px;
    line-height: 12px;
    background: white;
    z-index: 1;
    cursor: default;
  }
}

.list-card-img-row {
  flex: 0 0 auto;
  width: 100%;
  height: 25.6rem;
  overflow: hidden;
  position: relative;
}

.list-card-img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transform-origin: center;
  transition: transform 240ms ease-in-out;
}

.list-card-info-row {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 1.8rem 2.4rem 2.4rem 2.4rem;
}
.list-card-info-row-texts {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.home-address {
  display: block;
  margin-bottom: 1.2rem;
  font-size: 1.4rem;
  line-height: 1.8rem;
  word-break: break-word;
  color: rgba(var(--background), 1);
  font-style: normal;
  font-weight: 500;
  //user-select: all;
}

.list-price-id-row {
  display: grid;
  grid-column-gap: 1.6rem;
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: 2rem;
}

.list-price-id-title {
  display: block;
  font-size: 1.1rem;
  line-height: 1.4rem;
  word-break: break-word;
  text-transform: capitalize;
  color: rgba(#100b28, 0.5);
}

.list-price {
  font-size: 2.2rem;
  line-height: 2.8rem;
  word-break: break-all;
  white-space: nowrap;
  font-weight: 600;
  color: rgba(var(--background), 1);
}

.list-id {
  font-size: 1.6rem;
  line-height: 2.8rem;
  word-break: break-all;
  color: rgba(var(--background), 1);
}

.list-card-buttons {
  display: grid;
  grid-column-gap: 1.6rem;
  grid-template-columns: 12.8rem 1fr;
  width: 100%;
}
.analytics-button {
  border: 0.1rem solid rgba(var(--button-analytics), 1);
  background-color: rgba(var(--button-analytics), 1);
}
