@mixin cf-skeleton {
  animation: 1000ms linear infinite alternate cf-skeleton-glow;
  background: rgba(206, 217, 224, 0.2);
  background-clip: padding-box !important;
  border: .2rem solid transparent;
  border-radius: 1rem;
  box-shadow: none !important;
  color: transparent !important;
  cursor: default;
  pointer-events: none;
  user-select: none;
  padding: .2rem;
  width: 18rem;
  height: 2rem;
}

.cf-skeleton {
  .cf-skeleton-b {
    animation: 1000ms linear infinite alternate cf-skeleton-glow;
    background: rgba(206, 217, 224, 0.2);
    font-size: 0;
    pointer-events: none;
    border-radius: 4px;
  }

  .cf-skeleton-h {
    @include cf-skeleton;
  }

  .cf-skeleton-d {
    @include cf-skeleton;
    width: 100%;
    margin-bottom: .3rem;
  }

  .cf-skeleton-d-half {
    @include cf-skeleton;
    width: 50%;
    margin-bottom: .3rem;
  }

  .cf-skeleton-p {
    @include cf-skeleton;
    border-radius: .7rem;
    margin-top: 0.1rem;
    margin-bottom: 0.1rem;
    width: 28rem;
    height: 1.6rem;
    opacity: 0.7;
  }

  .cf-skeleton-span-wrapper {
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: space-between;
    margin: 0.5rem 0;

    .cf-skeleton-span-5,
    .cf-skeleton-span-10,
    .cf-skeleton-span-15,
    .cf-skeleton-span-20,
    .cf-skeleton-span-25,
    .cf-skeleton-span-30 {
      height: 1.5rem;
    }
  }

  .cf-skeleton-span-3 {
    @include cf-skeleton;
    display: inline-block;
    width: 3rem;

    &.margin{
      margin-right: .8rem;
    }
  }

  .cf-skeleton-span-5 {
    @include cf-skeleton;
    display: inline-block;
    width: 5rem;

    &.margin{
      margin-right: 1rem;
    }
  }

  .cf-skeleton-span-10 {
    @include cf-skeleton;
    display: inline-block;
    width: 10rem;

    &.margin{
      margin-right: 3rem;
    }
  }

  .cf-skeleton-span-15 {
    @include cf-skeleton;
    display: inline-block;
    width: 15rem;

    &.margin{
      margin-right: 5rem;
    }
  }

  .cf-skeleton-span-20 {
    @include cf-skeleton;
    display: inline-block;
    width: 20rem;

    &.margin{
      margin-right: 5rem;
    }
  }

  .cf-skeleton-span-25 {
    @include cf-skeleton;
    display: inline-block;
    width: 25rem;

    &.margin{
      margin-right: 5rem;
    }
  }

  .cf-skeleton-span-30 {
    @include cf-skeleton;
    display: inline-block;
    width: 30rem;

    &.margin{
      margin-right: 5rem;
    }
  }

  .cf-skeleton-dot {
    @include cf-skeleton;
    width: 2rem;
    height: 2rem;
  }

  .cf-skeleton-dot-sm {
    @include cf-skeleton;
    width: 1.5rem;
    height: 1.5rem;
  }

  .cf-skeleton-card {
    @include cf-skeleton;
    width: 100%;
    height: 100%;
    flex: 1;
  }

  .cf-skeleton-card-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
  }

  .cf-skeleton-circle {
    @include cf-skeleton;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  &-sticky {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    z-index: 20;
  }

  &.flex, & .flex {
    display: flex;
  }

  &.start, & .start {
    align-items: flex-start;
    justify-content: flex-start;
  }

  &.center, & .center {
    align-items: center;
    justify-content: center;
  }

  &.top, & .top {
    align-items: flex-start;
  }

  &.between, & .between {
    justify-content: space-between;
  }

  &.row, & .row {
    flex-direction: row;
  }

  &.col, & .col {
    flex-direction: column;
  }

  // custom for component

  // total listing traffic
  .citySkeletonWrapper {
    display: flex;

    .citySkeletonMap {
      display: flex;
      flex-direction: column;
      height: auto;
    }
  }

  // FAQ popup
  .request-popup-section .accordion-list .accordion-title i {
    top: 0;
  }
}

@keyframes cf-skeleton-glow {
  0% {
    background: rgba(206, 217, 224, 0.2);
  }

  100% {
    background: rgba(92, 112, 128, 0.2);
  }
}

// media queries
@media screen and (max-width: 1240px) {
  .cf-skeleton .citySkeletonWrapper {
    flex-direction: column;

    .citySkeletonMap {
      height: 43rem;
      padding: 2rem;
    }

    .citySkeletonTable {
      padding: 2rem;
    }
  }
}