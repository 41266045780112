.rm-manager-holder {
  display: grid;
  grid-template-rows: auto 11fr;
  width: 100%;
  height: 100%;
}
.rm-manager-head {
  display: flex;
  align-items: center;
  height: 14rem;
  padding: 2rem 2.4rem;
  // margin-bottom: 6rem;
  background-color: rgba(var(--background), 1);
}
.rm-manager-head-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
  padding: 0 2.4rem;
  max-width: 155rem;
  margin: 0 auto;
  position: relative;
  transition: max-width 240ms;
}
.rm-manager-head-title {
  font-size: 4rem;
  line-height: 5.1rem;
  font-weight: 600;
  color: #ffffff;
  text-transform: capitalize;
  word-break: break-word;
}
.rm-manager-content {
  width: 100%;
  height: 100%;
  padding: 4.1rem 2.4rem 0 2.4rem;
  max-width: 155rem;
  margin: 0 auto;
  min-width: 0;
  position: relative;
  transition: max-width 240ms;
  overflow: hidden;
}
.rm-manager-table-head-dropdown-title {
  display: none;
}
.rm-manager-table-tabs {
  display: flex;
  gap: 1.3rem;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  &.hidden {
    display: none;
  }
}
.rm-manager-table-holder-head {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  background-color: #ffffff;
  padding: 2.3rem 3.9rem;
  box-shadow: 0 1rem 2.5rem #f0f0f0;
  border: 0.1rem solid rgba(var(--border-color), 1);
  min-height: 9.2rem;
  min-width: 0;
}
.rm-manager-table-head-column {
  display: flex;
  align-content: center;
  flex: 0 0 auto;
  position: relative;
  &:last-child {
    height: 4.4rem;
  }
}
.rm-manager-table-tab {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  height: 2.9rem;
  grid-gap: 0.5rem;
  gap: 0.5rem;
  padding: 0 1.1rem;
  cursor: pointer;
  border-radius: 0.1rem;

  > p {
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: rgba(#100b28, 0.72);
    transition: color 240ms;
    margin-bottom: 0;
    font-weight: 510;
  }

  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.4rem;
    border-radius: 0.9rem;
    color: rgba(var(--background), 1);
    background-color: #f2f2f4;
    font-size: 1.2rem;
    line-height: 1.8rem;
    height: 1.8rem;
    min-width: 2.5rem;
    transition: background-color 240ms;
    font-weight: 500;
  }

  &.active {
    background-color: #323744;
    cursor: default;

    > p {
      color: #ffffff;
    }
  }
}
.rm-manager-table-sort {
  position: absolute;
  right: 0;
  height: 4.4rem;
  width: 4.4rem;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background-color: #ffffff;
  z-index: 2;
  border: 0.1rem solid transparent;
  transition: width 380ms ease-in-out, border 380ms, background-color 240ms;

  > input {
    outline: none;
    background: none;
    width: 100%;
    padding: 0 1rem 0 4rem;
    border: none;
    box-sizing: border-box;
    height: 100%;
    font-size: 1.5rem;
    color: rgba(var(--background), 1);
    transform: scale3d(0, 0, 0);
    transition: transform 240ms ease-in-out 100ms;

    &::placeholder {
      color: rgba(var(--background), 1);
    }
  }

  > i {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4.4rem;
    font-size: 1.8rem;
    color: #534f64;
    cursor: pointer;
  }

  &.open {
    width: 28rem;
    border-color: #ecebee;
    background-color: #fafafa;

    > input {
      transform: scale3d(1, 1, 1);
    }

    > i {
      color: rgba(var(--brand-color), 1);
    }
  }
}
.rm-manager-table-holder {
  display: grid;
  grid-template-rows: 11fr;
  width: 100%;
  height: 100%;
  height: calc(100% - 50px);

  .footer {
    margin-top: auto;
    flex: 0 0 auto;
    padding: 4rem 0;
  }
}
.rm-manager-table-holder-content {
  width: 100%;
  height: 100%;
  position: relative;
  min-width: 0;
}
.rm-manager-table-holder-content-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  scrollbar-width: none;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  .no-result {
    pointer-events: all;
  }
}
.manager-mail-link {
  color: #009cbd;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
    color: #009cbd;
  }
}

@media screen and (max-width: 1610px) and (min-width: 980px) {
  .rm-manager-head {
    height: 10.6rem;
    padding: 1.6rem;
  }
  .rm-manager-head-title {
    font-size: 3.2rem;
    line-height: 4.1rem;
  }
  .rm-manager-content,
  .rm-manager-head-inner {
    max-width: 130rem;
  }
  .rm-manager-head-inner {
    padding: 0 1.6rem;
  }
  .rm-manager-content {
    padding: 3.2rem 1.6rem 0 1.6rem;
  }
  .rm-manager-table-holder-head {
    min-height: 8rem;
    padding: 1.9rem 2rem 1.9rem 3.2rem;
  }
  .rm-manager-table-tabs {
    gap: 1.2rem;
  }
  .rm-manager-table-tab {
    height: 3rem;
  }
  .rm-manager-table-head-column {
    &:last-child {
      height: 4rem;
    }
  }
  .rm-manager-table-sort {
    height: 4rem;
    > i {
      font-size: 1.6rem;
    }
    input {
      font-size: 1.3rem;
    }
  }
}
@media screen and (max-width: 980px) {
  .manager-back.presentation-view-all-button {
    display: none;
  }
  .rm-manager-head-inner {
    padding: 0;
  }
  .rm-manager-head-title {
    font-size: 3.5rem;
    line-height: 4.4rem;
  }
  .rm-manager-content {
    padding: 2.4rem 2.4rem 0 2.4rem;
  }
  .rm-manager-table-holder-head {
    display: block;
    min-height: 7.6rem;
    padding: 0;
  }
  .rm-manager-table-head-column {
    width: 100%;
    &:first-child {
      padding: 2.4rem 2.4rem 2.3rem 2.4rem;
    }
    &:last-child {
      height: 4.8rem;
    }
  }
  .rm-manager-table-tab {
    padding: 0 0.843rem;
  }
  .rm-manager-table-sort {
    position: static;
    width: 100% !important;
    height: 100%;
    border-bottom: 0;
    > input {
      transform: initial;
      padding-left: 5rem;
    }
    > i {
      width: 5rem;
      padding-left: 1rem;
    }
  }
}
@media screen and (max-width: 738px) {
  .rm-manager-holder {
    height: auto;
    min-height: 100%;
  }
  .rm-manager-table-holder {
    height: calc(100% - 100px);
    .footer {
      padding: 2.9rem 0 0.8rem 0;
    }
  }
  .rm-manager-head {
    padding: 2rem 4rem 2rem 1.6rem;
    min-height: 8rem;
    height: auto;
  }
  .rm-manager-head-inner {
    padding: 0;
    flex-wrap: wrap;
  }
  .rm-manager-head-title {
    font-size: 2.2rem;
    line-height: 2.8rem;
  }
  .rm-manager-content {
    padding: 1.6rem;
  }
  .rm-manager-table-holder-head {
    display: block;
    padding: 0;
    border: 0.1rem solid #efefef;
  }
  .rm-manager-table-holder-content-inner {
    position: static;
    overflow: unset;

    .rm-manager-table-holder-content-inner-wrapper {
      height: fit-content;
      box-shadow: 0 1rem 2.5rem #f0f0f0;
      background-color: #ffffff;
    }
  }

  .rm-manager-table-head-column {
    padding: 0 !important;
    &:first-child {
      border-bottom: 0.1rem solid #efefef;
    }
    &:last-child {
      height: auto;
    }
  }
  .rm-manager-table-tab {
    height: 2.8rem;
  }
  .rm-manager-table-sort {
    position: static;
    width: 100%;
    height: 4.9rem;
    border: 0;
    > input {
      padding: 0 1rem 0 5.2rem;
      transform: none;
    }
    > i {
      width: 5.2rem;
      color: rgba(var(--brand-color), 1);
    }
  }
  .rm-manager-table-tabs {
    display: none;
    padding: 2.4rem 2.1rem;
  }
  .rm-manager-table-head-dropdown {
    overflow: hidden;
    min-height: 11rem;
    &.open {
      max-height: 11rem;
      .rm-manager-table-head-dropdown-title {
        display: none;
      }
      .rm-manager-table-tabs {
        display: flex;
      }
    }
  }
  .rm-manager-table-head-dropdown-title {
    display: flex;
    height: 6.8rem;
    border-bottom: 0.1rem solid #efefef;
  }
}
