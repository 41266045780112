.traffic-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 3.2rem;
  margin-bottom: 4rem;
}

.listing-traffic-page-card {
  // need for responsive charts
  overflow: hidden;
}

.traffic-card {
  border: 0.1rem solid #efefef;
  background: #ffffff;
  box-shadow: 0 0.2rem 0.8rem #f0f0f0;
  height: 100%;

  &-head {
    border-bottom: 0.1rem solid #efefef;
    padding: 2.6rem 3rem 3rem 3rem;
    position: relative;
  }

  &-head-title {
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 3rem;
    color: rgba(var(--background), 1);
    margin-bottom: 0.4rem;
  }

  &-head-subtitle {
    color: rgba(var(--background), 1);
    font-size: 1.6rem;
    line-height: 2rem;
  }

  &-body {
    display: flex;
    align-items: center;
    padding: 2rem;

    &.listing-traffic {
      align-items: flex-start;
    }
  }
}

.list-block {
  padding-top: 0;
  @media screen and (min-width: 768px) {
    padding-top: 10px;
  }
  width: 100%;
}

.pie-list {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 29rem;
  overflow-y: auto;
  scrollbar-width: none;
  height: 100%;
  padding-bottom: 10px;
}


@for $i from 1 through 14 {
  .pie-list .list-item:nth-child(#{$i}) {
    .list-item-dot{
      background-color: var(--chart-exposure#{$i});
    }
  }
}

.list-item {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 1.2rem 0 2rem;

  &:hover {
    background: #ffffff;
    box-shadow: 0 1rem 2.5rem #f0f0f0;
  }
}

.list-item-dot {
  flex: 0 0 auto;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 50%;
  margin-right: 1.2rem;
}

.list-item-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
  padding-right: 0.8rem;
}

.list-item-info {
  flex: auto;
  color: rgba(var(--background), 1);
  font-size: 1.4rem;
  line-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 0;
}

.pie-chart {
  position: relative;
  z-index: 1;
  width: 32.5rem;
  flex: none;
  height: 32.5rem;
  margin-right: 4rem;
}

.pie-chart-info {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
  z-index: 3;
}

.pie-chart-info-container {
  width: 220px;
  height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.pie-chart-info-title {
  letter-spacing: 0.66px;
  color: rgba(var(--background), 1);
  font-size: 4.4rem;
  line-height: 5.6rem;
  text-align: center;
  font-weight: 510;
}

.pie-chart-info-subtitle {
  color: rgba(var(--background), 1);
  line-height: 1.9rem;
  font-size: 1.6rem;
}

.area {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  padding-top: 3rem;
}

.area-legends {
  display: none;
  position: absolute;
    right: 14px;
    top: 0;
    bottom: 0;
    align-items: center;

    &-item {
        display: flex;
        margin: 0 16px;
        align-items: center;

        &-text {
            font-size: 1.6rem;
            line-height: 2rem;
            color: rgba(var(--background), 1);
        }

        &-dot {
            width: 12px;
            height: 12px;
            border-radius: 6px;
            margin-right: 8px;
        }
    }
}
