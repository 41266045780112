.rm-manager-mobile-table {
  background: #ffffff;
  box-shadow: 0 1rem 2.5rem #f0f0f0;
  border: 0.1rem solid #efefef;
  &.fixed-height {
    min-height: 360px;

    .no-result {
      top: 40%;
    }
  }
}

.rm-manager-mobile-total-text {
  font-size: 13px;
  line-height: 16px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #100b28;
  // text-transform: uppercase;
}

.rm-manager-mobile-row.total {
  background: #ffffff;
  padding-right: 3.2rem;
  &.hidden {
    display: none;
  }
  .rm-manager-mobile-column {
    min-width: 100px;
    padding-right: 1.8rem;
    flex: 2;
  }
  .rm-manager-mobile-total-ads {
    width: 100px;
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 16px;
    color: #100b28;
    margin: 0 auto;
    font-weight: bold;
  }
  .rm-manager-mobile-total-spend {
    flex: 1;
    width: 100px;
    display: flex;
    align-items: center;
    font-size: 13px;
    line-height: 16px;
    color: #100b28;
    margin: 0 auto;
    font-weight: bold;
  }
}

.rm-manager-mobile-rows-group {
  width: 100%;
  background-color: #ffffff;
  border-bottom: 0.1rem solid #efefef;
  border-top: 0;
  box-shadow: 0 1rem 2.5rem #f0f0f0;
}
.rm-manager-mobile-rows-group {
  width: 100%;
  height: 6.1rem;
  overflow: hidden;

  &.open {
    height: auto !important;
    > .rm-manager-mobile-row {
      .rm-manager-mobile-arrow {
        transform: rotateX(180deg);
      }
    }
  }
  &.region-dropdown {
    overflow: unset;
    box-shadow: unset;
    position: relative;
  }
}

.rm-manager-mobile-ads {
  flex: 1;
  width: 100px;
  display: flex;
  align-items: center;
  // justify-content: center;
  font-size: 13px;
  line-height: 16px;
  color: #100b28;
  margin: 0 auto;
  white-space: nowrap;
}
.rm-manager-mobile-spend {
  width: 100px;
  flex: 1;
  display: flex;
  align-items: center;
  // justify-content: center;
  font-size: 13px;
  line-height: 16px;
  color: #100b28;
  white-space: nowrap;
  margin: 0 auto;
}
.rm-manager-mobile-row {
  display: flex;
  align-items: center;
  width: 100%;
  height: 6.1rem;
  padding: 0 2rem;
  gap: 4px;
  &.first-level {
    padding-right: 3.2rem;
    justify-content: space-between;
    .rm-manager-mobile-ads {
      font-size: 11px;
      line-height: 14px;
      color: #100b28;
      text-transform: uppercase;
      opacity: 0.72;
      letter-spacing: 0.55px;
    }
    .rm-manager-mobile-spend {
      font-size: 11px;
      line-height: 14px;
      color: #100b28;
      text-transform: uppercase;
      opacity: 0.72;
      letter-spacing: 0.55px;
    }
  }
  .first-column {
    flex: 2;
    min-width: 100px;
    padding-right: 1.8rem;
  }

  &:not(:last-child) {
    border-bottom: 0.1rem solid #efefef;
  }
}
.rm-manager-mobile-row-column {
  flex: 2;
  display: flex;
  align-items: center;
  min-width: 0;
  padding-right: 1.8rem;

  // min-width: 115px;
}
.rm-manager-mobile-row-text {
  font-size: 1.3rem;
  line-height: 1.6rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
  color: rgba(var(--background), 1);
  padding-right: 1rem;
}
.rm-manager-mobile-arrow {
  flex: 0 0 auto;
  font-size: 0.8rem;
  color: rgba(var(--background), 0.58);
  transition: transform 240ms;
  &.open {
    transform: rotateX(180deg);
  }

  &.empty-agent {
    visibility: hidden;
  }
}
.rm-manager-mobile-hidden-rows {
  width: 100%;
  height: 4.4rem;
  overflow: hidden;

  &.open {
    height: auto;
  }
}
.rm-manager-mobile-hidden-rows-visible-part {
  display: flex;
  align-items: center;
  height: 4.4rem;
  width: 100%;
  padding: 0 2.3rem;
  background-color: #f6f6f7;
  border-bottom: 0.1rem solid #dfdde2;
  &.region {
    background-color: #e6e6e8;
  }
  &.minor-region {
    // padding-left: 4.2rem;
    .rm-manager-mobile-row-column {
      min-width: 96px;
      padding-left: 1.8rem;
      padding-right: 0;
    }
  }
}
.rm-manager-mobile-hidden-rows-layer-2 {
  width: 100%;
}
.rm-manager-mobile-arrow-turn {
  flex: 0 0 auto;
  margin-right: 1.1rem;
  font-size: 1rem;
  color: rgba(var(--background), 0.54);
}
.rm-manager-mobile-address {
  flex: 1;
  font-size: 1.1rem;
  line-height: 1.4rem;
  letter-spacing: 0.5px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: uppercase;
  color: rgba(var(--background), 0.72);
  // padding-right: 1.8rem;
}
.rm-manager-mobile-user-image {
  flex: 0 0 auto;
  display: block;
  border-radius: 50%;
  width: 3.5rem;
  height: 3.5rem;
  object-fit: cover;
  margin-right: 1rem;
}
