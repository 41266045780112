.analytics-page {
  display: grid;
  grid-template-rows: auto 11fr;
  width: 100%;
  height: 100%;
  .footer {
    padding: 5.6rem 0 3.6rem 0;
  }
  .analytics-d-table-column {
    .analytics-d-table-row {
      height: 8.3rem;

      &.head {
        height: 5.7rem;
      }

      &-col {
        padding-top: 0.5rem;

        &-title {
          line-height: 1.2rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          padding-right: 0.5rem;
        }

        .analytics-d-total-text {
          font-weight: 510;
          font-size: 1.6rem;
        }
      }
    }

    &.left {
      width: 41rem;

      .analytics-d-table-row-col {
        &:last-child {
          flex: 0 0 auto;
          min-width: 12rem;
        }
      }
    }

    &.center {
      -webkit-clip-path: (0 0, 100% 0, 100% 100%, 0% 100%);
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
      overflow-y: hidden;

      .swipe-cnt {
        transition: transform 380ms ease-in-out;

        &.swipe-step-1 {
          transform: translate(-33%);
        }

        &.swipe-step-2 {
          transform: translate(-67%);
        }
      }

      .analytics-d-table-row {
        padding-left: 4rem;
      }
    }

    &.right {
      width: 35.2rem;

      .analytics-d-table-row-col {
        &:first-child {
          width: 14rem;
        }
      }
    }
  }

  .analytics-d-mobile-table-row-content-inner {
    padding: 0 2rem 2rem 2rem;
  }
}
.analytics-d-mobile-dropdowns {
  display: flex;
  align-items: center;
  height: 4.8rem;
  transform: translate3d(0, 0, 0);
  position: relative;
  z-index: 5;

  .analytics-d-table-row {
    &.head {
      height: 100%;
      position: relative;
      padding: 0 2rem;
      @media screen and (max-width: 767px) {
        justify-content: space-between;
      }
    }

    .analytics-d-table-row-col {
      flex: 0 0 auto;
      position: static;
    }
  }
}
.header.active-delegate ~ .client-finder-content {
  .analytics-menu {
    @media screen and (max-width: 767px) {
      top: 44px;
    }
    top: 51px;
  }
  .your-listing-analytics-row-c-buttons {
    top: 7.8rem;
  }
  .analytics-menu-open-checkbox {
    @media screen and (max-width: 767px) {
      top: 44px;
    }
    top: 51px;
  }
}
.analytics-menu-open-checkbox {
  opacity: 0;
  pointer-events: none;
  width: 2rem;
  height: 2rem;
  position: fixed;
  right: 2.2rem;
  top: 2.4rem;
  z-index: 410;
  cursor: pointer;
  &:checked {
    & + .analytics-menu {
      transform: translate(-6px, 0);

      > span {
        &:nth-child(1) {
          transform: rotate(45deg) translate(0.8rem, 0);
        }

        &:nth-child(2) {
          transform: translate(0.4em, 0.4rem);
          opacity: 0;
        }

        &:nth-child(3) {
          transform: rotate(-45deg) translate(0.8rem, 0);
        }
      }
    }
  }
}
.analytics-menu {
  width: 1.8rem;
  display: none;
  position: fixed;
  right: 2.4rem;
  top: 2.7rem;
  z-index: 400;
  transition: transform 240ms ease-in-out;

  > span {
    display: block;
    height: 2px;
    width: 100%;
    background-color: rgba(#100b28, 0.72);
    margin-bottom: 0.4rem;
    transform-origin: center;
    transition: all 240ms ease-in-out;

    &:nth-child(2) {
      transition: all 120ms ease-in-out;
    }
  }
}
.your-listing-analytics-row {
  display: flex;
  align-items: center;
  width: 100%;
  height: 14rem;
  padding: 2rem 2.4rem;
  background-color: rgba(var(--background), 1);

  & .container {
    height: 100%;
  }
}
.your-listing-analytics-row-inner {
  display: grid;
  grid-template-columns: 11fr auto;
  grid-gap: 1rem;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
}

.has-rm-manager {
  .your-listing-analytics-row-column.buttons {
    @media (max-width: 980px) {
      display: none;
    }
  }
}
.your-listing-analytics-row-column {
  width: 100%;
  min-height: 0;
  max-width: 80%;
  min-width: 0;

  @media (max-width: 980px) {
    max-width: 100%;
  }

  &.buttons {
    display: flex;
    align-items: center;
  }
}
.your-listing-analytics-name {
  font-size: 4rem;
  line-height: 4rem;
  font-weight: 600;
  color: #ffffff;
  text-transform: capitalize;
  word-break: break-word;
  margin-bottom: 0.6rem;
}
.your-listing-analytics-address {
  display: block;
  width: 100%;
  font-size: 1.8rem;
  line-height: 1.8rem;
  color: rgba(#ffffff, 0.8);
  word-break: break-word;
  font-style: normal;
  user-select: all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media (max-width: 980px) {
    white-space: normal;
  }
}
.your-listing-analytics-row-c-buttons {
  display: flex;
  align-items: center;
}
.analytics-active-listing-button {
  outline: none;
  padding: 1rem;
  border: 0.1rem solid #ffffff;
  min-width: 24.4rem;
  height: 5.6rem;
  font-size: 1.6rem;
  line-height: 2rem;
  text-transform: capitalize;
  font-weight: 500;
  letter-spacing: 0.32px;
  background-color: #ffffff;
  color: rgba(var(--background), 1);
  transition: background-color 240ms, color 240ms;
  cursor: pointer;
  margin-right: 2rem;
  //&:hover {
  //  background-color: #ffffff;
  //  color: rgba(var(--background), 1);
  //}

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &:active {
    transform: scale(0.98);
  }
  &.tablet-button {
    display: none;
  }
}
.analytics-active-listing-button-mobile {
  display: none;
  width: 100%;
  position: absolute;
  bottom: -7.6rem;
  left: 0;
  outline: none;
  background-color: rgba(var(--background), 1);
  padding: 1rem;
  color: #ffffff;
  border: none;
  min-width: 24.4rem;
  height: 5.6rem;
  font-size: 1.5rem;
  line-height: 2rem;
  text-transform: capitalize;
  letter-spacing: 0.3px;
  word-break: break-word;

  &:active {
    transform: scale(0.98);
  }
}
.analytics-download-button {
  outline: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  transition: transform 240ms;
  &.manager {
    display: none;
  }
  &:hover {
    .analytics-download-button-text {
      color: rgba(#ffffff, 0.8);
    }
  }

  &:active {
    transform: translate(0, 0.6rem);
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
.analytics-download-button-icon {
  font-size: 5.6rem;
  display: block;
  //margin-bottom: 0.6rem;
  color: #ffffff;
}
.analytics-download-button-text {
  font-size: 1.2rem;
  line-height: 2rem;
  text-transform: capitalize;
  letter-spacing: 0.24px;
  color: #ffffff;
  transition: color 240ms;
}
.analytics-page-content {
  width: 100%;
  height: 100%;
  padding: 0 2.4rem;

  > .container {
    height: 100%;
  }
}
.analytics-page-content-inner {
  display: grid;
  grid-template-rows: auto 11fr;
  width: 100%;
  height: 100%;
}
.analytics-page-tabs-datepicker-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 3.2rem 0;
}
.analytics-page-tabs-column {
  display: inline-grid;
  vertical-align: top;
  grid-column-gap: 4rem;
  grid-auto-flow: column;
  overflow: hidden;
  position: relative;
}
.analytics-page-datepicker-column {
  height: 4.6rem;
  background-color: #ffffff;
  box-shadow: 0 5px 12px #f0f0f0;
  cursor: pointer;
}
.datepicker-icon,
.datepicker-arrow {
  flex: 0 0 auto;
  color: rgba(#100b28, 0.7);
  transition: color 240ms;
  font-size: 1rem;
}
.datepicker-icon {
  font-size: 1.6rem;
}
.datepicker-text {
  flex: 1;
  text-align: center;
  padding: 0 1rem;
  font-size: 1.6rem;
  line-height: 2rem;
  color: rgba(var(--background), 1);
}
.analytics-page-tab {
  font-size: 1.9rem;
  line-height: 2.5rem;
  white-space: nowrap;
  padding: 1rem 0;
  cursor: pointer;
  position: relative;
  font-weight: 510;
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0;
    background-color: rgba(var(--brand-color), 1);
    //transition: height 120ms;
  }

  &.active {
    font-weight: 600;

    &:before {
      height: 0.4rem;
    }
  }
}
.analytics-page-tab-switch {
  display: none;
  position: absolute;
  height: 0.4rem;
  width: 100%;
  bottom: 0;
  background-color: rgba(var(--brand-color), 1);
}
.analytics-page-table-container {
  display: grid;
  grid-template-rows: auto 11fr;
  min-width: 1rem;
  position: relative;
}
.analytics-page-table-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
  width: 100%;
  background-color: #ffffff;
  padding: 3.2rem 4.2rem 2.2rem;
  box-shadow: 0 1rem 2.5rem #f0f0f0;
  border-top: 0.1rem solid rgba(var(--border-color), 1);
  border-bottom: 0.1rem solid rgba(var(--border-color), 1);

  &-promotionBtn-wrapper {
    display: inline-block;
    vertical-align: top;
    max-width: 173px;
    width: 100%;

    & button {
      height: 48px;
    }
  }
}
.analytics-page-table-head-name {
  font-size: 2.4rem;
  line-height: 4.8rem;
  color: rgba(var(--background), 1);
  font-weight: 600;
  word-break: break-word;
}
.analytics-page-promote-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2.8rem;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 0.32px;
  outline: none;
  border: none;
  cursor: pointer;
  text-transform: capitalize;
  color: #ffffff;
  background-color: rgba(var(--brand-color), 1);
  opacity: 0.8;
  height: 4.8rem;
  transition: background-color 240ms;

  &:active {
    transform: scale3d(0.98, 0.98, 0.98);
  }

  &:hover {
    background-color: rgba(var(--brand-color), 1);
  }
}
.analytics-page-table-content {
  height: 100%;
  width: 100%;
  position: relative;
}
.analytics-page-table-content-scrollable {
  display: grid;
  grid-template-rows: 11fr auto;
  width: 100%;
  left: 0;
  top: 0;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
.analytics-page-table-cnt {
  width: 100%;
}
.analytics-page-table {
  box-shadow: 0 1rem 2.5rem #f0f0f0;
  background-color: #ffffff;
  padding: 0 4rem 3rem 4rem;
  position: relative;
}
.analytics-page-table-row {
  display: flex;
  align-items: center;
  width: 100%;
  height: 6.8rem;
  padding: 0 2.4rem;
  position: relative;

  &:not(:last-child) {
    border-bottom: 0.1rem solid rgba(var(--border-color), 1);
  }

  &.head {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    background-color: #ffffff;
    z-index: 100;
    .analytics-page-table-column-text {
      font-size: 1.3rem;
      line-height: 1.7rem;
      color: rgba(#100b28, 0.72);
      letter-spacing: 0.6px;
      text-transform: uppercase;
    }
  }

  &.finished {
    background-color: #fafafb;

    .see-details-button {
      background-color: rgba(var(--background), 1);
      color: #ffffff;

      &:hover {
        background-color: rgba(var(--brand-color), 1);
      }
    }

    .finished-cnt {
      &:before {
        background-color: rgba(var(--background), 1);
      }

      .analytics-page-table-column-text {
        color: rgba(var(--background), 1);
      }
    }
  }

  &.total {
    border-bottom: 0;

    .analytics-page-table-column-text {
      font-weight: 600;
      font-size: 1.6rem;
    }
  }
}
.analytics-page-table-row-inner {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: height 240ms ease-in-out;
}
.analytics-page-table-row-open {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5.6rem;
  opacity: 0;
  pointer-events: none;

  &:checked + .analytics-page-table-row-inner {
    height: auto;

    &:after,
    &:before {
      display: none;
    }
  }
}
.analytics-page-table-column {
  flex: 1;
  display: flex;
  height: 100%;
  align-items: center;
  min-width: 1rem;
  //&:not(:last-child) {
  //  margin-right: 3rem;
  //}

  &.platforms {
    flex: 0 0 auto;
    width: 25.492%;
  }

  &.goal {
    flex: 0 0 auto;
    width: 13%;
  }

  &.see-details {
    flex: 0 0 auto;
    //width: 10.8rem;
    width: 14rem;
    margin-left: 2rem;
  }

  &.cancel-ad {
    width: 7rem;
    flex: 0 0 auto;
    justify-content: flex-end;
    margin-left: 2rem;
  }
}
.analytics-page-table-dropdown {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;

  &.open {
    .analytics-page-table-dropdown-content {
      opacity: 1;
      pointer-events: all;
      &.goal {
        @media screen and (max-width: 980px) and (min-width: 768px) {
          left: unset;
          right: -1rem;
        }
      }
    }

    .analytics-page-table-dropdown-icon {
      &:before {
        transform: rotateX(180deg);
      }
    }
  }
}
.analytics-page-table-dropdown-head {
  display: inline-grid;
  grid-template-columns: 11fr auto;
  max-width: 100%;
  cursor: pointer;
  align-items: center;
}
.analytics-page-table-dropdown-text {
  display: block;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: rgba(#100b28, 0.72);
  letter-spacing: 0.6px;
  text-transform: uppercase;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.analytics-page-table-dropdown-icon {
  flex: 0 0 auto;
  display: block;
  font-size: 0.8rem;
  color: rgba(var(--background), 1);
  padding-left: 0.8rem;
  cursor: pointer;

  &:before {
    display: block;
    transform-origin: center center;
    transition: transform 240ms;
  }
}
.analytics-page-table-dropdown-content {
  position: absolute;
  left: -2.4rem;
  top: 3.7rem;
  min-width: 20rem;
  width: auto;
  background-color: #ffffff;
  box-shadow: 0 1rem 2.5rem rgba(#000000, 0.12);
  border-radius: 0 0 0.8rem 0.8rem;
  z-index: 100;
  padding: 2.6rem 2.4rem 3rem 2.4rem;
  opacity: 0;
  pointer-events: none;
  transition: opacity 240ms ease-in-out;
}
.analytics-page-table-dropdown-row {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  > input {
    display: none;

    &:checked {
      & + .analytics-page-table-dropdown-label {
        .custom-checkbox {
          border-color: rgba(var(--background), 1);

          &:before {
            opacity: 1;
          }
        }
      }
    }
  }
}
.analytics-page-table-dropdown-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  white-space: nowrap;

  > span {
    font-size: 1.6rem;
    line-height: 2rem;
    color: rgba(var(--background), 1);
    letter-spacing: 0.32px;
    text-transform: capitalize;
  }
}
.analytics-page-table-row-total {
  display: flex;
  justify-content: space-between;
}
.analytics-page-table-row-total-column {
  flex: 0 0 auto;
}
.custom-checkbox {
  flex: 0 0 auto;
  width: 2rem;
  height: 2rem;
  border: 0.1rem solid #d6d5da;
  border-radius: 0.2rem;
  position: relative;
  margin-right: 1.1rem;
  transition: border 120ms;

  &:before {
    content: "\e909";
    font-family: icomoon;
    display: block;
    position: absolute;
    font-size: 1rem;
    width: 1rem;
    height: 1rem;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    opacity: 0;
    transition: opacity 120ms;
    color: rgba(var(--brand-color), 1);
  }
}
.analytics-page-table-column-text {
  display: block;
  font-size: 1.5rem;
  line-height: 1.9rem;
  color: rgba(var(--background), 1);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:first-letter {
    text-transform: uppercase;
  }
}
.finished-cnt {
  position: relative;
  padding-left: 1.8rem;

  &:before {
    content: "";
    display: block;
    width: 1rem;
    height: 1rem;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto 0;
    border-radius: 50%;
    background-color: rgba(var(--brand-color), 1);
  }
}
.see-details-button {
  width: 100%;
  height: 3.2rem;
  outline: none;
  border: 0.1rem solid rgba(var(--background), 1);
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: rgba(var(--background), 1);
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  padding: 0 0.6rem;
  cursor: pointer;
  transition: background-color 240ms, color 240ms, border 240ms;

  &:hover {
    background-color: rgba(var(--brand-color), 1);
    color: #ffffff;
    border-color: rgba(var(--brand-color), 1);
  }

  &:active {
    transform: scale(0.98);
  }
}
.cancel-the-ad-cnt {
  position: relative;

  &.open {
    .cancel-the-ad-content {
      opacity: 1;
      pointer-events: auto;
    }
  }
}
.cancel-the-ad-button {
  outline: none;
  background: transparent;
  border: none;
  height: 3.2rem;
  font-size: 1.8rem;
  color: rgba(var(--background), 1);
  padding: 0;
  cursor: pointer;
}
.cancel-the-ad-content {
  min-width: 11.6rem;
  position: absolute;
  box-shadow: 0 1rem 2.5rem rgba(#000000, 0.12);
  background-color: #ffffff;
  z-index: 10;
  padding: 1.4rem;
  right: -1.4rem;
  top: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity 240ms ease-in-out;
}
.cancel-the-ad-content-head {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-bottom: 1.3rem;
  border-bottom: 0.1rem solid #efeff1;
  margin-bottom: 1.2rem;
}
.cancel-the-ad-content-close {
  outline: none;
  border: none;
  background: none;
  font-size: 0.8rem;
  color: rgba(#100b28, 0.6);
  transition: color 120ms;
  cursor: pointer;
  padding: 0;

  &:hover {
    color: rgba(var(--background), 1);
  }
}

.cancel-the-ad-button-text {
  width: 100%;
  padding: 0;
  outline: none;
  border: none;
  background: none;
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: rgba(var(--danger-color), 1);
  cursor: pointer;
  white-space: nowrap;
}

// cancel the ad popup START
.cancel-the-ad-popup {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 300;
  left: 0;
  top: 0;
  background-color: rgba(#100b28, 0.2);
  padding: 2rem;
  overflow: hidden;
}

.cancel-the-ad-popup-content {
  flex: 0 0 auto;
  display: flex;
  margin: auto;
  max-width: 64rem;
  width: 100%;
  min-height: 38rem;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 0.8rem 1.6rem rgba(#000000, 0.12);
  border-radius: 0.8rem;
  padding: 4.8rem;
  max-height: 100%;
  transform: translate(0, 100vh);
  scrollbar-color: #5a5a5a #dedede;
  scrollbar-width: thin;
  overflow-x: hidden;
  overflow-y: auto;
  animation: cancel-the-ad-popup-show 600ms ease-in-out forwards;

  .loader {
    & ~ * {
      opacity: 0.3;
      pointer-events: none;
    }
  }
}

@keyframes cancel-the-ad-popup-show {
  0% {
    transform: translate(0, 100vh);
  }

  100% {
    transform: translate(0, 0);
  }
}

.cancel-the-ad-close {
  outline: none;
  background: none;
  border: none;
  position: absolute;
  right: 1.2rem;
  top: 1.2rem;
  width: 3.6rem;
  height: 3.6rem;
  font-size: 1.2rem;
  cursor: pointer;
  color: rgba(var(--background), 1);
  border-radius: 50%;
  transition: background-color 240ms;

  &:hover {
    background-color: rgba(#100b28, 0.05);
  }
}

.cancel-the-ad-popup-content-inner {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  text-align: center;
  width: 100%;
  height: 100%;
  max-width: 48rem;
}
.cancel-the-ad-popup-img-holder {
  position: relative;
  margin-bottom: 1.7rem;
}
.cancel-the-ad-popup-img-block {
  width: 9.6rem;
  height: 9.6rem;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
}

.cancel-the-ad-popup-img {
  display: block;
  width: 110%;
  height: 130%;
  border-radius: inherit;
  object-fit: cover;
  overflow: hidden;
  position: absolute;
  left: -5%;
  top: -28%;
  &.google{
    top: -5%;
  }
}

.cancel-the-ad-popup-icon-block {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -0.6rem;
  bottom: 0;
  z-index: 10;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  background-color: rgba(var(--danger-color), 1);
  box-shadow: 0 0.3rem 0.6rem rgba(#000000, 0.12);
  color: #ffffff;
  font-size: 1.4rem;
}

.cancel-the-ad-popup-title {
  font-size: 2.8rem;
  line-height: 3.6rem;
  font-weight: 600;
  color: rgba(var(--background), 1);
  word-break: break-word;
  margin-bottom: 1.6rem;
  max-width: 44rem;
}

.cancel-the-ad-popup-buttons {
  display: flex;
  align-items: center;
  margin-top: 4.4rem;
}

.cancel-the-ad-popup-button {
  flex: 0 0 auto;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  margin: 0 1.6rem;
  padding: 0 0.4rem;
  width: 10rem;
  height: 4.8rem;
  text-transform: uppercase;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 600;
  letter-spacing: 0.32px;
  border-radius: 0.2rem;
  transition: background-color 240ms;

  &.yes {
    color: rgba(var(--background), 1);
  }

  &.no {
    background-color: rgba(var(--background), 1);
    color: #ffffff;

    &:hover {
      background-color: rgba(var(--brand-color), 1);
    }
  }
}

// cancel the ad popup END

// Blank Popup START

.blank-popup {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 600;
  left: 0;
  top: 0;
  background-color: rgba(#100b28, 0.2);
  padding: 2rem;
  overflow: hidden;
}

.blank-popup-content {
  flex: 0 0 auto;
  display: flex;
  margin: auto;
  max-width: 420px;
  width: 100%;
  min-height: 264px;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 0.8rem 1.6rem rgba(#000000, 0.12);
  border-radius: 8px;
  max-height: 100%;
  transform: translate(0, 100vh);
  scrollbar-color: #5a5a5a #dedede;
  scrollbar-width: thin;
  overflow-x: hidden;
  overflow-y: auto;
  animation: blank-popup-show 600ms ease-in-out forwards;

  .loader {
    & ~ * {
      opacity: 0.3;
      pointer-events: none;
    }
  }
}

@keyframes blank-popup-show {
  0% {
    transform: translate(0, 100vh);
  }

  100% {
    transform: translate(0, 0);
  }
}

.blank-popup-close {
  outline: none;
  background: none;
  border: none;
  position: absolute;
  right: 1.2rem;
  top: 1.2rem;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  cursor: pointer;
  color: rgba(var(--background), 1);
  border-radius: 50%;
  transition: background-color 240ms;

  &:hover {
    background-color: rgba(#100b28, 0.05);
  }
}

.blank-popup-content-inner {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  text-align: left;
  width: 100%;
  max-width: 55rem;
}
// Blank popup END

// promote listing empty START
.promote-listing-empty {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  position: absolute;
  width: 100%;
  height: 90%;
  left: 0;
  top: 0;
  background-color: #ffffff;
  z-index: 10;
  padding: 2rem;
}

.promote-listing-empty-button {
  display: inline-block;
  vertical-align: top;
  max-width: 200px;
  width: 100%;
}

.promote-listing-empty-text {
  font-size: 1.6rem;
  line-height: 2.6rem;
  color: rgba(#100b28, 0.74);
  letter-spacing: 0.32px;
  max-width: 30rem;
  text-align: center;
}
// promote listing empty END

@media screen and (max-width: 1610px) and (min-width: 980px) {
  .analytics-page {
    .container {
      max-width: 126.8rem;

      .analytics-page-content-inner {
        .analytics-page-tabs-datepicker-row {
          padding: 2rem 0 2.3rem 0;
        }

        .analytics-page-table-container {
          .analytics-page-table-head {
            padding: 3.4rem 3.3rem 3rem;
          }

          .analytics-d-table-column {
            &.left {
              width: 35.8rem;
            }
            &.center {
              .analytics-d-table-row {
                padding-left: 1rem;
              }
            }
            &.right {
              width: 32.8rem;
              .analytics-d-table-row-col {
                &:first-child {
                  width: 12.8rem;
                }
              }
            }

            .analytics-d-table-row {
              height: 7rem;

              &.head {
                height: 4.5rem;
              }
              &.total {
                .analytics-d-table-row-col {
                  .analytics-d-total-text {
                    font-size: 1.3rem;
                  }
                }
              }

              &-col {
                padding: 0.2rem 0 0;
              }
            }
          }
        }
      }
    }
    .footer {
      padding: 4rem 0 3.2rem 0;
    }
    .total-promote-buttons {
      gap: 1.6rem;
    }
  }
  .your-listing-analytics-row {
    height: 10.6rem;
  }
  .your-listing-analytics-name {
    font-size: 3.2rem;
    line-height: 4.1rem;
    margin-bottom: 0.3rem;
  }
  .your-listing-analytics-address {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
  .analytics-active-listing-button {
    min-width: 20.2rem;
    height: 4.2rem;
    font-size: 1.3rem;
    line-height: 1.7rem;
    padding: 0 1.2rem;
    margin-right: 1.2rem;
  }
  .analytics-download-button-icon {
    font-size: 4.2rem;
    width: 3.4rem;
  }
  .analytics-page-tabs-column {
    grid-gap: 2.4rem;
  }
  .analytics-page-table-head {
    padding: 3.5rem 3.3rem 3.1rem 3.3rem;
  }
  .analytics-page-table-dropdown-content {
    top: 3rem;
    padding: 1.6rem 1.5rem;
    border-radius: 0 0 0.2rem 0.2rem;
    min-width: 13rem;
  }
  .analytics-page-table-dropdown-icon {
    font-size: 0.6rem;
    width: 1.4rem;
    height: 1.4rem;
    padding-top: 0.4rem;
    padding-left: 0.6rem;
  }
  .analytics-page-table-dropdown-row {
    &:not(:last-child) {
      margin-bottom: 0.8rem;
    }
  }
  .analytics-page-table-dropdown-label {
    > span {
      font-size: 1.3rem;
    }
  }
  .analytics-page-table-head-name {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
  .analytics-page-tab {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .analytics-page-datepicker-column {
    height: 3.4rem;
    min-width: 294px;
    max-width: 294px;
  }
  .datepicker-text {
    font-size: 1.3rem;
    padding: 0 0.7rem;
    white-space: nowrap;
  }
  .datepicker-icon {
    font-size: 1.2rem;
  }
  .datepicker-arrow {
    font-size: 0.6rem;
  }
  .analytics-page-table-column-text {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
}

@media screen and (min-width: 981px) {
  .swipe-cnt,
  .analytics-d-table-row-col {
    transform: initial !important;
  }
  .swipe-cnt {
    -ms-overflow-style: none;
    scrollbar-width: none; 
    &::-webkit-scrollbar {
      width: 0px;
      height: 0px; 
      display: none;
   }
  }
}
@media screen and (max-width: 980px) {
  .analytics-d-mobile-dropdowns {
    .analytics-d-table-row.head {
      justify-content: space-between;
    }
  }
  .analytics-page {
    .container {
      .analytics-page-content-inner {
        .analytics-page-tabs-datepicker-row {
          padding: 3.1rem 0;

          .analytics-page-tabs-column {
            grid-column-gap: 3rem;
          }
        }

        .analytics-page-table-container {
          .analytics-page-table-head {
            padding: 4.3rem 2.4rem 3.7rem;

            &-name {
              font-size: 2.4rem;
              line-height: 2.4rem;
            }
          }

          .analytics-d-table-column {
            &.left {
              width: 28rem;

              .analytics-d-table-row {
                padding-right: 0;

                &-col {
                  min-width: 8rem;
                }
              }
            }
            &.center {
              .swipe-cnt {
                .analytics-d-table-row {
                  padding-left: 2rem;
                }
              }
            }
            &.right {
              width: 28.8rem;

              .analytics-d-table-row {
                padding-left: 2rem;

                &-col {
                  &:first-child {
                    min-width: 1rem;
                    width: 10rem;
                  }

                  .total-promote-button {
                    height: 3.2rem;
                  }
                }
              }
            }

            .analytics-d-table-row {
              &.total {
                .analytics-d-table-row-col {
                  .analytics-d-total-text {
                    font-size: 1.4rem;
                  }
                }
              }
            }
          }

          .promote-listing-empty-text {
            font-size: 1.6rem;
            max-width: 29rem;
          }
        }
      }
    }

    .total-promote-button {
      max-width: 100%;
      margin-left: unset;
      margin-right: auto;
    }
    .total-promote-buttons {
      display: flex;
      padding: 0 20px 20px;
      .cancel-the-ad-button-text {
        flex: 1;
      }
    }
  }
}

@media screen and (max-width: 738px) {
  .analytics-page {
    .your-listing-analytics-row {
      padding: 1.4rem 2rem;

      &-column {
        .your-listing-analytics-name {
          font-size: 2.2rem;
          line-height: 2.8rem;
        }

        &.buttons .analytics-active-listing-button {
          font-size: 1.6rem;
        }
      }
    }
    .container .analytics-page-content-inner {
      .analytics-page-tabs-datepicker-row {
        padding: 1.2rem 0 2.4rem;

        .analytics-page-tabs-column {
          grid-column-gap: 1.5rem;
        }
      }

      .analytics-page-table-container {
        .analytics-page-table-head {
          padding: 2.1rem 2rem 2.3rem;

          .analytics-page-table-head-name {
            font-size: 1.7rem;
            line-height: 2.2rem;
          }
        }

        .promote-listing-empty-text {
          font-size: 1.6rem;
        }
      }

      .analytics-page-table-content {
        .analytics-d-mobile-table-row-column {
          & > p {
            min-height: 1.8rem;
          }
        }
      }
    }

    .total-promote-buttons {
      display: grid;
      grid-auto-flow: row;
      grid-gap: 2rem;
      .button {
        max-width: 100%;
      }
    }

    .total-promote-button {
      max-width: 100%;
    }
  }
}
