.confirmation-wrapper {
  background-color: #ffffff;
  z-index: 500;
  animation: popup-show 640ms ease-in-out;
}

.confirmation-title {
  font-size: 1.8rem;
  line-height: 3.4rem;
  color: rgba(var(--background), 1);
  font-weight: 300;
  margin-bottom: 4.8rem;
  > span {
    font-weight: 600;
  }
}
.pdf-download {
  max-width: 44.8rem;
  width: 100%;
}

.submitted-row {
  display: flex;
  width: 100%;
}

.submitted-block {
  max-width: 44.8rem;
  min-height: 25.6rem;
  width: 100%;
  padding: 4rem 3.2rem 3.2rem 3.2rem;
  box-shadow: 0 10px 25px #F0F0F0;
  background-color: #ffffff;
  position: relative;
  &:before{
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    left: -0.6rem;
    top: -0.6rem;
    height: 1.8rem;
    border-left: 0.6rem solid rgba(var(--background), 1);
    border-right: 0.6rem solid rgba(var(--background), 1);
    border-top: 0.6rem solid rgba(var(--background), 1);
    z-index: 10;
  }
}

.submitted-price-row {
  display: flex;
  align-items: center;
  height: 6rem;
  padding: 0 1.6rem;
  border: 0.1rem solid rgba(var(--brand-color), 1);
  margin-bottom: 3.2rem;
}

.submitted-price-left-column {
  flex: 1;
  display: flex;
  align-items: center;
  color: rgba(var(--brand-color), 1);
}

.submitted-price-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  width: 1.6rem;
  height: 1.6rem;
  background-color: rgba(var(--brand-color), 1);
  border-radius: 50%;
  flex: 0 0 auto;
  color: #ffffff;
  margin-right: 1.5rem;
}

.submitted-name {
  font-size: 1.6rem;
  line-height: 2rem;
  color: rgba(var(--brand-color), 1);
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.64px;
}

.submitted-price {
  flex: 0 0 auto;
  font-size: 2rem;
  line-height: 2.8rem;
  color: rgba(var(--background), 1);
  font-weight: bold;
  letter-spacing: 0.32px;
}

.submitted-row-info {
  display: flex;
  justify-content: space-between;
  &:not(:last-child){
    margin-bottom: 1rem;
  }
}
.submitted-row-info-text{
  font-size: 1.3rem;
  line-height: 2rem;
  &.left {
    flex: 0 0 auto;
    margin-right: 0.4rem;
    color: rgba(#100B28, 0.8);
  }
  &.right {
    flex: 1;
    text-align: right;
    word-break: break-word;
    font-weight: 600;
    color: rgba(#100B28, 0.9);
  }
}

.go-back-listing {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  outline: none;
  background: transparent;
  border: none;
  cursor: pointer;
  color: rgba(var(--background), 1);
  font-size: 2rem;
  line-height: 2.5rem;
  //font-weight: 600;
  > p {
    margin-bottom: 1.4rem;
  }
  > i {
    font-size: 3.5rem;
    color: rgba(var(--brand-color), 1);
  }
}