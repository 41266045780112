$popup-header-desktop: 84px;
$popup-header-mobile: 63px;

.share-with-client-popup-shadow-close {
    display: flex;
    min-width: 100%;
    min-height: 100%;
    background-color: rgba(16, 11, 40, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 40px;
    @media screen and (max-width: 758px) {
        padding: 0;
    }
}

.share-with-client-popup {
    width: 640px;
    height: calc(100vh - 80px);
    background-color: #ffffff;
    box-shadow: 0 1rem 2.5rem rgba(#2b2d2f, 0.2);
    right: 0;
    top: 0;
    // z-index: 1000;
    transition: transform 240ms ease-out;
    transform: translate3d(0, 0, 0);
    overflow: hidden;
    border-radius: 8px;

    @media screen and (max-width: 758px) {
        width: 100%;
        height: 100vh;
    }
}

.share-with-client-content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #ecebee;
    padding: 26px 40px;

    @media screen and (max-width: 758px) {
        padding: 20px 16px 20px 20px;
    }
}
.bp3-popover2-content{
    background-color: rgba(var(--background), 1) !important;
}
.share-with-client-title {
    font-size: 24px;
    font-weight: 600;
    color: rgba(var(--background), 1);
    line-height: 30px;
    @media screen and (max-width: 758px) {
        font-size: 17px;
        line-height: 22px;
    }
}

.share-with-client-close {
    position: absolute;
    top: 12px;
    right: 12px;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
    font-size: 1.2rem;
    color: rgba(var(--background), 1);
    outline: unset;
    &:active {
        transform: scale(0.98);
        outline: unset;
    }
    &:hover {
        background: rgba(var(--background), 0.05);
        outline: unset;
    }
}

.share-with-client-content-inner {
    max-height: calc(100vh - 80px - #{$popup-header-desktop});
    overflow-y: overlay;
    overflow-x: hidden;
    scrollbar-color: #5A5A5A #DEDEDE;
    scrollbar-width: thin;
    padding: 32px 40px 0;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 758px) {
        padding: 20px 20px 0;
        max-height: calc(100vh - #{$popup-header-mobile});
    }
}

.share-with-client-popup-description {
    font-size: 18px;
    color: rgba(16, 11, 40, 0.72);
    padding-bottom: 23px;

    @media screen and (max-width: 758px) {
        font-size: 14px;
    }
}

.share-with-client-popup-input-container {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    position: relative;

    @media screen and (max-width: 758px) {
        padding-bottom: 13px;
    }
}

.share-with-client-popup-input-label {
    font-size: 17px;
    color: rgba(var(--background), 1);
    font-weight: 600;
    margin-bottom: 12px;
    .salutation-info {
        font-size: 16px;
        margin-left: 6px;
        color: rgba(var(--background), 0.72);
    }

    .salutation-tooltip {
        font-size: 12px;
        line-height: 16px;
        font-weight: normal;
    }

    @media screen and (max-width: 758px) {
        font-size: 15px;
    }
}
.bp3-tag-input-values{
    word-break: break-word;
}
div.tag-input {
    padding: 0 20px;
    min-height: 44px;
    background: #fafafa;
    border: 1px solid rgba(var(--background), 0.1);
    font-size: 16px;
    color: rgba(var(--background), 0.72);
    resize: none;
    box-shadow: unset;
    margin-bottom: 12px;
    border: 1px solid #100B281A;

    &.share-tag {
        min-height: 28px;
        font-size: 16px;
        line-height: 20px;
    }

    &.bp3-active {
        border-color: #009cbd;
        box-shadow: none;
    }

    &.input-error {
        border-color: #f80000;
    }

    @media screen and (max-width: 758px) {
        font-size: 14px;
        line-height: 24px;
        padding: 0 13px;
    }
}

.share-with-client-input {
    padding: 13px 20px 1px;
    background: #fafafa;
    border: 1px solid rgba(var(--background), 0.1);
    font-size: 16px;
    font-weight: 500;
    color: rgba(var(--background), 0.72);
    resize: none;
    margin-bottom: 12px;
    overflow: hidden;
    min-height: 230px;

    &.salutation {
        padding-left: 80px;
        @media screen and (max-width: 758px) {
            padding-left: 76px;
        }
    }

    &:focus {
        border-color: #009cbd;
        box-shadow: none;
        outline: none;
    }

    &.error {
        border-color: #f80000;
    }

    @media screen and (max-width: 758px) {
        font-size: 14px;
        padding: 13px 16px;
    }
}

.salutation-inner-hello {
    padding: 3px 7px;
    margin-top: 10px;
    margin-right: 12px;
    height: 24px;
    box-shadow: 0px 0px 2px #0f0f0f1f;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    color: rgba(16, 11, 40, 0.64);
    background-color: #ffffff;
}

.share-with-client-add-recipient {
    font-size: 15px;
    color: #009cbd;
    cursor: pointer;
    text-align: right;
    font-family: Helvetica;

    &:hover {
        opacity: 0.9;
    }

    @media screen and (max-width: 758px) {
        font-size: 13px;
    }
}

.share-with-client-input-error {
    font-size: 15px;
    color: #f80000;
    text-align: right;
    word-break: break-word;

    @media screen and (max-width: 758px) {
        font-size: 13px;
    }
}

.share-with-client-button {
    outline: none;
    padding: 0 1rem;
    height: 5.6rem;
    width: 100%;
    border: 0.1rem solid rgba(var(--background), 1);
    background-color: rgba(var(--background), 1);
    text-align: center;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2rem;
    color: #ffffff;
    letter-spacing: 0.32px;
    text-transform: capitalize;
    cursor: pointer;
    overflow: hidden;
    word-break: break-word;
    transition: background-color 240ms ease-in-out, color 240ms ease-in-out,
        border 240ms ease-in-out;

    &.disabled {
        color: rgba(16, 11, 40, 0.72);
        background-color: rgba(16, 11, 40, 0.05);
        border-color: rgba(16, 11, 40, 0.05);
        font-weight: 600;
        cursor: default;
        pointer-events: none;
        &:hover {
            color: rgba(16, 11, 40, 0.72);
            background-color: rgba(16, 11, 40, 0.05);
            border-color: rgba(16, 11, 40, 0.05);
        }
    }

    &:active {
        transform: scale(0.98);
    }

    &:hover {
        background-color: #009cbd;
        border-color: #009cbd;
        color: #ffffff;
    }
}

.share-with-client-content-footer {
    padding: 32px 0;
    display: flex;
    flex-direction: column;

    p {
        font-size: 14px;
        color: rgba(var(--background), 0.72);

        @media screen and (max-width: 758px) {
            font-size: 10px !important;
        }
    }

    a {
        color: #009cbd;
        text-decoration: underline;
        margin-left: 2px;
    }

    &-info{
        text-transform: uppercase;
    }
}

.share-with-client-checkbox {
    display: none;

    & + .share-with-client-label {
        .checkbox-icon {
            background: rgba(var(--background), 0.02);
            border: 1px solid #d6d5da;
            border-radius: 2px;
            width: 20px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 12px;

            .icon-check {
                font-size: 12px;
                display: none;
            }
        }
    }

    &:checked + .share-with-client-label {
        .checkbox-icon {
            background: #fff;
            border: 1px solid rgba(var(--background), 1);
            .icon-check {
                display: inline;
                font-size: 12px;
                color: #009cbd;
            }
        }
    }
}

.share-with-client-label {
    cursor: pointer;
    align-items: center;
    justify-content: center;
    display: flex;
    padding-left: 11px;
    font-size: 16px;
    color: rgba(var(--background), 1);
}

.share-with-client-checkbox-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 16px;
}
