.datepicker-holder {
  display: flex;
  align-items: center;
  max-width: 40rem;
  width: 100%;
  height: 4.6rem;
  padding: 0 2rem;
  position: relative;
  margin: 0 auto;

  &:hover {
    .datepicker-icon,
    .datepicker-arrow {
      color: rgba(var(--background), 1);
    }
  }

  @media screen and (max-width: 1610px) and (min-width: 980px) {
    height: 3.4rem;
    min-width: 294px;
    max-width: 294px;
    padding: 0 1.6rem;
    justify-content: center;
  }
}
