.select-paid-media-wrapper {
  animation: steps 480ms ease-in-out;
}

.select-paid-media-title {
  font-size: 1.8rem;
  line-height: 3.4rem;
  color: rgba(var(--background), 1);
  font-weight: 300;
  margin-bottom: 4.8rem;
  > span {
     font-weight: 600;
  }
}
.select-paid-media-cards{
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(35.2rem, 1fr));
  grid-gap: 3.2rem;
}
.select-paid-media-card{
  padding: 2.8rem 3.6rem;
  box-shadow: 0 0 0 0.1rem rgba(#100B28, 0.12);
  transition: padding 240ms;
  cursor: pointer;
  &.active{
    background-color: #FCFCFC;
    box-shadow: 0 1rem 2.5rem #F0F0F0;
    cursor: default;
    .select-paid-media-car-icon{
      opacity: 1;
      transform: translate(0, 0);
    }
  }
}
.select-paid-package-name-row{
  position: relative;
  width: 100%;
  padding-right: 3rem;
  margin-bottom: 3.8rem;
}
.select-paid-media-car-icon{
  position: absolute;
  display: block;
  right: 0.4rem;
  top: 0;
  font-size: 2.4rem;
  color: rgba(var(--brand-color), 1);
  opacity: 0;
  transform: translate(0, 140%);
  transition: all 240ms;
  transition-timing-function: ease-in-out;
}
.select-paid-package-name{
  font-size: 2.4rem;
  line-height: 3rem;
  color: rgba(var(--background), 1);
  font-weight: 600;
  text-transform: uppercase;
  word-break: break-word;
  letter-spacing: 0.9px;
}
.select-paid-package-price-row{
  margin-bottom: 5rem;
}
.select-paid-package-price{
  font-size: 5.6rem;
  line-height: 7.1rem;
  font-weight: bold;
  color: rgba(var(--background), 1);
  margin-bottom: 0.3rem;
  word-break: break-all;
}
.select-paid-package-day{
  display: block;
  font-size: 1.6rem;
  line-height: 2rem;
  color: rgba(var(--background), 1);
  opacity: 0.64;
  word-break: break-all;
}
.select-paid-package-reached{
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: rgba(var(--background), 1);
  word-break: break-word;
}