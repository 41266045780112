.licensing-discrepancy-popup {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transition: all 100ms linear;
  display: flex;
  justify-content: center;
  align-items: center;

  &.open {
    pointer-events: initial;
    opacity: 1;
    background: rgba(var(--background), 0.2);
    z-index: 5000;
    overflow: hidden;

    .licensing-discrepancy-popup-container {
      animation: cancel-the-ad-popup-show 600ms ease-in-out forwards;
    }
  }

  &:not(.open) {
    pointer-events: none;
    opacity: 0;
  }

  &-container {
    width: 64rem;
    background: white;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0.1rem 0 0 #ecebee;
    border-radius: 0.8rem;
    padding: 4.8rem;

    .popup-close {
      position: absolute;
      top: 1.2rem;
      right: 1.2rem;
      outline: none;
      border: none;
      width: 3.6rem;
      height: 3.6rem;
      cursor: pointer;
      font-size: 1.2rem;
      color: var(--background);
      transition: background-color 240ms ease-in-out;
      border-radius: 50%;

      &:hover {
        background-color: rgba(var(--background), 0.05);
      }
    }
  }

  &-section {
    flex-grow: 1;
    text-align: center;
    width: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;

    &-title {
      font-size: 2.6rem;
      line-height: 3.3rem;
      font-weight: 600;
      color: rgba(var(--background), 1);
      margin-bottom: 2rem;
      padding: 0 2rem;
      white-space: break-spaces;
    }

    &-info {
      font-size: 1.8rem;
      line-height: 2.8rem;
      color: rgba(var(--background), 0.72);
      flex-grow: 1;
      max-height: 44rem;
      overflow: auto;
    }
  }

  &-footer {
    margin-top: 4rem;
    outline: none;
    border: none;
    border-radius: 2px;
    width: 100%;
    height: 5.6rem;
    font-size: 1.6rem;
    line-height: 2rem;
    letter-spacing: 0.18rem;
    text-transform: uppercase;
    background-color: #100b28;
    color: #ffffff;
    transition: background-color 240ms ease-in-out, color 240ms ease-in-out;
    cursor: pointer;

    &.disabled {
      background-color: rgba(var(--background), 0.05);
      color: #100b28b8;
      pointer-events: none;
    }

    &:active {
      transform: scale(0.98);
    }
  }
}

@keyframes cancel-the-ad-popup-show {
  0% {
    transform: translate(0, 100vh);
  }

  100% {
    transform: translate(0, 0);
  }
}

//13inch Macbook START
@media screen and (max-width: 1610px) {
  .licensing-discrepancy-popup {
    &-container {
      width: 48rem;
      padding: 3.2rem 4rem 4rem;

      .popup-close {
        font-size: 1rem;
        width: 2.6rem;
        height: 2.6rem;
      }
    }

    &-section {
      &-title {
        font-size: 1.8rem;
        line-height: 2.4rem;
        margin-bottom: 1.1rem;
        padding: 0rem;
      }

      &-info {
        font-size: 1.3rem;
        line-height: 2.1rem;
        max-height: 33rem;
      }
    }

    &-footer {
      height: 4rem;
      font-size: 1.2rem;
      line-height: 100%;
      letter-spacing: 0.12rem;
      margin-top: 2.4rem;
    }
  }
}

//Tablet START
@media screen and (max-width: 980px) {
  .licensing-discrepancy-popup {
    &-container {
      width: 64rem;
      padding: 4rem 4.8rem 4.8rem;

      .popup-close {
        font-size: 1.2rem;
        width: 3.6rem;
        height: 3.6rem;
      }
    }

    &-section {
      &-title {
        font-size: 2.4rem;
        line-height: 3.2rem;
        margin-bottom: 1.7rem;
      }

      &-info {
        font-size: 1.8rem;
        line-height: 2.8rem;
        max-height: 44rem;
      }
    }

    &-footer {
      height: 5.6rem;
      font-size: 1.5rem;
      line-height: 100%;
      letter-spacing: 0.12rem;
      margin-top: 3.6rem;
    }
  }
}

// Mobile START
@media screen and (max-width: 738px) {
  .licensing-discrepancy-popup {
    align-items: baseline;

    &-container {
      width: 100%;
      border-radius: 0;
      padding: 4rem 2.4rem 3.2rem 2.4rem;

      .popup-close {
        width: 3.6rem;
        height: 3.6rem;
        font-size: 1.2rem;
        top: 0.8rem;
        right: 0.8rem;
      }
    }

    &-section {
      &-title {
        font-size: 1.6rem;
        line-height: 2.3rem;
        margin-bottom: 0.8rem;
      }

      &-info {
        font-size: 1.4rem;
        line-height: 2.2rem;
        max-height: 29rem;
        padding: 0 1.6rem;
      }
    }

    &-footer {
      height: 5.6rem;
      font-size: 1.5rem;
      line-height: 100%;
      letter-spacing: 0.12rem;
      margin-top: 2.4rem;
    }
  }
}
