.client-finder-notification {
  position: relative;
  display: flex;
  align-items: flex-start;
  padding: 0.8rem 1.2rem;
  margin-top: 1.6rem;
  width: 100%;
  min-height: 40px;
  background: #ffffff;
  box-shadow: 0 10px 25px #0000000f;
  border: 1px solid #ffffff;
  border-radius: 1px;
  opacity: 1;
  font-size: 1.6rem;
  line-height: 2rem;
  color: rgba(var(--background), 1);

  @media screen and (max-width: 1619px) {
    min-height: 36px;
    font-size: 1.3rem;
    line-height: 1.7rem;
  }

  @media screen and (max-width: 979px) {
    min-height: 40px;
    font-size: 1.6rem;
    line-height: 2.4rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
  }

  @media screen and (max-width: 767px) {
    // display: block;
    font-size: 1.3rem;
    line-height: 2rem;
    padding: 1rem;
  }

  &.warning {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0 8px 16px rgba(var(--danger-color), 0.12);
    font-weight: 510;

    &:before {
      width: 0.4rem;
    }

    .icon-info-full {
      color: rgba(var(--danger-color), 1);
    }
  }

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 100%;
    background-color: rgba(var(--danger-color), 1);
    transition: width 120ms;
  }

  > i {
    font-size: 1.6rem;
    margin-right: 0.8rem;
    margin-top: 4px;
    color: #a2a0ab;

    @media screen and (max-width: 1619px) {
      font-size: 1.4rem;
      margin-top: 2px;
    }
    @media screen and (max-width: 979px) {
      font-size: 1.6rem;
      margin-top: 3px;
    }
  }
}
