.rm-manager-table {
  width: 100%;
  height: fit-content;
  box-shadow: 0 0.2rem 0.8rem #f0f0f0;
  background-color: #ffffff;
  position: relative;
  transition: padding 240ms;
  border: 0.1rem solid rgba(var(--border-color), 1);
  // border-right: 1rem solid rgba(var(--border-color), 1);
  // border-bottom: 0.1rem solid rgba(var(--border-color), 1);

  &.fixed-height {
    min-height: 400px;
  }
}
.rm-manager-table-rows-group {
  width: 100%;
  height: 8.4rem;
  &.open {
    height: auto !important;
    > .rm-manager-table-row {
      position: sticky;
      top: 60px;
      background-color: white;
      z-index: 12;
    }
  }
}
.rm-manager-table-hidden-rows {
  width: 100%;
  height: auto;
  .rm-manager-table-hidden-rows-layer-2.open {
    .rm-manager-table-visible-row:not(.region) {
      z-index: 9;
    }
  }
  &.open {
    height: auto;
    .rm-manager-table-hidden-rows-layer-2.open {
      .rm-manager-table-visible-row:not(.region) {
        top: 199px;
      }
    }
    .rm-manager-table-visible-row {
      position: sticky;
      top: 143px;
      z-index: 10;
    }
  }
}
.rm-manager-table-hidden-rows-layer-2 {
  width: 100%;
  height: 0;
  overflow: unset;
  &.open {
    height: auto;
  }
}
.rm-manager-table-row {
  display: flex;
  align-content: center;
  width: 100%;
  height: 8.4rem;
  border-bottom: 0.1rem solid rgba(var(--border-color), 1);
  animation: rm-table-row-show 240ms ease-in-out;
  &.head {
    height: 6rem;
    position: relative;
    z-index: 100;
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    background-color: white;
    z-index: 14;
  }
  &:not(.head):not(.total) {
    &:hover {
      cursor: pointer;
      background-color: #fbfbfc;
    }
  }
  &.total.hidden {
    display: none;
  }
}
.rm-manager-table-visible-row {
  display: flex;
  align-content: center;
  width: 100%;
  height: 5.6rem;
  background-color: #f6f6f7;
  cursor: pointer;
  &.region {
    background-color: #e6e6e8;
  }
  &:not(:last-child) {
    border-bottom: 0.1rem solid #e1dfe4;
  }
  &.empty-agents {
    cursor: default;
  }
}
@keyframes rm-table-row-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.rm-manager-table-column {
  flex: 1;
  display: flex;
  align-items: center;
  padding-right: 1rem;
  &:first-child {
    padding-left: 3.9rem;
    width: 23%;
    flex: 0 0 auto;
    &.minor-region {
      padding-left: 5.9rem;
    }
  }
  &:last-child {
    width: 17.5rem;
    padding-right: 4.1rem;
    flex: 0 0 auto;
  }

  .icon-arrow-turn-right.rm-manager-table-icon {
    font-size: 10px;
    color: #100b28;
  }
}
.rm-manager-table-head-text {
  display: block;
  font-size: 1.2rem;
  line-height: 1.5rem;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: rgba(var(--background), 0.72);
  letter-spacing: 0.6px;
  transition: color 240ms;
  font-weight: 510;
  margin-right: 0.5rem;
}
.rm-manager-min-info-icon {
  font-size: 1.2rem;
  display: block;
  color: rgba(#100b28, 0.54);
  @media screen and(max-width: 980px) {
    display: none;
  }
}

.bp3-tooltip2 svg .bp3-popover2-arrow-fill {
  fill: rgba(var(--background), 1);
}

.rm-manager-min-info-text {
  white-space: nowrap;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #ffffff;
  letter-spacing: 0.18px;
  text-align: center;
  z-index: 9999;

  > span {
    display: block;
  }
}

.rm-manager-t-address {
  display: block;
  font-size: 1.3rem;
  line-height: 1.6rem;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: rgba(var(--background), 0.72);
  letter-spacing: 0.65px;
  font-weight: 510;
  margin-left: 10px;
}
.rm-manager-table-user-img {
  flex: 0 0 auto;
  display: block;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 1.6rem;
}
.rm-manager-table-text {
  display: block;
  font-size: 1.5rem;
  line-height: 1.9rem;
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: rgba(var(--background), 1);
}
.rm-manager-table-button {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  width: 13.4rem;
  height: 3.2rem;
  gap: 1rem;
  background-color: rgba(var(--background), 1);
  color: #ffffff;
  cursor: pointer;
  text-transform: capitalize;
  &:active {
    transform: scale(0.98);
  }
  > span {
    font-size: 1.5rem;
    line-height: 1.9rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  > i {
    transition: transform 120ms;
    transform-origin: center;
    font-size: 0.8rem;
    padding-top: 0.2rem;
    &.show {
      transform: rotateX(180deg) translate(0, -0.2rem);
    }
  }
  &.empty-agents {
    background-color: #999b9f;
    cursor: default;
    pointer-events: none;
  }
}
.rm-manager-table-total-text {
  font-size: 1.5rem;
  line-height: 1.9rem;
  color: rgba(var(--background), 1);
  font-weight: 600;
  text-transform: capitalize;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
@media screen and (max-width: 1610px) and (min-width: 980px) {
  .rm-manager-table-column {
    &:first-child {
      padding-left: 3.1rem;
      width: 29.069%;
      &.minor-region {
        padding-left: 5.1rem;
      }
    }
    &:last-child {
      width: 16.2rem;
    }
  }
  .rm-manager-table-text {
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
  .rm-manager-t-address {
    font-size: 1.1rem;
    line-height: 1.4rem;
    letter-spacing: 0.55px;
  }
  .rm-manager-table-button {
    width: 12rem;
    > span {
      font-size: 1.3rem;
      line-height: 1.6rem;
    }
  }
  .rm-manager-table-total-text {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  .rm-manager-table-user-img {
    width: 4rem;
    height: 4rem;
    margin-right: 1.4rem;
  }
  .rm-manager-table-rows-group {
    height: 7.2rem;
    overflow: unset;

    &.open > .rm-manager-table-row {
      top: 46px;
    }
  }
  .rm-manager-table-row {
    height: 7.2rem;
    &.head {
      height: 4.6rem;
    }
  }
  .rm-manager-table-head-text {
    font-size: 1.1rem;
    line-height: 1.4rem;
    letter-spacing: 0.55px;
    margin-right: 0.5rem;
  }

  .rm-manager-table-hidden-rows.open
    .rm-manager-table-hidden-rows-layer-2.open {
    .rm-manager-table-visible-row:not(.region) {
      top: 173px;
    }
  }
  .rm-manager-table-hidden-rows.open .rm-manager-table-visible-row {
    top: 117px;
  }
}
@media screen and(max-width: 980px) {
  .rm-manager-table-column {
    &:first-child {
      width: 26.045%;
      padding-left: 3.2rem;
      &.minor-region {
        padding-left: 5.1rem;
      }
    }
    &:last-child {
      width: 13.2rem;
      padding-right: 2.4rem;
    }
  }
  .rm-manager-table-row {
    &.head {
      height: 5.6rem;
    }
  }
  .rm-manager-table-text {
    font-size: 1.3rem;
    line-height: 1.6rem;
    white-space: normal;
  }
  .rm-manager-t-address {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }
  .rm-manager-table-button {
    width: 11.8rem;
    min-width: 11.8rem;
    gap: 0.5rem;
    > span {
      font-size: 1.3rem;
    }
  }
  .rm-manager-table-user-img {
    width: 4rem;
    height: 4rem;
    margin-right: 1.2rem;
  }
  .rm-manager-table-total-text {
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  .rm-manager-table-column {
    &:last-child {
      .rm-manager-table-head-text {
        margin-right: 0;
      }
    }
  }
  .rm-manager-table-hidden-rows.open
    .rm-manager-table-hidden-rows-layer-2.open {
    .rm-manager-table-visible-row:not(.region) {
      top: 195px;
    }
  }
  .rm-manager-table-hidden-rows.open .rm-manager-table-visible-row {
    top: 140px;
  }
  .rm-manager-table-rows-group.open > .rm-manager-table-row {
    top: 56px;
  }
}
