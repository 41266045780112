.simple-image-container {
  background-color: rgba(var(--background), 1);
  padding: 47px 0;
  width: 540px;
  height: 540px;
  &.visible-hidden {
    position: fixed;
    top: -2000px;
  }
}

.simple-image {
  width: auto;
  height: 100%;
  /* max-height: 446px; */
  /* object-fit: contain; */
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}
