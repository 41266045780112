.no-result {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100;
  padding: 5rem 4rem 4rem;
  pointer-events: none;

  &-content{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin: auto;
    max-width: 53rem;
  }
  &-img{
    display: block;
    max-width: 10rem;
    max-height: 10rem;
    width: auto;
    height: auto;
    object-fit: contain;
    margin-bottom: 0.8rem;
    color: rgba(var(--background), 1);
    animation: no-result 360ms ease-in-out;
  }
  &-title{
    font-size: 3.2rem;
    line-height: 4.1rem;
    margin-bottom: 1.2rem;
    font-weight: 600;
    animation: fadein 150ms;
  }
  &-info{
    font-size: 1.8rem;
    line-height: 3rem;
    font-weight: 510;
    animation: fadein 150ms;
  }
}

@keyframes no-result {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% {
    opacity: 1;
    transform: scale(0.9);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
