.footer {
  width: 100%;
  padding: 5.6rem 4.8rem 3.6rem 4.8rem;
  .copyright-text-info {
    @media screen and (max-width: 979px) {
      font-size: 1.2rem;
      line-height: 2rem;
    }
  }
}
.footer-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.copyright-text {
  color: rgba(#100b28, 0.5);
  font-weight: 500;
}
.copyright-text-info {
  position: relative;
  font-size: 1.2rem;
  line-height: 2rem;
}
.copyright-text-tm {
  position: absolute;
  right: -1.2rem;
  top: -0.6rem;
  font-size: 0.7rem;
}
.copyright-info {
  width: 20px;
  height: 22px;
  position: fixed;
  right: 3.4rem;
  bottom: 3.4rem;
  flex: 0 0 auto;
  display: block;
  cursor: pointer;
  font-size: 2rem;
  color: rgba(#100b28, 0.7);
  transition: color 240ms;
  z-index: 200;
  margin-right: 0.1rem;
  &:hover {
    color: rgba(var(--background), 1);
  }
}
