.bp3-tag-input {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    cursor: text;
    height: auto;
    line-height: inherit;
    min-height: 30px;
    padding-left: 5px;
    padding-right: 0;
    .bp3-icon > svg {
        display: block;
    }
    .bp3-icon > svg:not([fill]) {
        fill: currentColor;
    }

& > * {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}
& > &-values {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
}
& &-icon {
    color: #5c7080;
    margin-left: 2px;
    margin-right: 7px;
    margin-top: 7px;
}
& &-values {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-item-align: stretch;
    align-self: stretch;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: 7px;
    margin-top: 5px;
    min-width: 0;
}
& &-values > * {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}
& &-values > .bp3-fill {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
}
& &-values::before,
& &-values > * {
    margin-right: 5px;
}
& &-values:empty::before,
& &-values > :last-child {
    margin-right: 0;
}
& &-values:first-child .bp3-input-ghost:first-child {
    padding-left: 5px;
}
& &-values > * {
    margin-bottom: 5px;
}
& .bp3-tag {
    overflow-wrap: break-word;
}
& .bp3-tag.bp3-active {
    outline: rgba(19, 124, 189, 0.6) auto 2px;
    outline-offset: 0;
    -moz-outline-radius: 6px;
}
& .bp3-input-ghost {
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    line-height: 20px;
    width: 80px;
}
& .bp3-input-ghost:disabled,
& .bp3-input-ghost.bp3-disabled {
    cursor: not-allowed;
}
& .bp3-button,
& .bp3-spinner {
    margin: 3px;
    margin-left: 0;
}
& .bp3-button {
    min-height: 24px;
    min-width: 24px;
    padding: 0 7px;
}
&.bp3-large {
    height: auto;
    min-height: 40px;
}
&.bp3-large::before,
&.bp3-large > * {
    margin-right: 10px;
}
&.bp3-large:empty::before,
&.bp3-large > :last-child {
    margin-right: 0;
}
&.bp3-large &-icon {
    margin-left: 5px;
    margin-top: 10px;
}
&.bp3-large .bp3-input-ghost {
    line-height: 30px;
}
&.bp3-large .bp3-button {
    min-height: 30px;
    min-width: 30px;
    padding: 5px 10px;
    margin: 5px;
    margin-left: 0;
}
&.bp3-large .bp3-spinner {
    margin: 8px;
    margin-left: 0;
}
&.bp3-active {
    background-color: #ffffff;
    -webkit-box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3),
        inset 0 1px 1px rgba(16, 22, 26, 0.2);
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3),
        inset 0 1px 1px rgba(16, 22, 26, 0.2);
}
&.bp3-active.bp3-intent-primary {
    -webkit-box-shadow: 0 0 0 1px #106ba3, 0 0 0 3px rgba(16, 107, 163, 0.3),
        inset 0 1px 1px rgba(16, 22, 26, 0.2);
    box-shadow: 0 0 0 1px #106ba3, 0 0 0 3px rgba(16, 107, 163, 0.3),
        inset 0 1px 1px rgba(16, 22, 26, 0.2);
}
&.bp3-active.bp3-intent-success {
    -webkit-box-shadow: 0 0 0 1px #0d8050, 0 0 0 3px rgba(13, 128, 80, 0.3),
        inset 0 1px 1px rgba(16, 22, 26, 0.2);
    box-shadow: 0 0 0 1px #0d8050, 0 0 0 3px rgba(13, 128, 80, 0.3),
        inset 0 1px 1px rgba(16, 22, 26, 0.2);
}
&.bp3-active.bp3-intent-warning {
    -webkit-box-shadow: 0 0 0 1px #bf7326, 0 0 0 3px rgba(191, 115, 38, 0.3),
        inset 0 1px 1px rgba(16, 22, 26, 0.2);
    box-shadow: 0 0 0 1px #bf7326, 0 0 0 3px rgba(191, 115, 38, 0.3),
        inset 0 1px 1px rgba(16, 22, 26, 0.2);
}
&.bp3-active.bp3-intent-danger {
    -webkit-box-shadow: 0 0 0 1px #c23030, 0 0 0 3px rgba(194, 48, 48, 0.3),
        inset 0 1px 1px rgba(16, 22, 26, 0.2);
    box-shadow: 0 0 0 1px #c23030, 0 0 0 3px rgba(194, 48, 48, 0.3),
        inset 0 1px 1px rgba(16, 22, 26, 0.2);
}
.bp3-dark & &-icon,
&.bp3-dark &-icon {
    color: #a7b6c2;
}
.bp3-dark & .bp3-input-ghost,
&.bp3-dark .bp3-input-ghost {
    color: #f5f8fa;
}
.bp3-dark & .bp3-input-ghost::-webkit-input-placeholder,
&.bp3-dark .bp3-input-ghost::-webkit-input-placeholder {
    color: rgba(167, 182, 194, 0.6);
}
.bp3-dark & .bp3-input-ghost::-moz-placeholder,
&.bp3-dark .bp3-input-ghost::-moz-placeholder {
    color: rgba(167, 182, 194, 0.6);
}
.bp3-dark & .bp3-input-ghost:-ms-input-placeholder,
&.bp3-dark .bp3-input-ghost:-ms-input-placeholder {
    color: rgba(167, 182, 194, 0.6);
}
.bp3-dark & .bp3-input-ghost::-ms-input-placeholder,
&.bp3-dark .bp3-input-ghost::-ms-input-placeholder {
    color: rgba(167, 182, 194, 0.6);
}
.bp3-dark & .bp3-input-ghost::placeholder,
&.bp3-dark .bp3-input-ghost::placeholder {
    color: rgba(167, 182, 194, 0.6);
}
.bp3-dark &.bp3-active,
&.bp3-dark.bp3-active {
    background-color: rgba(16, 22, 26, 0.3);
    -webkit-box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3),
        inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    box-shadow: 0 0 0 1px #137cbd, 0 0 0 1px #137cbd, 0 0 0 3px rgba(19, 124, 189, 0.3),
        inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
}
.bp3-dark &.bp3-active.bp3-intent-primary,
&.bp3-dark.bp3-active.bp3-intent-primary {
    -webkit-box-shadow: 0 0 0 1px #106ba3, 0 0 0 3px rgba(16, 107, 163, 0.3),
        inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    box-shadow: 0 0 0 1px #106ba3, 0 0 0 3px rgba(16, 107, 163, 0.3),
        inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
}
.bp3-dark &.bp3-active.bp3-intent-success,
&.bp3-dark.bp3-active.bp3-intent-success {
    -webkit-box-shadow: 0 0 0 1px #0d8050, 0 0 0 3px rgba(13, 128, 80, 0.3),
        inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    box-shadow: 0 0 0 1px #0d8050, 0 0 0 3px rgba(13, 128, 80, 0.3),
        inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
}
.bp3-dark &.bp3-active.bp3-intent-warning,
&.bp3-dark.bp3-active.bp3-intent-warning {
    -webkit-box-shadow: 0 0 0 1px #bf7326, 0 0 0 3px rgba(191, 115, 38, 0.3),
        inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    box-shadow: 0 0 0 1px #bf7326, 0 0 0 3px rgba(191, 115, 38, 0.3),
        inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
}
.bp3-dark &.bp3-active.bp3-intent-danger,
&.bp3-dark.bp3-active.bp3-intent-danger {
    -webkit-box-shadow: 0 0 0 1px #c23030, 0 0 0 3px rgba(194, 48, 48, 0.3),
        inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
    box-shadow: 0 0 0 1px #c23030, 0 0 0 3px rgba(194, 48, 48, 0.3),
        inset 0 0 0 1px rgba(16, 22, 26, 0.3), inset 0 1px 1px rgba(16, 22, 26, 0.4);
}

.bp3-input-group .bp3-tag {
    margin: 5px;
}
.bp3-input-ghost {
    background: none;
    border: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    padding: 0;
}
.bp3-input-ghost::-webkit-input-placeholder {
    color: rgba(92, 112, 128, 0.6);
    opacity: 1;
}
.bp3-input-ghost::-moz-placeholder {
    color: rgba(92, 112, 128, 0.6);
    opacity: 1;
}
.bp3-input-ghost:-ms-input-placeholder {
    color: rgba(92, 112, 128, 0.6);
    opacity: 1;
}
.bp3-input-ghost::-ms-input-placeholder {
    color: rgba(92, 112, 128, 0.6);
    opacity: 1;
}
.bp3-input-ghost::placeholder {
    color: rgba(92, 112, 128, 0.6);
    opacity: 1;
}
.bp3-input-ghost:focus {
    outline: none !important;
}

.bp3-input-group.bp3-small .bp3-tag {
    min-height: 20px;
    min-width: 20px;
    margin: 2px;
}

.bp3-input-group.bp3-round .bp3-tag {
    border-radius: 30px;
}

.bp3-tag {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #5c7080;
    border: none;
    border-radius: 3px;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: #f5f8fa;
    font-size: 12px;
    line-height: 16px;
    max-width: 100%;
    min-height: 20px;
    min-width: 20px;
    padding: 2px 6px;
    position: relative;
}
.bp3-tag.bp3-interactive {
    cursor: pointer;
}
.bp3-tag.bp3-interactive:hover {
    background-color: rgba(92, 112, 128, 0.85);
}
.bp3-tag.bp3-interactive.bp3-active,
.bp3-tag.bp3-interactive:active {
    background-color: rgba(92, 112, 128, 0.7);
}
.bp3-tag > * {
    -webkit-box-flex: 0;
    -ms-flex-positive: 0;
    flex-grow: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
}
.bp3-tag > .bp3-fill {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-negative: 1;
    flex-shrink: 1;
}
.bp3-tag::before,
.bp3-tag > * {
    margin-right: 4px;
}
.bp3-tag:empty::before,
.bp3-tag > :last-child {
    margin-right: 0;
}
.bp3-tag:focus {
    outline: rgba(19, 124, 189, 0.6) auto 2px;
    outline-offset: 0;
    -moz-outline-radius: 6px;
}
.bp3-tag.bp3-round {
    border-radius: 30px;
    padding-left: 8px;
    padding-right: 8px;
}
.bp3-dark .bp3-tag {
    background-color: #bfccd6;
    color: #182026;
}
.bp3-dark .bp3-tag.bp3-interactive {
    cursor: pointer;
}
.bp3-dark .bp3-tag.bp3-interactive:hover {
    background-color: rgba(191, 204, 214, 0.85);
}
.bp3-dark .bp3-tag.bp3-interactive.bp3-active,
.bp3-dark .bp3-tag.bp3-interactive:active {
    background-color: rgba(191, 204, 214, 0.7);
}
.bp3-dark .bp3-tag > .bp3-icon,
.bp3-dark .bp3-tag .bp3-icon-standard,
.bp3-dark .bp3-tag .bp3-icon-large {
    fill: currentColor;
}
.bp3-tag > .bp3-icon,
.bp3-tag .bp3-icon-standard,
.bp3-tag .bp3-icon-large {
    fill: #ffffff;
}
.bp3-tag.bp3-large,
.bp3-large .bp3-tag {
    font-size: 14px;
    line-height: 20px;
    min-height: 30px;
    min-width: 30px;
    padding: 5px 10px;
}
.bp3-tag.bp3-large::before,
.bp3-tag.bp3-large > *,
.bp3-large .bp3-tag::before,
.bp3-large .bp3-tag > * {
    margin-right: 7px;
}
.bp3-tag.bp3-large:empty::before,
.bp3-tag.bp3-large > :last-child,
.bp3-large .bp3-tag:empty::before,
.bp3-large .bp3-tag > :last-child {
    margin-right: 0;
}
.bp3-tag.bp3-large.bp3-round,
.bp3-large .bp3-tag.bp3-round {
    padding-left: 12px;
    padding-right: 12px;
}
.bp3-tag.bp3-intent-primary {
    background: #137cbd;
    color: #ffffff;
}
.bp3-tag.bp3-intent-primary.bp3-interactive {
    cursor: pointer;
}
.bp3-tag.bp3-intent-primary.bp3-interactive:hover {
    background-color: rgba(19, 124, 189, 0.85);
}
.bp3-tag.bp3-intent-primary.bp3-interactive.bp3-active,
.bp3-tag.bp3-intent-primary.bp3-interactive:active {
    background-color: rgba(19, 124, 189, 0.7);
}
.bp3-tag.bp3-intent-success {
    background: #0f9960;
    color: #ffffff;
}
.bp3-tag.bp3-intent-success.bp3-interactive {
    cursor: pointer;
}
.bp3-tag.bp3-intent-success.bp3-interactive:hover {
    background-color: rgba(15, 153, 96, 0.85);
}
.bp3-tag.bp3-intent-success.bp3-interactive.bp3-active,
.bp3-tag.bp3-intent-success.bp3-interactive:active {
    background-color: rgba(15, 153, 96, 0.7);
}
.bp3-tag.bp3-intent-warning {
    background: #d9822b;
    color: #ffffff;
}
.bp3-tag.bp3-intent-warning.bp3-interactive {
    cursor: pointer;
}
.bp3-tag.bp3-intent-warning.bp3-interactive:hover {
    background-color: rgba(217, 130, 43, 0.85);
}
.bp3-tag.bp3-intent-warning.bp3-interactive.bp3-active,
.bp3-tag.bp3-intent-warning.bp3-interactive:active {
    background-color: rgba(217, 130, 43, 0.7);
}
.bp3-tag.bp3-intent-danger {
    background: #db3737;
    color: #ffffff;
}
.bp3-tag.bp3-intent-danger.bp3-interactive {
    cursor: pointer;
}
.bp3-tag.bp3-intent-danger.bp3-interactive:hover {
    background-color: rgba(219, 55, 55, 0.85);
}
.bp3-tag.bp3-intent-danger.bp3-interactive.bp3-active,
.bp3-tag.bp3-intent-danger.bp3-interactive:active {
    background-color: rgba(219, 55, 55, 0.7);
}
.bp3-tag.bp3-fill {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
}
.bp3-tag.bp3-minimal > .bp3-icon,
.bp3-tag.bp3-minimal .bp3-icon-standard,
.bp3-tag.bp3-minimal .bp3-icon-large {
    fill: #5c7080;
}
.bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) {
    background-color: rgba(138, 155, 168, 0.2);
    color: #182026;
}
.bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive {
    cursor: pointer;
}
.bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive:hover {
    background-color: rgba(92, 112, 128, 0.3);
}
.bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive.bp3-active,
.bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive:active {
    background-color: rgba(92, 112, 128, 0.4);
}
.bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) {
    color: #f5f8fa;
}
.bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive {
    cursor: pointer;
}
.bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive:hover {
    background-color: rgba(191, 204, 214, 0.3);
}
.bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive.bp3-active,
.bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]).bp3-interactive:active {
    background-color: rgba(191, 204, 214, 0.4);
}
.bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) > .bp3-icon,
.bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) .bp3-icon-standard,
.bp3-dark .bp3-tag.bp3-minimal:not([class*="bp3-intent-"]) .bp3-icon-large {
    fill: #a7b6c2;
}
.bp3-tag.bp3-minimal.bp3-intent-primary {
    background-color: rgba(19, 124, 189, 0.15);
    color: #106ba3;
}
.bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive {
    cursor: pointer;
}
.bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive:hover {
    background-color: rgba(19, 124, 189, 0.25);
}
.bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive.bp3-active,
.bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive:active {
    background-color: rgba(19, 124, 189, 0.35);
}
.bp3-tag.bp3-minimal.bp3-intent-primary > .bp3-icon,
.bp3-tag.bp3-minimal.bp3-intent-primary .bp3-icon-standard,
.bp3-tag.bp3-minimal.bp3-intent-primary .bp3-icon-large {
    fill: #137cbd;
}
.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary {
    background-color: rgba(19, 124, 189, 0.25);
    color: #48aff0;
}
.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive {
    cursor: pointer;
}
.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive:hover {
    background-color: rgba(19, 124, 189, 0.35);
}
.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive.bp3-active,
.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-primary.bp3-interactive:active {
    background-color: rgba(19, 124, 189, 0.45);
}
.bp3-tag.bp3-minimal.bp3-intent-success {
    background-color: rgba(15, 153, 96, 0.15);
    color: #0d8050;
}
.bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive {
    cursor: pointer;
}
.bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive:hover {
    background-color: rgba(15, 153, 96, 0.25);
}
.bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive.bp3-active,
.bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive:active {
    background-color: rgba(15, 153, 96, 0.35);
}
.bp3-tag.bp3-minimal.bp3-intent-success > .bp3-icon,
.bp3-tag.bp3-minimal.bp3-intent-success .bp3-icon-standard,
.bp3-tag.bp3-minimal.bp3-intent-success .bp3-icon-large {
    fill: #0f9960;
}
.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success {
    background-color: rgba(15, 153, 96, 0.25);
    color: #3dcc91;
}
.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive {
    cursor: pointer;
}
.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive:hover {
    background-color: rgba(15, 153, 96, 0.35);
}
.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive.bp3-active,
.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-success.bp3-interactive:active {
    background-color: rgba(15, 153, 96, 0.45);
}
.bp3-tag.bp3-minimal.bp3-intent-warning {
    background-color: rgba(217, 130, 43, 0.15);
    color: #bf7326;
}
.bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive {
    cursor: pointer;
}
.bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive:hover {
    background-color: rgba(217, 130, 43, 0.25);
}
.bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive.bp3-active,
.bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive:active {
    background-color: rgba(217, 130, 43, 0.35);
}
.bp3-tag.bp3-minimal.bp3-intent-warning > .bp3-icon,
.bp3-tag.bp3-minimal.bp3-intent-warning .bp3-icon-standard,
.bp3-tag.bp3-minimal.bp3-intent-warning .bp3-icon-large {
    fill: #d9822b;
}
.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning {
    background-color: rgba(217, 130, 43, 0.25);
    color: #ffb366;
}
.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive {
    cursor: pointer;
}
.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive:hover {
    background-color: rgba(217, 130, 43, 0.35);
}
.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive.bp3-active,
.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-warning.bp3-interactive:active {
    background-color: rgba(217, 130, 43, 0.45);
}
.bp3-tag.bp3-minimal.bp3-intent-danger {
    background-color: rgba(219, 55, 55, 0.15);
    color: #c23030;
}
.bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive {
    cursor: pointer;
}
.bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive:hover {
    background-color: rgba(219, 55, 55, 0.25);
}
.bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive.bp3-active,
.bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive:active {
    background-color: rgba(219, 55, 55, 0.35);
}
.bp3-tag.bp3-minimal.bp3-intent-danger > .bp3-icon,
.bp3-tag.bp3-minimal.bp3-intent-danger .bp3-icon-standard,
.bp3-tag.bp3-minimal.bp3-intent-danger .bp3-icon-large {
    fill: #db3737;
}
.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger {
    background-color: rgba(219, 55, 55, 0.25);
    color: #ff7373;
}
.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive {
    cursor: pointer;
}
.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive:hover {
    background-color: rgba(219, 55, 55, 0.35);
}
.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive.bp3-active,
.bp3-dark .bp3-tag.bp3-minimal.bp3-intent-danger.bp3-interactive:active {
    background-color: rgba(219, 55, 55, 0.45);
}

.bp3-tag-remove {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: -2px;
    margin-right: -6px !important;
    margin-top: -2px;
    opacity: 0.5;
    padding: 2px;
    padding-left: 0;
}
.bp3-tag-remove:hover {
    background: none;
    opacity: 0.8;
    text-decoration: none;
}
.bp3-tag-remove:active {
    opacity: 1;
}
.bp3-tag-remove:empty::before {
    font-family: "Icons16", sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    content: "";
}
.bp3-large .bp3-tag-remove {
    margin-right: -10px !important;
    padding: 0 5px 0 0;
}
.bp3-large .bp3-tag-remove:empty::before {
    font-family: "Icons20", sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 1;
}

}