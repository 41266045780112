.rm-user-dashboard {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  z-index: 10;
  & + .your-ad-details-head-inner-column {
    // margin-right: auto;
    // margin-left: 7.2rem;
  }
}
.rm-agent-dashboard-close {
  flex: 0 0 auto;
  display: none;
  vertical-align: top;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  height: 2.4rem;
  color: #ffffff;
  padding: 0 1.6rem;
  cursor: pointer;
}
.rm-agent-dashboard-img {
  flex: 0 0 auto;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  margin-right: 1.6rem;
  object-fit: cover;
  &.header {
    width: 64px;
    height: 64px;
    &.placeholder {
      font-size: 2.4rem;
      background-color: rgba(var(--brand-color), 1);
    }
  }
  &.placeholder {
    color: #ffffff;
    font-size: 1.6rem;
    font-weight: 410;
    background-color: #100b28;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.rm-user-dashboard-column {
  flex: 1;
  min-width: 0;
}
.rm-user-dashboard-user-row {
  display: flex;
}
.rm-user-dashboard-user-name {
  font-size: 2.2rem;
  line-height: 2.8rem;
  color: #ffffff;
  font-weight: 600;
  text-transform: capitalize;
}
.rm-user-type {
  flex: 0 0 auto;
  text-align: center;
  margin-left: 0.8rem;
  position: relative;
  height: 1.7rem;
  line-height: 1.5rem;
  min-width: 4.3rem;
  padding: 0 0.2rem;
  background-color: rgba(var(--brand-color), 1);
  color: #ffffff;
  font-size: 1.2rem;
  text-transform: capitalize;
}

.rm-user-dashboard-text {
  display: block;
  padding-top: 0.3rem;
  font-size: 1.5rem;
  text-transform: capitalize;
  line-height: 1.9rem;
  color: rgba(#ffffff, 0.8);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 43rem;
}
@media screen and (max-width: 1610px) {
  .rm-user-dashboard {
    & + .your-ad-details-head-inner-column {
      margin-left: 3.8rem;
      margin-right: auto;
    }
  }
  .rm-agent-dashboard-img {
    width: 4rem;
    height: 4rem;
    margin-right: 1.2rem;
    &.header {
      width: 48px;
      height: 48px;
      &.placeholder {
        font-size: 2rem;
      }
    }
    &.placeholder {
      font-size: 1.4rem;
    }
  }
  .rm-user-dashboard-user-name {
    font-size: 1.8rem;
    line-height: 2.3rem;
  }
  .rm-user-dashboard-text {
    padding-top: 0.1rem;
    font-size: 1.3rem;
    line-height: 1.6rem;
    max-width: 38rem;
  }
  .rm-user-type {
    min-width: 3.7rem;
    height: 1.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}
@media screen and (max-width: 1240px) {
  .your-ad-details-head-inner {
    &.has-rm-manager:not(.pdf) {
      padding-top: 8.3rem;
      padding-bottom: 1.3rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .rm-user-dashboard {
        position: absolute;
        left: 0;
        top: 1.3rem;
      }
      .your-ad-details-head-inner-column {
        margin-left: 0;
      }
      .your-ad-details-head-info-column {
        min-width: 0;
        &:not(:last-child) {
          margin-right: 4.8rem;
        }
      }
      .your-ad-details-head-button {
        height: 42px;
        width: 182px;
        font-size: 13px;
        line-height: 20px;
      }
      .your-ad-details-download-button {
        i {
          font-size: 42px;
        }
      }
    }
  }
}

@media screen and (max-width: 738px) {
  .your-ad-details-head-inner {
    &.has-rm-manager:not(.pdf) {
      display: block;
      padding-bottom: 0.6rem;
      padding-top: 9rem;
      .rm-user-dashboard {
        top: -0.2rem;
        left: -1.8rem;
        width: calc(100% - 3rem);
      }
      .your-ad-details-download-button {
        position: absolute;
        top: 0;
        right: 0;
        width: 3.4rem;
        height: 4.2rem;
      }
      .rm-user-dashboard-text {
        white-space: inherit;
        word-break: break-word;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
      .your-ad-details-download-button-icon {
        font-size: 4.2rem;
      }
      .your-ad-details-head-button {
        display: none;
      }
      .add-preview-hide-mobile {
        margin-top: 1.6rem;
        margin-bottom: 0;
      }
      .your-ad-details-head-info {
        display: flex;
        align-items: center;
        &:before {
          content: "";
          display: block;
          position: absolute;
          left: 0;
          top: -1.8rem;
          width: 100%;
          height: 0.1rem;
          background-color: #403c53;
        }
      }
      .your-ad-details-head-info-column {
        width: auto;
        flex: 0 0 auto;
        &:not(:last-child) {
          margin-bottom: 0;
          margin-right: 4rem;
        }
        &:nth-child(3) {
          margin-right: 0;
        }
      }
    }
  }
  .analytics-d-head-inner {
    &.has-rm-manager:not(.pdf) {
      padding: 0;
      gap: 0;
      overflow: hidden;
    }
    .rm-user-dashboard {
      margin-left: -1.6rem;
    }
    .rm-user-dashboard-user-name {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      max-width: 53vw;
    }
  }
  .rm-agent-dashboard-img {
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 1.2rem;
    &.placeholder {
      font-size: 1.3rem;
    }
    &.header {
      width: 40px;
      height: 40px;
      z-index: 1;
      &.placeholder {
        font-size: 1.6rem;
      }
    }
  }
  .rm-agent-dashboard-close {
    display: inline-flex;
    padding: 0 1.4rem;
  }
  .rm-user-dashboard-user-name {
    font-size: 1.7rem;
    line-height: 2.2rem;
  }
  .rm-user-dashboard-text {
    padding-top: 0.1rem;
    font-size: 1.3rem;
    line-height: 1.6rem;
    white-space: pre-line;
    max-width: 20rem;
  }
  .rm-user-type {
    min-width: 3.4rem;
    font-size: 0.9rem;
    line-height: 1.2rem;
    height: 1.3rem;
  }
}
// for PDF
.pdf-main {
  .rm-user-dashboard-text {
    font-size: 2.4rem;
    line-height: 2.8rem;
    max-width: unset;
  }
  .rm-user-type {
    font-size: 1.8rem;
    line-height: 2rem;
    height: 2.4rem;
    padding: 0 0.6rem;
  }
  .rm-user-dashboard-user-name {
    font-size: 40px;
    line-height: 51px;
  }
  .rm-agent-dashboard-img {
    width: 100px;
    height: 100px;
  }
  .icon-left.rm-agent-dashboard-close {
    display: none;
  }
  .rm-user-dashboard + .your-ad-details-head-inner-column {
    margin-left: auto;
    margin-right: 4rem;
  }
}
