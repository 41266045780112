
@font-face {
  font-family: 'Euclid';
  src: url('./euclid-light/EuclidCircularALight.eot');
  src: url('./euclid-light/EuclidCircularALight.eot') format('embedded-opentype'),
  url('./euclid-light/EuclidCircularALight.woff2') format('woff2'),
  url('./euclid-light/EuclidCircularALight.woff') format('woff'),
  url('./euclid-light/EuclidCircularALight.ttf') format('truetype'),
  url('./euclid-light/EuclidCircularALight.svg#EuclidCircularALight') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Euclid';
  src: url('./euclid-regular/EuclidCircularARegular.eot');
  src: url('./euclid-regular/EuclidCircularARegular.eot') format('embedded-opentype'),
  url('./euclid-regular/EuclidCircularARegular.woff2') format('woff2'),
  url('./euclid-regular/EuclidCircularARegular.woff') format('woff'),
  url('./euclid-regular/EuclidCircularARegular.ttf') format('truetype'),
  url('./euclid-regular/EuclidCircularARegular.svg#EuclidCircularARegular') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Euclid';
  src: url('./euclid-medium/EuclidCircularAMedium.eot');
  src: url('./euclid-medium/EuclidCircularAMedium.eot?#iefix') format('embedded-opentype'),
  url('./euclid-medium/EuclidCircularAMedium.woff2') format('woff2'),
  url('./euclid-medium/EuclidCircularAMedium.woff') format('woff'),
  url('./euclid-medium/EuclidCircularAMedium.ttf') format('truetype'),
  url('./euclid-medium/EuclidCircularAMedium.svg#euclid_circular_alight') format('svg');
  font-weight: 510;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Euclid';
  src: url('./euclid-semibold/EuclidCircularASemiBold.eot');
  src: url('./euclid-semibold/EuclidCircularASemiBold.eot') format('embedded-opentype'),
  url('./euclid-semibold/EuclidCircularASemiBold.woff2') format('woff2'),
  url('./euclid-semibold/EuclidCircularASemiBold.woff') format('woff'),
  url('./euclid-semibold/EuclidCircularASemiBold.ttf') format('truetype'),
  url('./euclid-semibold/EuclidCircularASemiBold.svg#EuclidCircularASemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: fallback;
}
@font-face {
  font-family: 'Euclid';
  src: url('./euclid-bold/EuclidCircularABold.eot');
  src: url('./euclid-bold/EuclidCircularABold.eot') format('embedded-opentype'),
  url('./euclid-bold/EuclidCircularABold.woff2') format('woff2'),
  url('./euclid-bold/EuclidCircularABold.woff') format('woff'),
  url('./euclid-bold/EuclidCircularABold.ttf') format('truetype'),
  url('./euclid-bold/EuclidCircularABold.svg#EuclidCircularABold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: fallback;
}
