.manager-filter-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 3rem;
  padding-bottom: 1.4rem;
  border-bottom: 0.1rem solid #eff0f1;
  margin-bottom: 2rem;
}
.manager-filter-input-group {
  flex: 1;
  display: flex;
  align-items: center;
  @media screen and (max-width: 767px) {
   padding-left: 12px;
  }
}

.manager-filter-input {
  width: 100%;
  outline: none;
  border: none;
  color: rgba(#100b28, 0.72);
  font-size: 1.6rem;
  background: transparent;
  font-weight: inherit;
  line-height: 2rem;
  @media (max-width: 1619px) {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }

  &::placeholder {
    color: rgba(#100b28, 0.54);
  }

  &:focus {
    &::placeholder {
      color: rgba(var(--background), 1);
    }
  }
}

.manager-filter-datepicker {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  .icon-calendar {
    font-size: 16px;
  }
  @media (min-width: 768px) and (max-width: 980px) {
    .icon-calendar {
      font-size: 12px;
    }
  }
  @media screen and (max-width: 1919px) {
    .icon-calendar {
      font-size: 14px;
    }
  }
  .rm-manager-min-info-icon {
    @media (min-width: 768px) and (max-width: 980px) {
      display: block;
    }
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.manager-filter-datepicker-period {
  font-size: 1.6rem;
  line-height: 2rem;
  white-space: nowrap;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  @media screen and (max-width: 1919px) {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }
}

.date-picker-container {
  background-color: #ffffff;
  box-shadow: 0 0.8rem 1.6rem rgba(#000000, 0.12);
  // border-radius: 0.8rem;
  padding: 2.4rem;
  width: 336px;
  height: 325px;
  position: relative;
}

.date-picker-header {
  position: static;
}

.date-picker-header-title {
  line-height: 2.3rem;
  font-size: 1.6rem;
  color: #100b28;
  margin-bottom: 2.4rem;
  font-weight: 510;
}

.date-close-icon {
  position: absolute;
  top: 8px;
  right: 8px;
  width: 36px;
  height: 36px;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 10px;

  &:hover {
    background-color: #100b280d;
  }
}

.date-picker-footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-top: 24px;
  border-top: 1px solid #e0dfe4;
}

.submit-button {
  outline: none;
  padding: 0 1rem;
  height: 4rem;
  width: 10.8rem;
  background-color: rgba(var(--background), 1);
  text-align: center;
  font-size: 1.3rem;
  line-height: 2rem;
  color: #ffffff;
  letter-spacing: 0.32px;
  text-transform: capitalize;
  cursor: pointer;
  overflow: hidden;
  word-break: break-word;
  border: unset;
  transition: background-color 240ms ease-in-out, color 240ms ease-in-out,
    border 240ms ease-in-out;
  font-weight: 500;
  &.secondary {
    border: unset;
    color: rgba(16, 11, 40, 0.72);
    background-color: rgba(16, 11, 40, 0.05);
    font-weight: 600;
  }

  &:active {
    transform: scale(0.98);
  }

  &:disabled {
    background-color: #f2f2f4;
    border: unset;
    color: rgba(#100b28, 0.5);
    cursor: default;
    pointer-events: none;
  }
}

.date-picker-body {
  display: flex;
  flex: auto;
}

.picker {
  margin-bottom: 24px;

  .bp3-input-group.bp3-intent-danger .bp3-input:focus{
    box-shadow: unset;
  }

}
.picker-labels {
  display: flex;
  gap: 2px;
  flex: 1;
}
.picker-label {
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: #100b28;
  margin-bottom: 0.8rem;
  flex: 1;
  font-weight: 500;
}

.date-picker-select {
  width: 100%;
  margin-bottom: 1.6rem;
}
.date-picker-select-label {
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: #100b28;
  margin-bottom: 0.8rem;
  font-weight: 500;
}
.custom-datepicker {
  & .bp3-control-group {
    gap: 2px;
    .bp3-input-group {
      cursor: pointer;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  & .bp3-input-group input.bp3-input {
    height: 40px;
    width: 143px;
    padding-left: 14px;
    border: 0;
    font-weight: 500;
    border-bottom: 0.1rem solid #100b28;
    background-color: #f2f2f4;
    box-shadow: unset;
    z-index: 1;
    &:focus {
      border: 1px solid #009cbd;
    }
  }
}

.bp3-overlay .bp3-transition-container {
  z-index: 1200;

  .manager-picker {
    margin-top: -14px;
    .DayPicker-Day {
      width: 40px;
      height: 40px;
    }
    .bp3-popover-arrow {
      &::before {
        content: unset;
      }
      svg {
        display: none;
      }
    }
    .bp3-datepicker-year-select {
      flex: none;
      margin-right: auto;
      select {
        padding-right: 8px;
      }
      span {
        display: none;
      }
    }
    .bp3-datepicker-month-select {
      flex: none;
      margin-left: auto;
      select {
        padding-right: 8px;
      }
      span {
        display: none;
      }
    }
    .bp3-datepicker .DayPicker-Day.DayPicker-Day--selected {
      background-color: #009cbd;
    }
    .DayPicker-Day.DayPicker-Day--isToday.DayPicker-Day--today {
      .bp3-datepicker-day-wrapper {
        border: unset;
      }
      &:not(.DayPicker-Day--selected-range-start):not(.DayPicker-Day--selected-range-end) {
        color: #009cbd;
        .bp3-datepicker-day-wrapper {
          position: relative;
          &::after {
            content: " ";
            width: 4px;
            height: 4px;
            display: block;
            position: absolute;
            left: 18px;
            bottom: 2px;
            background-color: #009cbd;
          }
        }
      }
    }
    .custom-datepicker {
      padding: 4px 0;
    }
  }
}

.calendar-input-icon {
  right: 12px;
  position: absolute;
  top: 13px;
  z-index: 100;
}

.manager-filter-tooltip {
  color: #100b28;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
  .bp3-popover2-content {
    background: white !important;
  }
  svg .bp3-popover2-arrow-fill {
    fill: white;
  }
}

.manager-date-picker {
  @media screen and (max-width: 767px) {
    &.dialog-container {
      align-items: flex-start;
      padding-top: 59px;
    }
  }
}

.mobile-filter-datepicker {
  display: none;
  @media screen and (max-width: 767px) {
    display: flex;

    i {
      font-size: 12px;
    }
  }
  background: #ffffff;
  box-shadow: 0px 2px 8px #f0f0f0;
  border: 1px solid #efefef;
  height: 4rem;
  margin-bottom: 1.6rem;
  align-items: center;
  padding-left: 12px;

  .mobile-filter-datepicker-period {
    font-size: 1.3rem;
    line-height: 2rem;
    color: #100b28;
    margin-left: 8px;
  }
}
