.payment-options-container {
  display: flex;
  background-color: #ffffff;
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 2rem;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #5A5A5A #DEDEDE;
  left: 0;
  top: 0;
  z-index: 500;
  animation: popup-show 640ms ease-in-out;
  .loader {
    & + {
      .payment-content{
        opacity: 0.3;
      }
    }
  }
}

@keyframes popup-show {
  0% {
    opacity: 0;
    transform: translate(0, 100%);
  }
  10% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
  }
}

.payment-popup-close {
  position: absolute;
  right: 2.4rem;
  top: 3.2rem;
  width: 4.8rem;
  height: 4.8rem;
  outline: none;
  border: none;
  background-color: transparent;
  border-radius: 50%;
  font-size: 1.2rem;
  color: rgba(var(--background), 1);
  cursor: pointer;
  transition: background-color 240ms, color 240ms;

  &:active {
    transform: scale(0.98);
  }

  &:hover {
    background-color: rgba(var(--background), 1);
    color: #ffffff;
  }
}
.payment-content {
  max-width: 35.2rem;
  margin: 18.5rem auto auto;
  width: 100%;
}
.payment-content-inner{
  width: 100%;

  &.md {
    //margin-bottom: 3.4rem;
    padding-bottom: 3.4rem;
  }

  &.sm {
    //margin-bottom: 2.2rem;
    padding-bottom: 2.2rem;
  }
}
.payment-head{
  width: 100%;
  margin-bottom: 4.8rem;
}
.payment-title {
  font-size: 3.2rem;
  line-height: 4.1rem;
  word-break: break-word;
  text-transform: capitalize;
  font-weight: 600;
  text-align: center;
  color: rgba(var(--background), 1);
}

//.payment-form {
//  width: 100%;
//  display: inline-grid;
//  grid-template-columns: repeat(2, auto);
//  grid-gap: 2.5rem;
//  margin-bottom: 4.3rem;
//}

.payment-label {
  display: inline-flex;
  vertical-align: top;
  align-items: center;
  cursor: pointer;
  &.has-margin{
    margin-left: 3.6rem;
  }
}
.payment-label-text {
  flex: 1;
  font-size: 1.6rem;
  line-height: 2rem;
  text-transform: capitalize;
  color: rgba(var(--background), 1);
}

.radio-button {
  &:checked {
    & + label {
      .payment-label-text {
        font-weight: 600;
      }
    }
  }
}

.payment-inputs{
  width: 100%;
  overflow: hidden;
  max-height: 30rem;
  margin-top: 4.3rem;
  opacity: 1;
  transition: max-height 480ms ease-in-out, opacity 320ms ease-in-out;
}
.marketing-budget{
  &:checked{
    ~ .payment-inputs{
      max-height: 4.5rem;
      opacity: 0;
    }
  }
}

.payment-input-row{
  display: grid;
  align-items: end;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 3.2rem;
  width: 100%;
  &:not(:last-child){
    margin-bottom: 3.2rem;
  }
  &.error {
    .payment-input-r-title{
      color: rgba(var(--danger-color), 1);;
    }
    .payment-input{
      border-color: rgba(var(--danger-color), 1);;
    }
  }
}
.payment-input-row-column{
  width: 100%;
  position: relative;
  padding-top: 3.1rem;
  &:only-child{
    grid-column: span 2;
  }

  &.error {
    .payment-input-r-title {
      color: rgba(var(--danger-color), 1);
      font-size: 1.2rem;
      line-height: 1.4rem;
    }
    @media (max-width: 1919px) {
      padding-top: 2.8rem;
      .payment-input-r-title {
        font-size: 1.1rem;
        line-height: 1.3rem;
      }
    }
    @media (max-width: 1439px) {
      .payment-input-r-title {
        font-size: 1.2rem;
        line-height: 1.4rem;
      }
    }
    @media (max-width: 767px) {
      .payment-input-r-title {
        font-size: 1.1rem;
        line-height: 1.3rem;
      }
    }
    .payment-input{
      border-color: rgba(var(--danger-color), 1);;
    }
  }
}
.payment-input-r-title{
  display: block;
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: 0.19px;
  margin-bottom: 1.6rem;
  transition: color 240ms;
  text-transform: capitalize;
  word-break: break-word;
  color: rgba(var(--brand-color), 1);
  position: absolute;
  left: 0;
  top: 0;
}
.card-number-row{
  width: 100%;
  position: relative;
  .payment-input{
    padding-right: 4rem;
  }
}
.payment-img{
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
  width: 3.3rem;
  height: 2.4rem;
  padding: 0 0.6rem;
  background-color: #F2F2F2;
  border-radius: 0.4rem;
  > img {
    display: block;
    max-width: 100%;
  }
}
#cvv {
  -webkit-text-security:disc;
}
.payment-input{
  display: block;
  width: 100%;
  outline: none;
  background: transparent;
  border: none;
  border-bottom: 0.1rem solid rgba(#100B28, 0.26);
  padding: 0 0 0.8rem 0;
  color: rgba(var(--background), 1);
  transition: border 240ms;
  font-size: 1.6rem;
  box-sizing: border-box;
  border-radius: 0;
  line-height: 2rem;
  font-weight: 500;
  -webkit-appearance: none;
  appearance: none;
  &::placeholder{
    color: rgba(#100B28, 0.54);
  }
  &:focus{
    border-color: rgba(#100B28, 0.5);
  }
}

.payment-bottom{
  width: 100%;
  transform: translate3d(0, 0, 0);
}
.payment-bottom-inner{
  width: 100%;
  padding-bottom: 3.5rem;
}
.payment-b-row{
  display: flex;
  justify-content: space-between;
  font-size: 1.2rem;
  line-height: 1.6rem;
  width: 100%;
  text-transform: capitalize;
  letter-spacing: 0.22px;
  opacity: 1;
  transition: opacity 240ms;
  color: rgba(#100B28, 0.8);
  &:not(:last-child){
    margin-bottom: 0.4rem;
  }
  &.bold{
    margin-top: 1.2rem;
    font-size: 1.4rem;
    line-height: 1.8rem;
    color: rgba(var(--background), 1);
    li {
      &:first-child{
        font-weight: 500;
      }
      &:last-child{
        font-weight: bold;
      }
    }
  }

  li {
    &.font-medium {
      font-weight: 510;
    }
  }
}
.insufficient-founds{
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  height: 0;
  overflow: hidden;
  transition: height 340ms ease-in-out;
  > h3 {
    font-size: 1.7rem;
    line-height: 2.6rem;
    margin-bottom: 1rem;
    font-weight: 600;
  }
  > p{
    font-size: 1.6rem;
    line-height: 2.6rem;
    font-weight: 500;
    max-width: 90%;
    margin: 0 auto;
    text-align: center;
    transition: opacity 1s;
  }
}
.insufficient-open {
  .marketing-budget:checked {
    & ~ .payment-inputs {
      max-height: 2.4rem
    }
  }

  .insufficient-founds {
    height: fit-content;
    @media (min-width: 1919px) {
      padding-bottom: 2rem;
      padding-top: 2rem;
    }
    > h3 {
      opacity: 1;
    }

    > p {
      opacity: 1;
    }
  }

  .payment-bottom-inner {
    height: 0;
    padding-bottom: 2rem;

    .payment-b-row {
      opacity: 0;
      pointer-events: none;
    }
  }
}