.permission-issue {
  display: flex;
  justify-content: center;
  align-items: center;
  // position: fixed;
  width: 100%;
  height: 100%;
  // left: 0;
  // top: 0;
  background-color: #ffffff;
  color: rgba(var(--background), 1);
  z-index: 300;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #5A5A5A #DEDEDE;
  // padding: 20rem 2.4rem 2.4rem 2.4rem;
}

.permission-issue-content {
  margin: 0 auto;
  max-width: 57.4rem;
  width: 100%;
  text-align: center;
  word-break: break-word;
}

.permission-issue-head {
  width: 100%;
  padding-bottom: 6.5rem;
  margin-bottom: 6.5rem;
  border-bottom: 0.1rem solid rgba(#100B28, 0.1);
}

.permission-issue-img {
  display: inline-block;
  vertical-align: top;
  height: auto;
  max-width: 38rem;
  width: 100%;
}

.permission-issue-shadow {
  height: 3px;
  width: 180px;
  background-color: #a0a0a0;
  display: inline-block;
  margin: 4.4rem 0;
  filter: blur(6px);
  border-radius: 34%;
}

.permission-issue-title {
  font-size: 5.6rem;
  line-height: 7.1rem;
  margin-bottom: 1.6rem;
}
.permission-issue-text{
  font-size: 2rem;
  line-height: 2.9rem;
}

.permission-issue-b-text{
  font-size: 1.8rem;
  line-height: 2.6rem;
  > a {
   color: #009CBD;
   text-decoration: underline;
  }
}
.permission-issue-bottom {
  width: 100%;
}