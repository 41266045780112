.maintenance-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  background-color: #FCFCFC;
  z-index: 999;
  padding: 285px 30px 30px;
}
.maintenance-content {
  margin: 0 auto;
  max-width: 770px;
  width: 100%;
  text-align: center;
  color: #100B28;
}
.maintenance-img-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-bottom: 45px;
  &:after {
    content: "";
    display: block;
    height: 3px;
    width: 225px;
    background-color: #a0a0a0;
    margin-top: 40px;
    filter: blur(6px);
    border-radius: 36%;
  }
}
.maintenance-img {
  flex: 0 0 auto;
  display: block;
  max-width: 448px;
  height: auto;
}
.maintenance-title {
  font-size: 56px;
  line-height: 71px;
  font-weight: 600;
  margin-bottom: 16px;
  > span {
    color: #009CBD;
  }
}
.maintenance-text {
  font-size: 20px;
  line-height: 33px;
  color: #3E3A52;
  max-width: 572px;
  margin: 0 auto;
}
@media screen and (max-width: 1610px) {
  .maintenance-wrapper {
    padding-top: 245px;
  }

  .maintenance-img-holder {
    margin-bottom: 31px;
    &:after {
      max-width: 134px;
      margin-top: 32px;
    }
  }
  .maintenance-img {
    max-width: 273px;
  }

  .maintenance-title {
    font-size: 32px;
    line-height: 41px;
    margin-bottom: 11px;
  }

  .maintenance-text {
    font-size: 16px;
    line-height: 28px;
    max-width: 458px;
  }
}
@media screen and (max-width: 980px) {
  .maintenance-wrapper {
    padding-top: 276px;
  }
}
@media screen and (max-width: 738px) {
  .maintenance-wrapper {
    padding-top: 101px;
  }
  .maintenance-img-holder {
    margin-bottom: 36px;
    &:after {
      max-width: 134px;
      margin-top: 36px;
    }
  }
  .maintenance-title{
    font-size: 29px;
    line-height: 35.5px;
    margin-bottom: 15px;
  }
}