.preview-your-ad {
  width: 100%;
}

.preview-your-ad-content {
  display: grid;
  grid-template-columns: auto 11fr;
  grid-gap: 7.2rem;
  width: 100%;
  position: relative;

  .step-name-container {
    display: none;
  }
}

.preview-your-ad-content-column {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 100%;

  &.space {
    justify-content: space-between;
  }
}

.preview-your-ad-swiper-row {
  width: 38rem;
  height: 57.2rem;
  position: relative;
  white-space: nowrap;
}

.preview-your-ad-swipe-block {
  height: 100%;
  width: 100%;
}

.preview-your-ad-swipe-block-inner {
  display: inline-flex;
  align-items: flex-end;
  //
  vertical-align: top;
  height: 100%;
}

.preview-your-ad-popup {
  .preview-your-ad-swipe-block-inner {
    .preview-your-ad-card-inner {
      padding: 0 !important;
    }
  }
}
.preview-your-ad-holder {
  display: inline-flex;
  justify-content: center;
  vertical-align: top;
  white-space: initial;
  height: 100%;
}

.your-ad-block {
  height: 100%;
  animation: preview-card-show 340ms ease-in-out;
}
@keyframes preview-card-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.p-y-ad-swipe-icon {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto 0;
  text-align: center;
  font-size: 1.2rem;
  color: rgba(var(--background), 1);
  width: 2.4rem;
  height: 2.4rem;
  line-height: 2.4rem;
  z-index: 20;
  cursor: pointer;

  &.left {
    left: -2rem;
  }

  &.right {
    right: -2rem;
  }

  &.hidden {
    display: none;
  }
}

.preview-your-ad-content-column-row-cnt {
  width: 100%;

  .review-copy-radio-buttons-row {
    width: 100%;
  }

  .review-copy-input-r-title {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 2rem;
  }

  .review-copy-radio-text {
    font-size: 1.6rem;
    line-height: 2rem;
    color: rgba(var(--background), 1);
  }
}

.preview-your-ad-content-column-row {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  width: 100%;
}

.preview-your-ad-block {
  width: 37rem;
  background-color: #ffffff;
  box-shadow: 0 1rem 2.5rem #f0f0f0;
  margin: auto;
  font-family: "Arial";
  cursor: pointer;

  &.single-image {
    width: 32.8rem;

    .preview-your-ad-card {
      margin: 0;
    }
    .preview-your-ad-sponsored-text {
      font-size: 1.2rem;
      line-height: 1.4rem;
      > i {
        font-size: 1.1rem;
      }
    }
    .preview-your-ad-logo-block {
      width: 4.1rem;
      height: 4.1rem;
      margin-right: 0.8rem;
    }
    .preview-your-ad-block-bottom-column {
      transform: translate(-4px, -2px);
    }
    .preview-your-ad-slider-row {
      padding-bottom: 1.4rem;
      border: 0;
    }

    .preview-your-ad-slider-row-inner {
      display: block;
      width: 100%;
    }

    .preview-your-ad-block-head {
      padding: 1rem 1rem 1.3rem 1rem;
      align-items: flex-start;
    }

    .preview-your-ad-card-head {
      height: 10.4rem;
      padding: 1.2rem 1.7rem 4.5rem 1.7rem;
    }
    .preview-your-ad-menu {
      transform: translate(0, 0.5rem);
      font-size: 1.7rem;
    }
    .preview-your-ad-user-name {
      font-size: 1.3rem;
      line-height: 1.8rem;
      letter-spacing: 0.2px;
    }

    .preview-your-ad-card-user {
      font-size: 1rem;
      line-height: 2.1rem;
      margin-bottom: 0.4rem;
    }

    .preview-your-ad-block-info {
      font-size: 1.4rem;
      margin-bottom: 1rem;
      line-height: 1.7rem;
      letter-spacing: 0.07px;
      height: 3.4rem;
    }

    .preview-your-ad-card-user-info {
      font-size: 1.2rem;
    }

    .preview-your-ad-card-head-logo {
      width: 4.8rem;
      height: 4.8rem;
    }

    .preview-your-ad-card {
      width: 100%;
      height: 41.1rem;
      border: 0;
      border-radius: 0;

      &.has-header {
        .preview-your-ad-card-content {
          border-left: 1.7rem solid transparent;
          border-right: 1.7rem solid transparent;
        }
        .preview-your-ad-card-img {
          height: calc(100% + 1.9rem);
          transform: translate(0, -3.3rem);
          transform-origin: bottom;
        }
      }
      .preview-your-ad-card-bottom {
        padding: 0 1.3rem;
      }
    }

    .preview-your-ad-block-bottom-inner {
      padding: 0.9rem 0 1rem 0;
    }

    .preview-your-ad-card-bottom-column {
      display: flex;
      flex-direction: column-reverse;
      transform: translate(0, -5px);

      > p {
        font-size: 1.5rem;
        line-height: 1.9rem;
      }

      > span {
        font-size: 1.2rem;
        line-height: 1.6rem;
      }
    }

    .preview-your-ad-card-button {
      height: 3.6rem;
      font-size: 1.4rem;
    }
  }
}

.your-ad-block {
  &.hidden {
    display: none;
  }
}

.preview-your-ad-block-head {
  display: flex;
  align-items: center;
  padding: 1.2rem 2rem 1.6rem 1.4rem;
}

.preview-your-ad-block-head-column {
  flex: 1;
  display: flex;
  align-items: center;
}

.preview-your-ad-logo-block {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.5rem;
  height: 4.5rem;
  margin-right: 1rem;
  border-radius: 50%;
  align-self: baseline;
}

.preview-your-ad-logo {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;

  &.hidden {
    visibility: hidden;
    opacity: 0;
  }
}

.preview-your-ad-user-info {
  flex: 1;
}

.preview-your-ad-user-name {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: bold;
  text-transform: capitalize;
  color: rgba(var(--background), 1);
  white-space: pre-line;
}

.preview-your-ad-sponsored-text {
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #616770;
  > i {
    font-size: 1.2rem;
    display: inline-block;
    // vertical-align: top;
    padding-left: 1rem;
    position: relative;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: -0.2rem;
      top: 0.5rem;
      vertical-align: top;
      width: 0.2rem;
      height: 0.2rem;
      border-radius: 50%;
      margin-left: 0.6rem;
      background-color: rgba(#100b28, 0.7);
    }
  }
}

.preview-your-ad-menu {
  flex: 0 0 auto;
  font-size: 1.8rem;
  color: rgba(#100b28, 0.7);
}

.preview-your-ad-block-info {
  font-size: 1.7rem;
  line-height: 2rem;
  height: 4rem;
  padding: 0 1.3rem;
  margin-bottom: 1.7rem;
  color: #1d2129;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.preview-your-ad-slider-container {
  position: relative;
  width: 100%;
}
.preview-your-ad-slider-row {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  padding-bottom: 3.6rem;
  border-left: 1.3rem solid transparent;
  border-right: 1.3rem solid transparent;
  position: relative;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.preview-your-ad-slider-row-inner {
  display: inline-block;
  white-space: nowrap;
  position: relative;
}

.preview-your-ad-card {
  display: inline-grid;
  vertical-align: top;
  grid-template-rows: 11fr auto;
  width: 26.8rem;
  height: 35.7rem;
  margin-right: 1.4rem;
  position: relative;
  white-space: initial;
  //box-shadow: 0 0.2rem 0.4rem rgba(#000000, 0.14);
  border: 0.1rem solid #dddfe2;
  border-radius: 0.6rem;
  overflow: hidden;
  &.preview-portal {
    position: fixed;
    top: -4500px;
  }

  &.pdf-preview {
    display: block;
    width: 29.1rem;
    height: 29.1rem;
    border: none;
    border-radius: initial;
    margin: 0;
    .preview-your-ad-card-inner {
      height: 100%;
    }
    // todo
    .preview-your-ad-card-head {
      padding: 1.3rem 1.2rem 3rem 1.2rem;
      height: 8.7rem;
    }
    .preview-your-ad-card-head-logo {
      width: 4.2rem;
      height: 4.2rem;
    }
    .preview-your-ad-card-user {
      font-size: 1.5rem;
      line-height: 1.7rem;
      margin: 0;
    }
    .preview-your-ad-card-user-info {
      font-size: 1rem;
      line-height: 1.4rem;
    }
    // todo
  }
  &.full {
    .preview-your-ad-card-inner {
      //background-color: rgba(var(--background), 1);
      background-color: #100b28;
      padding: 2.7rem 0;
    }
  }
  &.has-header {
    .preview-your-ad-card-head {
      display: flex;
    }
    .preview-your-ad-card-img {
      height: calc(100% + 1.6rem);
      transform: translate(0, -2.6rem);
      transform-origin: bottom;
    }
    .preview-your-ad-card-content {
      border-left: 1.2rem solid transparent;
      border-right: 1.2rem solid transparent;
    }
  }
  &.popup-image {
    .preview-your-ad-card-content {
      border: 0 !important;
    }
    .preview-your-ad-card-img {
      height: 100% !important;
      transform: initial !important;
    }
  }
}

.preview-your-ad-card-inner {
  display: grid;
  grid-template-rows: auto 11fr;
  grid-template-areas: "head" "content";
  width: 100%;
  position: relative;
  background-color: #100b28;
  // TO DO need delete in refactor time, now need maybe its has negative affect in another styles
  // &:before{
  //   content: "";
  //   display: block;
  //   position: absolute;
  //   left: 0;
  //   bottom: 0;
  //   width: 100%;
  //   height: 3.5rem;
  //  background-color: #100B28;
  // }
  // box-shadow: 0 -1rem 0 0 rgba(var(--background), 1) inset;
}

.preview-your-ad-card-head {
  grid-area: head;
  display: none;
  align-items: center;
  width: 100%;
  padding: 1rem 1.2rem 3.6rem 1.2rem;
  height: 8.7rem;
  //background-color: rgba(var(--background), 1);
  background-color: #100b28;
  min-width: 1rem;
}

.preview-your-ad-card-head-column {
  flex: 1;
  min-width: 1rem;
}

.preview-your-ad-card-head-logo {
  flex: 0 0 auto;
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 50%;
  margin-left: 2rem;

  &.brightLogo {
    margin-left: 0;
    border-radius: 0;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;
    .preview-your-ad-logo {
      border-radius: 0;
      //filter: contrast(0.2) brightness(2) grayscale(100%);
      width: auto;
      max-width: 100%;
      object-fit: contain;
    }
  }
}

.preview-your-ad-card-user {
  font-size: 1.5rem;
  line-height: 1.7rem;
  font-weight: bold;
  color: #ffffff;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.preview-your-ad-card-user-info {
  font-size: 1rem;
  line-height: 1.4rem;
  color: rgba(#ffffff, 0.8);
  letter-spacing: 0.2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.preview-your-ad-card-content {
  grid-area: content;
  z-index: 10;
  position: relative;
}

.preview-your-ad-card-img {
  display: block;
  width: 100%;
  height: 100%;
  min-height: 17rem;
  object-fit: cover;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
}

.preview-your-ad-card-bottom {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 0.9rem 1.3rem 0.9rem;
  height: 8.5rem;
  min-width: 1rem;
  background-color: #f1f2f5;
  z-index: 10;
}

.preview-your-ad-card-bottom-column {
  flex: 1;
  min-width: 1rem;
  padding-right: 0.2rem;

  > p {
    //color: rgba(var(--background), 1);
    //padding-right: 0.3rem;
    color: #1d2129;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: bold;
    // text-transform: capitalize;
    overflow: hidden;
    max-height: 4rem;
    word-break: break-word;
    // text-overflow: ellipsis;
    //display: -webkit-box;
    // -webkit-line-clamp: 2;
    // -webkit-box-orient: vertical;
  }

  > span {
    display: block;
    text-decoration: none;
    font-size: 1.7rem;
    line-height: 2rem;
    text-transform: uppercase;
    color: #616770;
    letter-spacing: -0.34px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.preview-your-ad-card-button {
  flex: 0 0 auto;
  height: 3.9rem;
  outline: none;
  min-width: 10rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 0.6rem;
  padding: 0 0.6rem;
  font-size: 1.5rem;
  // text-transform: capitalize;
  font-family: Arial;
  cursor: pointer;
  margin-left: 0.6rem;
  font-weight: bold;
  letter-spacing: -0.23px;
  background-color: #e4e6ea;
  border: none;
  color: #1d2129;
}

.slider-arrow {
  position: absolute;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 50%;
  top: 0;
  bottom: 0;
  margin: auto 0;
  background-color: #ffffff;
  border: none;
  font-size: 1.2rem;
  color: rgba(var(--background), 1);
  z-index: 20;
  box-shadow: 0 0 2rem 0 rgba(#000000, 0.5);

  &:active {
    transform: scale(0.98);
  }
  &.left {
    left: 1rem;
  }
  &.right {
    right: 1rem;
  }
}

.preview-your-ad-block-bottom {
  width: 100%;
  padding: 0 1rem;
}

.preview-your-ad-block-bottom-inner {
  display: flex;
  align-items: center;
  padding: 1.6rem 0;
  border-top: 0.1rem solid rgba(#100b28, 0.1);
}

.preview-your-ad-block-bottom-column {
  flex: 1;
  display: flex;
  align-items: center;
  color: rgba(#100b28, 0.6);
  justify-content: center;
  font-weight: bold;
  transform: translate(-8px, -1px);
  > i {
    display: block;
    font-size: 1.9rem;
    margin-right: 0.7rem;
    font-weight: bold;
  }

  > span {
    display: block;
    font-size: 1.3rem;
    text-transform: capitalize;
  }
}

.preview-your-ad-info-text {
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 3.1rem;
  color: rgba(var(--background), 1);
  max-width: 54.7rem;
  // for pixel perfect
  max-height: 9.2rem;
  // for pixel perfect
  width: 100%;

  span {
    color: rgba(var(--brand-color), 1);
    cursor: pointer;
    margin-left: 0.4rem;
    white-space: nowrap;
    border-bottom: 0.1rem solid rgba(var(--brand-color), 1);
    &:nth-child(2) {
      margin: 0;
    }
  }
}

.preview-your-ad-info-text-hidden {
  width: 100%;
  margin-top: 1rem;
}

.preview-your-ad-content-column-button {
  display: flex;
  gap: 2rem;
  width: 35.2rem;
  font-weight: 500;
}

// preview your ad popup START
.preview-your-ad-popup {
  display: flex;
  background-color: #ffffff;
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 2rem;
  overflow-x: hidden;
  overflow-y: auto;
  left: 0;
  top: 0;
  z-index: 500;
  animation: popup-show 640ms ease-in-out;
  align-items: center;
  justify-content: center;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
}

.preview-your-ad-popup-card {
  //height: 61.2rem;
  max-width: 44rem;
  width: 100%;
  text-align: center;
  .preview-your-ad-content-column {
    display: block;
    width: 100%;
    height: 100%;
    max-width: 44rem;
    margin: 0 auto;
    position: relative;
  }
  .preview-your-ad-holder {
    width: 38rem;
    height: auto;
    text-align: initial;
    &:not(:last-child) {
      margin-right: 4rem;
    }
  }
  .preview-your-ad-block {
    &.single-image {
      .preview-your-ad-card {
        margin: 0 !important;
      }
    }
  }

  .p-y-ad-swipe-icon {
    &.left {
      left: -0.8rem;
    }

    &.right {
      right: -0.8rem;
    }
  }

  .instagram-your-ad-block {
    width: 100%;
  }

  .preview-your-ad-swiper-row {
    text-align: center;
    height: 100%;
    width: 100%;
    max-width: 42rem;
    padding: 1rem 2rem 3rem 2rem;
    overflow: hidden;
  }

  .preview-your-ad-swipe-block {
    max-width: 38rem;
    margin: 0 auto;
  }

  .preview-your-ad-swipe-block-inner {
    text-align: initial;
  }

  .step-name {
    color: rgba(var(--background), 1);
    font-size: 1.4rem;
    font-weight: normal;
    text-align: center;
  }

  .p-y-ad-swipe-icon {
    display: block;
    cursor: pointer;

    &.hidden {
      display: none;
    }
  }
  .story-insta-your-ad-block {
    .preview-your-ad-card {
      &.popup-image {
        height: 31.8rem;
      }
    }
  }
}

.your-ad-details {
  .story-insta-your-ad-head,
  .story-insta-your-ad-bottom {
    background-image: url("../images/primary-bg.png");
    background-position: center;
    background-repeat: repeat;
    background-size: cover;
    background-color: transparent;
  }
}

.preview-your-ad-popup-close {
  position: fixed;
  right: 3.2rem;
  top: 3.2rem;
  width: 4.8rem;
  height: 4.8rem;
  outline: none;
  border: none;
  border-radius: 50%;
  font-size: 1.2rem;
  color: #ffffff;
  background-color: rgba(var(--background), 1);
  cursor: pointer;
  transition: background-color 240ms, color 240ms;
  z-index: 10;

  &:active {
    transform: scale(0.98);
  }

  &:hover {
    background-color: rgba(var(--background), 1);
    color: #ffffff;
  }
}

// preview your ad popup ENd

// INSTAGRAM Preview your ad START
.instagram-your-ad-block {
  width: 38rem;
  margin: auto;
  box-shadow: 0 1rem 2.5rem #e6e6e6;
  border-radius: 1.1rem;
  font-family: Arial;
  cursor: pointer;

  &.hidden {
    display: none;
  }
  .preview-your-ad-card {
    margin: 0 !important;
    width: 100%;
    border-radius: 0;
    height: 38rem;
    border: none;

    &.has-header {
      .preview-your-ad-card-content {
        border-left: 1.6rem solid transparent;
        border-right: 1.6rem solid transparent;
      }
    }
    .preview-your-ad-card-img {
      height: calc(100% + 2rem);
      transform: translate(0, -3.6rem);
    }
  }
  .preview-your-ad-card-head {
    height: 12.1rem;
    padding: 1.4rem 1.6rem 5rem 1.6rem;
  }

  .preview-your-ad-card-head-logo {
    width: 5.7rem;
    height: 5.7rem;
  }

  .preview-your-ad-card-user {
    font-size: 1.3rem;
    line-height: 2.3rem;
    margin-bottom: 0.8rem;
  }
  .preview-your-ad-card-user-info {
    font-size: 1.3rem;
  }
}

.preview-your-ad__google-footer-container .footer {
  padding: 0;
}

.instagram-your-ad-head {
  display: flex;
  align-items: center;
  min-height: 4.8rem;
  padding: 1rem 1.2rem;
  width: 100%;
}

.instagram-your-ad-head-column {
  flex: 1;
  display: flex;
  align-items: center;
  min-width: 1rem;
}

.instagram-your-ad-logo {
  flex: 0 0 auto;
  display: block;
  margin-right: 0.9rem;
  width: auto;
  object-fit: cover;
  border-radius: 50%;
  height: 3.3rem;
}

.instagram-your-ad-head-texts {
  flex: 1;
  word-break: break-word;

  > p {
    font-size: 1.3rem;
    line-height: 1.7rem;
    letter-spacing: -0.33px;
    color: #262627;
    text-transform: lowercase;
    font-weight: bold;
  }

  > span {
    display: block;
    font-size: 1.1rem;
    line-height: 1.5rem;
    text-transform: capitalize;
    color: rgba(#262627, 0.7);
  }
}

.instagram-your-ad-menu {
  flex: 0 0 auto;
  font-size: 1.1rem;
}

.instagram-shop-now {
  width: 100%;
  padding: 0 1.2rem;
  margin-bottom: 1rem;
}

.instagram-shop-now-inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 0;
  width: 100%;
  border-bottom: 0.1rem solid #dbdbdb;

  > span {
    display: block;
    font-size: 1.4rem;
    line-height: 1.8rem;
    font-weight: bold;
    color: #262627;
    word-break: break-word;

    &:first-letter {
      text-transform: uppercase;
    }
  }

  > i {
    font-size: 1.4rem;
    color: #989898;
  }
}

.instagram-your-ad-content {
  width: 100%;
}

.instagram-your-ad-bottom {
  width: 100%;
  padding: 0 1.2rem 1.6rem 1.2rem;
}

.instagram-your-ad-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1.2rem;
}

.instagram-your-ad-icons-column {
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-gap: 1.8rem;
}

.instagram-your-ad-icon {
  flex: 0 0 auto;
  font-size: 2.4rem;
  color: #262627;
}

.instagram-info-row {
  display: flex;
  font-size: 1.4rem;
  line-height: 1.9rem;
  letter-spacing: 0.07px;
  color: #262627;
  max-height: 3.8rem;
}

.instagram-info-name {
  font-weight: bold;
  text-transform: lowercase;
}

.instagram-info-text {
  display: -webkit-box;
  // -webkit-line-clamp: 2;
  overflow: hidden;
  // text-overflow: ellipsis;
  -webkit-box-orient: vertical;
}

.instagram-info-name-h {
  position: relative;
  //&:after{
  //  content: "more";
  //  color: #ABABAC;
  //  margin-left: 0.4rem;
  //}
}

// INSTAGRAM Preview your ad END

// STORY instagram START
.story-insta-your-ad-block {
  width: 32.2rem;
  border-radius: 1rem;
  overflow: hidden;
  font-family: Arial;
  box-shadow: 0 1rem 2.5rem #e6e6e6;
  cursor: pointer;

  &.hidden {
    display: none;
  }

  .preview-your-ad-card {
    width: 100% !important;
    height: 34rem;
    margin: 0 !important;
    border-radius: 0;
    border: 0 !important;

    &.has-header {
      .preview-your-ad-card-content {
        grid-area: unset;
        border-left: 1.4rem solid transparent;
        border-right: 1.4rem solid transparent;
      }
      .preview-your-ad-card-img {
        height: calc(100% + 3rem);
        transform: translate(0, -3.1rem);
      }
    }
  }

  .preview-your-ad-card-head {
    align-items: center;
    height: 12rem;
    padding-bottom: 3.5rem;
  }
  .preview-your-ad-card-user {
    font-size: 0.9rem;
    line-height: 2.1rem;
    letter-spacing: 0.68px;
    margin-bottom: 0.5rem;
  }

  .preview-your-ad-card-user-info {
    font-size: 1.2rem;
    line-height: 1.6rem;
    color: rgba(#ffffff, 0.8);
    letter-spacing: 0.18px;
  }

  .preview-your-ad-card-head-logo {
    width: 4.9rem;
    height: 4.9rem;
  }
}

.story-insta-your-ad-head {
  width: 100%;
  height: 6.4rem;
  //todo nkar hanelu jamanak bg popoxakanov drac chi haskanum.
  //background-color: rgba(var(--background), 1);
  //todo
  background-color: #100b28;
  padding: 0.7rem 0.5rem 0 0.7rem;
}

.story-insta-time-row {
  display: block;
  width: 100%;
  position: relative;
  height: 0.2rem;
  border-radius: 0.2rem;
  overflow: hidden;
  background-color: #727179;
  margin-bottom: 0.8rem;

  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 30%;
    height: 100%;
    left: 0;
    top: 0;
    background-color: #d2d3d5;
    border-radius: 0.2rem;
  }
}

.story-insta-your-ad-head-inner {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 0.9rem;
}

.story-insta-your-ad-head-column {
  flex: 1;
  display: flex;
  align-items: center;
}

.story-insta-your-ad-icons-column {
  flex: 0 0 auto;
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 2rem;

  > i {
    font-size: 1.3rem;
    color: #d0d0d4;
  }
}

.story-insta-ad-logo {
  display: block;
  width: 2.8rem;
  height: 2.8rem;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 0.8rem;
  background-color: #ffffff;
}

.story-insta-ad-head-texts {
  flex: 1;

  > p {
    font-size: 1.2rem;
    text-transform: lowercase;
    color: #ffffff;
    letter-spacing: -0.18px;
  }

  > span {
    display: block;
    font-size: 1rem;
    color: rgba(#ffffff, 0.8);

    > i {
      font-size: 0.6rem;
    }
  }
}

.story-insta-your-ad-content {
  margin-top: -1px;
  width: 100%;
}

.story-insta-your-ad-bottom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 16.8rem;
  width: 100%;
  padding: 1.5rem 2.7rem 1.1rem 2.7rem;
  //background-color: rgba(var(--background), 1);
  background-color: #100b28;
  margin-top: -1px;
}
.story-insta-your-ad-bottom-text-holder {
  width: 100%;
}
.story-insta-your-ad-bottom-text-h {
  display: inline-block;
  vertical-align: top;
}
.story-insta-your-ad-bottom-text {
  font-size: 1.3rem;
  line-height: 1.9rem;

  letter-spacing: 0.39px;
  font-weight: bold;
  padding: 0.4rem 0.8rem;
  color: #ffffff;
  background-color: #000000;
  border-radius: 0.6rem;
  overflow: hidden;
  // text-overflow: ellipsis;
  display: -webkit-box;
  // -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  & > span {
    max-height: 3.8rem;
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
  }
}

.story-insta-your-ad-button-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  > i {
    font-size: 1.4rem;
    color: #ffffff;
    padding-bottom: 0.4rem;
  }

  > button {
    outline: none;
    border: none;
    height: 3.8rem;
    border-radius: 4.2rem;
    min-width: 8.2rem;
    background-color: #ffffff;
    padding: 0 0.4rem;
    font-size: 1.2rem;
    line-height: 1.6rem;
    letter-spacing: -0.18px;
    font-weight: bold;
    color: #040404;

    &:first-letter {
      text-transform: uppercase;
    }
  }
}

// STORY instagram END

.preview-social-tabs-title {
  display: block;
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 0.8rem;
  text-transform: capitalize;
  color: rgba(var(--brand-color), 1);
  word-break: break-word;
  letter-spacing: 0.21px;
  transition: color 240ms;
  font-weight: 500;
}

.preview-social-tabs-container {
  margin-bottom: 4.8rem;
  margin-top: 5.6rem;
}

.preview-social-tabs {
  width: 100%;
  .analytics-page-tab {
    font-size: 1.6rem;
    line-height: 2rem;
    color: rgba(#100b28, 0.74);
    transition: color 120ms;
  }

  .analytics-page-tab {
    font-weight: 500;

    &.active {
      color: rgba(#100b28, 1);
      cursor: default;
    }
  }
}
