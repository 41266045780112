.social-popup{
  position: fixed;
  display: grid;
  grid-template-rows: auto 11fr auto;
  grid-row-gap: 3.5rem;
  width: 100%;
  height: 100%;
  max-width: 46.4rem;
  background-color: #ffffff;
  box-shadow: 0 1rem 2.5rem rgba(#2B2D2F, 0.2);
  right: 0;
  top: 0;
  z-index: 1000;
  transform: translate3d(110%, 0, 0);
  transition: transform 240ms ease-out;
  &.open{
    transform: translate3d(0, 0, 0);
  }
}
.social-popup-head{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 1.4rem;
}
.social-popup-bottom{
  width: 100%;
  display: none;
  padding: 2.4rem;
}
.social-popup-close{
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  cursor: pointer;
  background-color: rgba(var(--background), 1);
  font-size: 1.2rem;
  color: #ffffff;
  &:active{
    transform: scale(0.98);
  }
}
.social-popup-content{
  width: 100%;
  padding: 0 8.8rem 1.4rem 8.8rem;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: #5A5A5A #DEDEDE;
  scrollbar-width: thin;
}

.social-popup-c-images-row{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin-bottom: 2.8rem;
}
.social-popup-c-images-row-inner{
  display: flex;
  align-items: center;
  margin-bottom: 1.7rem;
}
.social-popup-c-i-column{
  flex: 0 0 auto;
}
.social-popup-plus{
  display: block;
  font-size: 1.6rem;
  color: rgba(#100B28, 0.5);
  padding: 0 1rem;
}
.social-popup-img{
  display: block;
  max-width: 5.6rem;
}
.social-popup-media-name{
  font-size: 3rem;
  line-height: 3.4rem;
  color: rgba(var(--background), 1);
  text-align: center;
  text-transform: capitalize;
  font-weight: 600;
}

.social-popup-info {
  font-size: 1.6rem;
  line-height: 3rem;
  color: rgba(var(--background), 1);
  &.for-google{
    font-weight: 400;
  }
}