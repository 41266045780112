.steps-wrapper {
  display: grid;
  grid-template-rows: auto 11fr auto;
  width: 100%;
  min-height: 100%;
}

.steps-content {
  width: 100%;
  overflow: hidden;

  &.google {
    overflow: initial;

    & > div[class^="preview-your-ad-content-column"]:not([class*=" space"]) {
      justify-content: flex-start;
    }

    .preview-your-ad-content-column.space {
      height: calc(100% + 48px);
    }
  }
}

.steps-bottom {
  width: 100%;
  &.active {
    .next-step-row-inner {
      overflow: hidden;
    }
    .next-step-left-column {
      transform: translateY(1.8rem);
    }
  }
}

.step-cnt {
  padding: 6rem 4.8rem 3.2rem 4.8rem;
  animation: steps 480ms ease-in-out;
}
@keyframes steps {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.step-info-row {
  padding: 2.6rem 2.8rem 2.6rem 4.2rem;
  background-color: rgba(var(--background), 1);
  position: relative;
  min-width: 1rem;
  &.promote-listings {
    padding: 2.6rem 2.8rem 2.6rem 2.8rem;
    @media screen and (max-width: 767px) {
      padding: 1.6rem 0.4rem 1.6rem 1.6rem;
    }
  }
}

.step-info-row-content {
  width: 100%;
}
.step-info-row-content-inner {
  display: grid;
  grid-template-columns: 11fr auto;
  align-items: end;
  width: 100%;
}
.step-info-row-text-column {
  flex: 1;
  min-width: 1rem;
}

.step-info-row-arrows-column {
  flex: 0 0 auto;
  width: auto;
}

.step-info-title {
  letter-spacing: 1.92px;
  font-size: 1.6rem;
  line-height: 2rem;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 0.6rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
  color: rgba(var(--brand-color), 1);
}
.step-info-title-hide {
  margin-left: 4px;
}

.step-name-container {
  width: 100%;
  position: relative;
  height: 6.2rem;
  overflow: hidden;
  &.step-2 {
    .step-name {
      &:nth-child(-n + 2) {
        transform: translate(0, -100%);
      }
    }
  }

  &.step-3 {
    .step-name {
      &:nth-child(-n + 3) {
        transform: translate(0, -200%);
      }
    }
  }

  &.step-4 {
    .step-name {
      &:nth-child(-n + 4) {
        transform: translate(0, -300%);
      }
    }
  }

  &.step-5 {
    .step-name {
      &:nth-child(-n + 5) {
        transform: translate(0, -400%);
      }
    }
  }

  &.step-6 {
    .step-name {
      &:nth-child(-n + 6) {
        transform: translate(0, -500%);
      }
    }
  }
  &.confirmation {
    height: auto;
    .step-name {
      white-space: unset;
      overflow: unset;
      word-break: break-word;
    }
  }
}

.step-name {
  font-size: 5rem;
  line-height: 6rem;
  color: #ffffff;
  text-transform: capitalize;
  font-weight: 600;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: transform 240ms ease-in-out;
}
.steps-progress-row {
  height: 0.4rem;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: rgba(#ffffff, 0.75);
}

.steps-progress {
  height: 100%;
  background-color: rgba(var(--brand-color), 1);
  transition: width 240ms ease-in-out;
}

.steps-arrows {
  display: flex;
  align-items: center;
  animation: step-arrows-show 240ms linear;
}

.step-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.4rem;
  height: 4.4rem;
  transition: background-color 240ms, color 240ms;
  color: rgba(#ffffff, 0.5);
  cursor: pointer;
  font-size: 1.8rem;
  margin: 0 0.4rem;
  border: 0.1rem solid rgba(#ffffff, 0.3);
  border-radius: 0.2rem;
  &:hover {
    background-color: rgba(#ffffff, 0.5);
    color: #ffffff;
  }
  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}

@keyframes step-arrows-show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.next-step-row {
  width: 100%;
  padding: 0 4.8rem;
}

.next-step-row-inner {
  display: flex;
  align-items: flex-end;
  width: 100%;
}

.next-step-left-column {
  flex: 1;
  display: flex;
  transition: transform 200ms;
}

.next-step-button-block {
  display: flex;
  gap: 2rem;
  flex: 0 0 auto;
  min-width: 32rem;
}

.back-button {
  background-color: #fcfcfc;
  border-color: rgba(#100b28, 0.26);
  color: rgba(var(--background), 1);
  max-width: 14rem;
  &:hover {
    border-color: #100b28;
  }
}

.steps-count-column {
  flex: 0 0 auto;
  display: flex;
  justify-content: flex-end;
  padding-left: 2rem;
}

.steps-count-column-inner {
  display: block;
}

.steps-count-title {
  font-size: 2rem;
  line-height: 2.5rem;
  color: rgba(var(--background), 1);
  min-height: 2.5rem;
  text-transform: capitalize;
  font-weight: 500;
}

.steps-count-items {
  display: flex;
  margin: 1.6rem 0;
}

.steps-count-item {
  flex: 0 0 auto;
  height: 0.5rem;
  width: 4.8rem;
  background-color: #e3e3e6;
  position: relative;
  pointer-events: none;
  overflow: hidden;

  &:before {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    width: 0;
    left: 0;
    top: 0;
    background-color: rgba(var(--brand-color), 1);
    transition: width 240ms ease-in-out;
  }

  &:not(:last-child) {
    margin-right: 0.4rem;
  }

  &.active {
    &:before {
      width: 100%;
      cursor: pointer;
      pointer-events: all;
    }
  }
}

.steps-count-bottom {
  font-size: 1.2rem;
  line-height: 1.4rem;
  color: rgba(#100b28, 0.72);
  font-weight: 400;
}

.bp3-datepicker .DayPicker-Month {
  &:focus {
    outline: unset;
  }
}
