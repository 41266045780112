.choose-listing-images-view-step {
  width: 100%;
  height: 100%;
  &.step-cnt {
    padding: 0;
  }
  .steps-bottom {
    padding: 3.2rem 0 2.6rem 0;
    box-shadow: 0px -0.8rem 3.5rem rgba(#000000, 0.08);
    background-color: #fcfcfc;
    min-height: 14.9rem;
  }
  .scrollable-container {
    padding: 0 4.8rem;
  }
}
.choose-listing-content {
  display: grid;
  grid-template-rows: 11fr auto;
  width: 100%;
  height: 100%;
  &-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: 4rem 0 3.2rem 0;
  }

  &-tabs-column {
    display: inline-grid;
    vertical-align: top;
    grid-column-gap: 4rem;
    grid-auto-flow: column;
    overflow: hidden;
    position: relative;

    &-tab {
      font-size: 1.9rem;
      line-height: 2.4rem;
      white-space: nowrap;
      padding: 0.8rem 0;
      cursor: pointer;
      position: relative;

      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 0;
        background-color: rgba(var(--brand-color), 1);
      }

      &.active {
        font-weight: 600;

        &:before {
          height: 0.4rem;
        }
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
      }

      &.hidden {
        display: none;
      }
    }
  }
}

.choose-listing-cnt {
  max-width: 144rem;
  width: 100%;
  margin: 0 auto;
}

.choose-listing-selected-images {
  flex: 0 0 auto;
}

.choose-listing-selected-images-row {
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;
}

.choose-listing-s-i-column-left {
  display: flex;
  align-items: center;
  margin-right: 3.2rem;
}

.choose-listing-head-column-left-content {
  display: flex;
  align-items: center;
  width: 100%;
}

.choose-listing-head-images {
  flex: 0 0 auto;
  display: flex;
  flex-wrap: wrap;
  margin-right: 3.2rem;
  overflow: hidden;
}

.choose-listing-head-images-column {
  flex: 0 0 auto;
  width: 8rem;
  height: 5.6rem;
  cursor: pointer;
  position: relative;
  transition: transform 240ms;
  //&:not(.empty){
  //  z-index: 10;
  //}
  &:not(:last-child) {
    margin-right: 1.2rem;
  }
  &.empty {
    display: none;
    //transform: translate(calc(-100% - 1.2rem), 0);
    //&:last-child{
    //  transform: translate(calc(-200% - 2.4rem), 0);
    //  transition-delay: 50ms;
    //}
  }
}
.choose-listing-head-no-images {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  color: rgba(#100b28, 0.5);
  line-height: 1.4rem;
  font-size: 1.1rem;
  word-break: break-word;
  border: 0.1rem solid #c6c5cc;
  cursor: default;
  padding: 0 0.4rem;
  text-align: center;
}

.choose-listing-head-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  animation: choose-img 240ms ease-in-out;
}
@keyframes choose-img {
  0% {
    opacity: 0;
    transform: scale(0.3);
  }
  50% {
    opacity: 1;
    transform: scale(1.05);
  }
  70% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

.choose-listing-head-texts {
  width: 100%;
  word-break: break-word;
}

.choose-listing-head-title {
  display: block;
  word-break: break-all;
  color: rgba(var(--brand-color), 1);
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 600;
  letter-spacing: 1.44px;
}

.choose-listing-head-name {
  font-size: 2.6rem;
  line-height: 3.3rem;
  text-transform: capitalize;
  margin-bottom: 0.2rem;
  font-weight: 600;
  color: rgba(var(--background), 1);
}

.choose-listing-head-info {
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: rgba(var(--background), 1);
  font-weight: 400;

  > span {
    font-weight: bold;
    padding: 0 0.4rem;
  }
}

.choose-listing-s-i-column-right {
  flex: 0 0 auto;
  display: flex;
}

.choose-listing-head-choose-column {
  display: flex;
  gap: 2rem;
  flex: 0 0 auto;
  min-width: 35.2rem;
}

.choose-listing-close {
  outline: none;
  border: none;
  background: transparent;
  width: 4.8rem;
  height: 4.8rem;
  border-radius: 50%;
  margin-left: 1.4rem;
  cursor: pointer;
  font-size: 1.2rem;
  color: rgba(var(--background), 1);
  transition: background-color 240ms;

  &:hover {
    background-color: #f0f0f1;
  }
}

.choose-listing-content-inner {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(35.2rem, 1fr));
  grid-column-gap: 3.2rem;
  grid-row-gap: 3rem;
  justify-content: center;
  width: 100%;
  //max-width: 144rem;
  //margin: 0 auto;
}
.choose-listing-content-copyright-row {
  width: 100%;
  padding-bottom: 4rem;
}

.choose-listing-card {
  background-color: #f0f0f1;
  padding: 0;
  cursor: pointer;
  height: 26.4rem;
  position: relative;
  transition: all 240ms;
  transition-timing-function: ease-in-out;
  &.active {
    padding: 2rem;
    .choose-listing-card-count {
      opacity: 1;
      transform: translate(0, -50%);
    }
    //.choose-listing-card-img-cnt{
    //  animation: choose-listing-card-img-cnt-show 240ms ease-in-out forwards;
    //}
  }
}

.choose-listing-card-img-cnt {
  position: relative;
  width: 100%;
  height: 100%;
}
//@keyframes choose-listing-card-img-cnt-show {
//  0%{
//    transform: scale(1);
//  }
//  40%{
//    transform: scale(1.05);
//  }
//  100% {
//    transform: scale(1);
//  }
//}

.choose-listing-card-img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.choose-listing-card-count {
  display: block;
  height: 2.4rem;
  line-height: 2.4rem;
  text-align: center;
  min-width: 2.4rem;
  padding: 0 0.4rem;
  position: absolute;
  background-color: rgba(var(--brand-color), 1);
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: 600;
  border-radius: 50%;
  left: 1rem;
  top: 2rem;
  transform: translate(0, 100%);
  transition: all 240ms;
  transition-timing-function: ease-in-out;
  opacity: 0;
  z-index: 10;
}

.choose-media.footer {
  padding-left: 0;
  padding-right: 0;
  margin-top: auto;
}

.choose-media-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
