.request-popup-container {
  display: flex;
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 500;
  justify-content: flex-end;
  transition: background-color 340ms linear;
  overflow: hidden;

  &.open {
    opacity: 1;
    pointer-events: initial;
    background-color: rgba(#100B28, 0.2);

    .request-popup-content {
      transform: translate(0, 0);
    }
  }

  &:not(.open) {
    pointer-events: none;
    animation: request-popup-close 340ms ease-in-out 400ms forwards;
  }
}
@keyframes request-popup-close {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.request-popup-content {
  flex: 0 0 auto;
  max-width: 49.6rem;
  width: 100%;
  box-shadow: 0 8px 16px #0F0F0F1F;
  background-color: #ffffff;
  transform: translate(100%, 0);
  transition: transform 340ms ease-in-out 120ms;
}
.request-popup-forms-container{
  display: grid;
  grid-template-rows: auto 11fr;
  width: 100%;
  height: 100%;
  min-height: 0;
  min-width: 0;
}
.request-popup-head {
  display: flex;
  width: 100%;
  height: 9rem;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem 1.2rem 1.2rem 4.8rem;
  box-shadow: 0 0.1rem 0 0 #ECEBEE;
}
.request-popup-head-column {
  flex: 0 0 auto;
  position: relative;
}
.request-popup-head-arrow{
  color: rgba(var(--background), 1);
  margin-right: 1.5rem;
  font-size: 1.2rem;
  cursor: pointer;

  @media screen and (max-width: 1919px){
    margin-right: 1.1rem;
    font-size: 0.9rem;
  }
}
.request-popup-title {
  font-size: 2rem;
  line-height: 3rem;
  word-break: break-word;
  font-weight: 600;
  color: #100B28;
  transition: transform 340ms ease-in-out;

  &.with-icon {
    align-items: center;
    display: flex;
  }
}
.request-popup-title-holder{
  height: 3rem;
  overflow: hidden;
}
.request-popup-close {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: auto;
  outline: none;
  border: none;
  width: 3.6rem;
  height: 3.6rem;
  cursor: pointer;
  font-size: 1.2rem;
  color: #100B28;
  transition: background-color 240ms ease-in-out;
  border-radius: 50%;

  &:hover {
    background-color: #100B280D;
  }
}
.full-screen-draggable {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  &:hover{
    pointer-events: none;
  }
}
.request-popup-swipe-container{
  display: flex;
  overflow: hidden;
  width: 100%;
  height: 100%;
  min-height: 0;
  min-width: 0;
}
.request-popup-section-holder{
  flex: 0 0 auto;
  display: grid;
  grid-template-rows: 11fr auto;
  width: 100%;
  height: 100%;
  min-height: 0;
  min-width: 0;
  transition: transform 340ms ease-in-out;
  transform: translate(-100%, 0);
}
.faq-swipe{
  .request-popup-title{
    transform: translate(0, -100%);
  }
  .request-popup-head-arrow {
    pointer-events: none;
  }
  .request-popup-section-holder{
    transform: initial;
  }
}
.request-popup-section {
  width: 100%;
  padding: 3.2rem 4.8rem 0;
  min-height: 0;
  overflow-x: hidden;
  overflow-y: overlay;
  scrollbar-color: #5A5A5A #DEDEDE;
  scrollbar-width: thin;
  height: 100%;

  &.sm {
    @media (min-width: 1610px) {
      padding: 2.4rem 4.8rem 0;
    }
  }
}
.request-popup-description {
  font-size: 1.5rem;
  line-height: 2.4rem;
  color: rgba(#100B28, 0.72);
  padding-right: 0.2rem;
  margin-bottom: 2.3rem;
  min-height: 7rem;
}
// forms START
.request-popup-forms-cnt {
  width: 100%;
}
.request-popup-form-row {
  width: 100%;
  position: relative;
  padding-bottom: 3.2rem;

  &.with-error {
    padding-bottom: 4.4rem;
  }
}
.request-popup-form-title {
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin-bottom: 1.4rem;
  font-weight: 510;
  word-break: break-word;

  .nowrap {
    white-space: nowrap;
  }

  .asterisks {
    color: rgba(var(--danger-color), 1);
    font-size: 1.3rem;
    margin-left: 0.2rem;
    font-weight: 510;
  }
}
.request-dropdown {
  width: 100%;
  max-width: 26.4rem;
  position: relative;

  &.open {
    .request-dropdown-content {
      opacity: 1;
      pointer-events: initial;
    }

    .request-dropdown-head {
      border-color: #100B2880;
    }
  }
}
.request-dropdown-head {
  display: flex;
  align-items: center;
  width: 100%;
  height: 4.8rem;
  border: 0.1rem solid #100B2829;
  padding: 1rem 1.6rem 1rem 2rem;
  cursor: pointer;
  transition: border-color 240ms ease-in-out, background-color 240ms ease-in-out;

  &:hover {
    border-color: #100B2852;
  }

  &.select {
    border-color: #100B2829;
    background-color: #FAFAFA;
  }
}
.request-dropdown-text {
  font-size: 1.5rem;
  line-height: 1.9rem;
  text-transform: capitalize;
  flex: 1;
}
.request-dropdown-icon {
  flex: 0 0 auto;
  font-size: 0.8rem;
  line-height: 1.9rem;
}
.request-dropdown-content {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  padding: 1rem 0.8rem;
  z-index: 100;
  background-color: #ffffff;
  box-shadow: 0 8px 16px #0F0F0F1F;
  opacity: 0;
  pointer-events: none;
  transition: opacity 240ms ease-in-out;
}
.request-popup-form-error {
  font-size: 1.3rem;
  line-height: 2rem;
  word-break: break-word;
  position: absolute;
  left: 0;
  color: rgba(var(--danger-color), 1);;
  bottom: 1.6rem;
}

.request-dropdown-row {
  display: flex;
  align-items: center;
  min-height: 4rem;
  font-size: 1.5rem;
  line-height: 1.9rem;
  letter-spacing: 0.24px;
  color: #100B28;
  padding: 1rem 1.2rem;
  transition: background-color 240ms ease-in-out;
  word-break: break-word;
  cursor: pointer;

  &:after {
    flex: 0 0 auto;
    content: "\e909";
    font-family: icomoon;
    font-size: 1.3rem;
    margin-left: auto;
    color: rgba(var(--brand-color), 1);
    opacity: 0;
    transition: opacity 240ms ease-in-out;
  }

  &:hover {
    background-color: #100B2808;
  }

  &.active {
    background-color: #100B2808;
    cursor: default;

    &:after {
      opacity: 1;
    }
  }
}
.request-form-textarea {
  display: block;
  width: 100%;
  min-height: 13rem;
  border: 0.1rem solid #100B2829;
  padding: 2rem 1.6rem;
  outline: none;
  resize: none;
  -webkit-appearance: none;
  appearance: none;
  font-size: 1.5rem;
  line-height: 2.5rem;
  color: #100B28;
  transition: background-color 240ms ease-in-out, border 240ms ease-in-out;
  box-sizing: border-box;

  &::placeholder {
    color: rgba(#100B28, 0.72);
  }

  &.success {
    background-color: #FAFAFA;
    border-color: #100B2829;
  }

  &:hover {
    border-color: #100B2852;
  }

  &:focus {
    border-color: #100B2880;
  }
}
// select file START
.request-popup-select-file-title {
  font-size: 1.6rem;
  line-height: 2rem;
  margin-bottom: 1.2rem;
  font-weight: 510;
  word-break: break-word;
}
.request-popup-select-file-description {
  font-size: 1.3rem;
  line-height: 2rem;
  color: #100B28;
  margin-bottom: 1.6rem;
}
.select-file-row {
  width: 100%;
}
.select-file-states {
  width: 100%;
  min-height: 13.6rem;
}
.select-file-state {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  border-width: 0.1rem;
  min-height: 13.6rem;
  animation: select-file-states 240ms ease-in-out;
}
@keyframes select-file-states {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.select-file-default-state {
  border-color: #100B281F;
  border-style: solid;
}
.select-file-drag-drop-state {
  border-style: dashed;
  border-color: #009cbd;
  transition: background-color 240ms ease-in-out, border 240ms ease-in-out;
  z-index: 10000000000000000000;
  &.hover,
  &:hover{
    background-color: #FAFAFA;
    border-style: solid;
    .select-file-drag-here-text {
      color: #100B28;
    }
  }

  &.error {
    border-color: rgba(var(--danger-color), 1);;

    .select-file-drag-icon {
      color: rgba(var(--danger-color), 1);;
    }
  }
}
.select-file-input {
  display: none;
}
.select-file-label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16rem;
  height: 4.8rem;
  border: 0.1rem solid #100B281F;
  margin-bottom: 1rem;
  background-color: #FAFAFA;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 0.3px;
  transition: border 240ms ease-in-out;
  cursor: pointer;

  &:hover {
    border-color: #100B2866;
  }

  &:active {
    transform: scale(0.98);
  }
}
.select-file-drag-here-text {
  font-size: 1.4rem;
  line-height: 2.2rem;
  color: rgba(#100B28, 0.72);
  max-width: 16.5rem;
  text-align: center;
  word-break: break-word;
  transition: color 240ms ease-in-out;
}
.select-file-default-text {
  animation: select-file-states 240ms ease-in-out;
}
.select-file-error-text {
  animation: select-file-states 240ms ease-in-out;
  max-width: 27rem;
  color: rgba(var(--danger-color), 1);;
  text-align: start;
  margin-top: 1.2rem;
  display: none;
  &.show{
    display: block;
  }

}
.select-file-drag-icon {
  flex: 0 0 auto;
  font-size: 4rem;
  width: 3.2rem;
  margin-bottom: 1.2rem;
  color: #009CBD;
}
.select-file-file-added-state {
  display: flex;
  gap: 1.2rem;
  width: 100%;
  animation: select-file-states 240ms ease-in-out;
}
.select-file-files-column{
  flex: 1;
  min-width: 1rem;
}
.select-file-files-row {
  display: flex;
  align-items: center;
  gap: 0.9rem;
  width: 100%;
  height: 5.6rem;
  animation: select-file-states 240ms ease-in-out;
  border: 0.1rem solid #100B2829;
  padding: 1rem 1rem 1rem 2rem;
  background-color: #FAFAFA;
  position: relative;
  &:not(:last-child){
    margin-bottom: 1rem;
  }
  &:not(:only-child):first-child{
    width: calc(100% + 6.8rem);
  }
  &:nth-last-child(3){
    width: 100% !important;
  }
  .request-popup-form-error{
    display: none;
    bottom: auto;
    top: calc(100% + 1.2rem);
  }
  &.error {
     border-color: rgba(var(--danger-color), 1);;
    .select-file-add-icon{
      color: rgba(var(--danger-color), 1);;
    }
  }
}
.select-file-add-icon{
  flex: 0 0 auto;
  font-size: 2.4rem;
  width: 1.8rem;
  color: #100B2866;
}
.select-file-row-text{
  flex: 1;
  font-size: 1.3rem;
  line-height: 1.6rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: rgba(#100B28, 0.72);
}
.select-file-delete-icon{
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 2.4rem;
  height: 2.4rem;
  font-size: 1.2rem;
  color: rgba(#100B28, 0.72);
  transition: color 240ms ease-in-out;
  z-index: 100;
  &:before{
    display: block;
    transition: transform 240ms ease-in-out;
  }
  &:hover {
    color: #100B28;
    &:before{
      transform: scale(1.333);
    }
  }
}
.select-file-plus-column{
  flex: 0 0 auto;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.select-file-add-plus-block {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  width: 5.6rem;
  height: 5.6rem;
  cursor: pointer;
  transition: color 240ms ease-in-out, border 240ms ease-in-out;
  animation: select-file-states 240ms ease-in-out;
  &.default{
    border: 0.1rem dashed #100B283D;
    color: rgba(#100B28, 0.4);
    &:hover {
      border-color: #100B2852;
      color: rgba(#100B28, 0.72);
    }
  }
  &.drag-drop{
    border: 0.1rem dashed #009CBD;
    color: #009CBD;
    &:hover {
      border: 1px solid #009CBD;
    }
  }
  &:active{
    transform: scale(0.98);
  }
}
.select-file-add-plus-icon{
  font-size: 1.2rem;
}
.select-file-add-dd-icon{
  font-size: 2.4rem;
}
// forms END
.request-popup-bottom {
  width: 100%;
  padding: 3.6rem 4.8rem 2.4rem 4.8rem;
  border-top: 0.1rem solid #ECEBEE;

  &.sm {
    @media (min-width: 1610px) {
      padding: 3.6rem 4.8rem 1.5rem 4.8rem;
    }
  }
}
.request-popup-button {
  outline: none;
  border: none;
  width: 100%;
  height: 5.6rem;
  padding: 0 1rem;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 0.32px;
  margin-bottom: 2.4rem;
  background-color: #100B28;
  color: #ffffff;
  cursor: pointer;
  transition: background-color 240ms ease-in-out, color 240ms ease-in-out;
  &.disabled {
    background-color: rgba(#100B28, 0.05);
    color: #100B28B8;
    pointer-events: none;
  }
  &:active {
    transform: scale(0.98);
  }
}
.request-popup-bottom-text {
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: rgba(#100B28, 0.72);
  text-align: center;
}
//request popup received START
.request-popup-received-container{
  display: grid;
  grid-template-rows: auto 11fr auto;
  width: 100%;
  height: 100%;
}
.request-popup-received-head{
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 1.2rem;
  height: 9rem;
}
.request-popup-received-section{
  display: flex;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: overlay;
  scrollbar-color: #5A5A5A #DEDEDE;
  scrollbar-width: thin;
  padding: 0 9.2rem;
}

.request-popup-received-texts{
  margin: auto;
  width: 100%;
  text-align: center;
}
.request-popup-received-img{
  display: inline-block;
  vertical-align: top;
  width: 6.4rem;
  height: auto;
  object-fit: contain;
  margin-bottom: 1.6rem;
}
.request-popup-received-description{
  font-size: 2rem;
  line-height: 2.6rem;
  font-weight: 600;
  color: #100B28;
  margin-bottom: 1.6rem;
}
.request-popup-received-info{
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: rgba(#100B28, 0.72);
}
.request-popup-received-bottom{
  padding: 5.6rem 4.8rem;
}
.request-popup-received-button{
  outline: none;
  border: none;
  width: 100%;
  height: 5.6rem;
  padding: 1rem;
  background-color: #100B28;
  font-size: 1.6rem;
  line-height: 2rem;
  letter-spacing: 0.32px;
  text-transform: capitalize;
  cursor: pointer;
  color: #ffffff;
  &:active{
    transform: scale(0.98);
  }
  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}
//request popup received END
//for 1440px/13 START
@media screen and (max-width: 1610px){
  .request-popup-content{
    max-width: 37.5rem;
  }
  .request-popup-head{
    height: 7.2rem;
    padding-left: 3.2rem;
  }
  .request-popup-title{
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .request-popup-title-holder{
    height: 2rem;
  }
  .request-popup-close{
    font-size: 1rem;
  }
  .request-popup-section {
    padding: 2rem 3.2rem 0 3.2rem;
  }
  .request-popup-description{
    font-size: 1.3rem;
    line-height: 2.1rem;
    margin-bottom: 1rem;
  }
  .request-popup-form-title{
    font-size: 1.3rem;
    line-height: 1.8rem;
    margin-bottom: 1.3rem;
    padding-right: 0.2rem;
  }
  .request-dropdown-head{
    height: 4.2rem;
  }
  .request-dropdown-text{
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
  .request-dropdown-row{
    font-size: 1.3rem;
    line-height: 2rem;
  }
  .request-popup-form-row{
    margin-top: 2rem;
    padding-bottom: 0.5rem;

    &.with-error {
      padding-bottom: 4.2rem;
    }
  }
  .request-popup-form-error{
    bottom: 1.7rem;
    font-size: 1.2rem;
    line-height: 2rem;
  }
  .request-form-textarea {
    padding: 1.4rem 2rem;
    font-size: 1.3rem;
    line-height: 2rem;
    min-height: 8.8rem;
  }
  .request-popup-select-file-title{
    font-size: 1.3rem;
    line-height: 1.8rem;
    margin-bottom: 0.9rem;
  }
  .request-popup-select-file-description{
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin-bottom: 1.5rem;
    letter-spacing: -0.12px;
  }
  .select-file-states{
    min-height: 11.6rem;
  }
  .select-file-state{
    min-height: 11.6rem;
  }
  .select-file-label{
    height: 4.2rem;
    font-size: 1.3rem;
    letter-spacing: 0.26px;
    margin-bottom: 0.8rem;
  }
  .select-file-files-row{
    gap: 1rem;
    &:not(:only-child):first-child {
      width: calc(100% + 6.6rem);
    }
  }
  .select-file-drag-here-text{
    font-size: 1.3rem;
    line-height: 2rem;
  }
  .select-file-file-added-state{
    gap: 1rem;
  }
  .request-popup-bottom{
    padding: 2.3rem 3.2rem 2rem 3.2rem;

    .request-popup-description {
      font-size: 1.2rem;
      line-height: 1.8rem;
      margin-bottom: 2.5rem;

      &.faq-description {
        margin-bottom: 1rem;
      }
    }
  }
  .request-popup-button{
    height: 4.2rem;
    font-size: 1.3rem;
    line-height: 2rem;
    margin-bottom: 1rem;
  }
  .request-popup-bottom-text{
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
  //request popup received START
  .request-popup-received-head{
    height: 7.2rem;
  }
  .request-popup-received-section{
    padding: 0 6.5rem;
  }
  .request-popup-received-img{
    margin-bottom: 1.8rem;
  }
  .request-popup-received-description{
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 1.2rem;
  }
  .request-popup-received-info{
    font-size: 1.3rem;
    line-height: 2rem;
  }
  .request-popup-received-bottom{
    padding: 4.5rem 3.2rem;
  }
  .request-popup-received-button{
    height: 4.2rem;
    font-size: 1.3rem;
  }
  .request-popup-select-file-row {
    margin-top: 1.2rem;
  }
  //request popup received END
}
//for 1440px/13 END
// for tablet START
@media screen and (max-width: 980px){
  .request-popup-content{
    max-width: 41.6rem;
  }
  .request-popup-head{
    height: 6.8rem;
    padding: 0.8rem 0.8rem 0.8rem 4rem;
  }
  .request-popup-title{
    font-size: 1.8rem;
    line-height: 2rem;
  }
  .request-popup-close{
    width: 4.8rem;
    height: 4.8rem;
    font-size: 1.2rem;
    &:active {
      background-color: #100B280D;
    }
  }
  .request-popup-section{
    padding: 2.5rem 4rem 0;
  }
  .request-popup-description{
    font-size: 1.4rem;
    line-height: 2.2rem;
    margin-bottom: 3.3rem;
  }
  .request-popup-form-title{
    font-size: 1.4rem;
    line-height: 2.2rem;
    margin-bottom: 1.4rem;
    padding-right: 0.4rem;
  }
  .request-dropdown-head{
    height: 4.8rem;
  }
  .request-popup-form-row{
    padding-bottom: 3.3rem;

    &.with-error {
      padding-bottom: 4.1rem;
    }
  }
  .request-dropdown-text{
    font-size: 1.4rem;
    line-height: 1.8rem;
  }
  .request-dropdown-row{
    font-size: 1.4rem;
    line-height: 2rem;
    letter-spacing: 0.22px;
  }
  .request-popup-form-error{
    bottom: 3.4rem;
    font-size: 1.2rem;
    line-height: 2rem;
  }
  .request-form-textarea{
    min-height: 13rem;
    font-size: 1.4rem;
    line-height: 2.5rem;
  }
  .request-popup-select-file-title{
    font-size: 1.4rem;
    line-height: 1.8rem;
    margin-bottom: 1.2rem;
  }
  .request-popup-select-file-description{
    line-height: 2rem;
    margin-bottom: 2.5rem;
    letter-spacing: 0;
  }
  .select-file-states{
    min-height: 13.6rem;
  }
  .select-file-state{
    min-height: 13.6rem;
  }
  .select-file-label{
    height: 4.8rem;
    font-size: 1.5rem;
    line-height: 2rem;
    letter-spacing: 0.3px;
    margin-bottom: 0.6rem;
  }
  .select-file-drag-here-text{
    font-size: 1.4rem;
    line-height: 2.2rem;
  }
  .select-file-file-added-state{
    gap: 2rem;
  }
  .select-file-files-row{
    &:not(:only-child):first-child {
      width: calc(100% + 7.6rem);
    }
  }
  .request-popup-bottom {
    padding: 4rem 4rem 2.4rem 4rem;

    .request-popup-bottom {
      margin-bottom: 4rem;
    }
  }
  .request-popup-button{
    height: 5.6rem;
    font-size: 1.6rem;
    line-height: 2rem;
    letter-spacing: 0.32px;
    margin-bottom: 1.6rem;
  }
  .request-popup-bottom-text{
    font-size: 1.3rem;
    line-height: 1.6rem;
  }
  //request popup received START
  .request-popup-received-head {
    height: 6.8rem;
    padding: 0.8rem;
  }
  .request-popup-received-section{
    padding: 0 7.5rem;
  }
  .request-popup-received-img{
    margin-bottom: 1.6rem;
  }
  .request-popup-received-description{
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
  .request-popup-received-info{
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
  .request-popup-received-bottom{
    padding: 5.6rem 4rem;
  }
  .request-popup-received-button{
    height: 5.6rem;
    font-size: 1.6rem;
  }
  //request popup received END
}
// for tablet END
// for mobile START
@media screen and (max-width: 738px) {
  .request-popup-head{
    height: 6.1rem;
    padding: 0.6rem 0.6rem 0.6rem 2.4rem;
  }
  .request-popup-section{
    padding: 1.9rem 2.4rem 2.5rem 2.4rem;

    &.support-request {
      .request-popup-description{
        margin-bottom: 2rem;
      }
      .request-popup-form-title {
        margin-bottom: 1rem;
      }
      .request-popup-select-file-row {
        margin-top: 0;
      }
      .request-popup-select-file-title {
        margin-bottom: 0.7rem;
      }
      .request-popup-select-file-description {
        margin-bottom: 2.3rem;
      }
      .select-file-state {
        padding: 3.6rem 1rem 2.4rem;
      }
    }
  }
  .request-popup-description{
    margin-bottom: 2.3rem;
  }
  .request-popup-form-row{
    padding-bottom: 2.5rem;

    &:nth-of-type(2) {
      margin-top: 0;
    }

    &.with-error {
      padding-bottom: 5.3rem;
    }
  }
  .request-dropdown-row{
    font-size: 1.5rem;
    letter-spacing: 0.24px;
  }
  .request-dropdown-text{
    font-size: 1.5rem;
    line-height: 1.9rem;
  }
  .request-popup-form-error{
    font-size: 1.3rem;
    line-height: 2rem;
    bottom: 2.5rem;
  }
  .request-popup-form-title{
    letter-spacing: -0.1px;
    padding-right: 0;
  }
  .request-popup-title {
    font-size: 1.6rem;
    line-height: 2rem;
  }
  .request-form-textarea{
    font-size: 1.5rem;
  }
  .request-popup-bottom{
    padding: 2.5rem 2.4rem 1rem 2.4rem;

    .request-popup-description {
      line-height: 2rem;
      margin-bottom: 1rem;
    }
  }
  .request-popup-button{
    font-size: 1.5rem;
    letter-spacing: 0.3px;
    margin-bottom: 1.4rem;
  }
  //request popup received START
  .request-popup-received-head{
    height: 6rem;
    padding: 0.6rem;
  }
  .request-popup-received-section{
    padding: 0 5.4rem;
  }
  .request-popup-received-description{
    font-size: 1.6rem;
    line-height: 2.2rem;
    padding: 0 0.6rem;
  }
  .request-popup-received-info{
    line-height: 2.2rem;
  }
  .request-popup-received-bottom{
    padding: 2.4rem 2.4rem 5rem 2.4rem;
  }
  .request-popup-received-button{
    font-size: 1.5rem;
    letter-spacing: 0.3px;
  }
  //request popup received END
}
// for mobile END
@media (min-width: 768px) and (max-width: 1439px) {
  .request-popup-bottom {
    .request-popup-description {
      margin-bottom: 2.3rem;
      font-size: 1.3rem;
      line-height: 2.1rem;
    }
    .request-popup-button {
      margin-bottom: 2rem;
      font-size: 1.5rem;
    }
  }
  .request-popup-description {
    margin-bottom: 2.6rem;
  }
  .request-popup-form-row {
    &:nth-of-type(2) {
      margin-top: 0;
      padding-bottom: 4rem;
    }
  }
}