.review-copy-step {
  width: 100%;
}

.review-copy-inputs-row {
  display: inline-grid;
  grid-template-columns: auto auto;
  grid-column-gap: 12.8rem;
}

.review-copy-inputs-column {
  width: 35.2rem;
  &.google {
    width: 560px;
    @media screen and (max-width: 1919px) {
      width: 432px;
    }
    @media screen and (max-width: 979px) {
      width: 320px;
    }
    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}

.review-copy-social-row {
  display: flex;
  width: 100%;
  margin-bottom: 3.2rem;
}

.review-copy-social-row-column {
  flex: 0 0 auto;
  @media screen and (max-width: 767px) {
    flex: unset;
  }
}

.review-copy-s-img-block {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4.8rem;
  height: 4.8rem;
  box-shadow: 0 1rem 2.5rem #f0f0f0;
  border-radius: 0.4rem;
  background-color: #ffffff;
}

.review-copy-s-img {
  display: block;
  max-width: 2.4rem;
  max-height: 2.4rem;
  @media screen and (max-width: 989px) and (min-width: 768px) {
    max-width: 2rem;
    max-height: 2rem;
  }
}

.review-copy-social-row-name {
  display: block;
  padding-top: 0.8rem;
  text-align: center;
  font-size: 1rem;
  line-height: 1.4rem;
  color: rgba(var(--background), 1);
  letter-spacing: 0.16px;
  text-transform: capitalize;
  &.google {
    max-width: 4.8rem;
    @media screen and (max-width: 989px) {
      line-height: 1.1rem;
    }
  }
}

.review-copy-s-plus {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  height: 4.8rem;
  color: rgba(#100b28, 0.5);
  margin: 0 0.7rem;

  > i {
    font-size: 0.8rem;
  }
}

.review-copy-input-row {
  width: 100%;
  margin-bottom: 3.2rem;

  &:not(.error) {
    &:hover {
      .review-copy-input:not(:disabled),
      .custom-editable-text {
        border-color: rgba(var(--background), 1);
        &:disabled {
          border-color: rgba(var(--background), 0.2);
        }
      }
      
    }
  }

  &.error {
    .review-copy-input,
    .custom-editable-text {
      border-color: rgba(var(--danger-color), 1);
    }

    .review-copy-input-r-title {
      color: rgba(var(--danger-color), 1);
      text-transform: none;
    }
  }
}

.review-copy-input-r-title {
  display: block;
  font-size: 1.3rem;
  line-height: 1.6rem;
  margin-bottom: 1.4rem;
  text-transform: capitalize;
  color: rgba(var(--brand-color), 1);
  word-break: break-word;
  letter-spacing: 0.21px;
  transition: color 240ms;
}

.review-copy-input {
  width: 100%;
  outline: none;
  background: transparent;
  font-size: 1.7rem;
  line-height: 2.2rem;
  color: rgba(var(--background), 1);
  font-weight: 500;
  transition: border 240ms;
  border: none;
  padding: 0 0 0.7rem 0;
  box-sizing: border-box;
  border-radius: 0;
  border-bottom: 0.1rem solid #d8d8dc;
  margin-bottom: 0.4rem;

  &::placeholder {
    color: rgba(#100b28, 0.54);
  }

  div {
    display: none !important;
  }
  &:disabled {
    -webkit-text-fill-color: rgba(var(--background), 0.5);
    opacity: 1;
  }
}

.custom-editable-text {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 30px;
  font-size: 1.7rem;
  line-height: 2.7rem;
  color: rgba(var(--background), 1);
  font-weight: 500;
  transition: border 240ms;
  border-left: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 0.1rem solid #d8d8dc;
  margin-left: 0;
  border-radius: 0;
  background: none;
  margin-right: 0;
  margin-bottom: 0.4rem;
  padding: 0 0 0.4rem 0;
  resize: none;
  box-sizing: border-box;
  word-break: break-word;
  -webkit-user-select: text;

  * {
    all: unset !important;
    //font-family: inherit !important;
    //color: inherit !important;
    //font-size: inherit !important;
    //font-weight: inherit !important;
    //line-height: inherit !important;
    //background: none !important;
    //display: block !important;
    //letter-spacing: inherit !important;
    //text-decoration: none !important;
    //text-align: left !important;
  }
  &:disabled {
    color: rgba(var(--background), 0.5);
  }
}

[contenteditable="true"]:empty:before {
  content: attr(placeholder);
  display: block;
  color: rgba(#100b28, 0.54);
  font-weight: 500;
}

.review-copy-input-r-latter-count {
  font-size: 1.1rem;
  line-height: 1.4rem;
  padding-top: 0.4rem;
  color: rgba(#100b28, 0.45);
}

.review-copy-radio-buttons-row {
  width: 110%;

  &.regular-radio-buttons
    .radio-button:checked
    + label
    .review-copy-radio-text {
    font-weight: 500;
  }
}

.review-copy-radio-buttons-title {
  display: block;
}

.review-copy-radio-form {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  font-weight: 500;
  @media screen and (max-width: 979px) {
    &:not(.call-to-action){
      flex-direction: column;
    }
    
  }
  @media screen and (max-width: 767px) {
    flex-direction: row;
  }
}

.review-copy-radio-buttons-column {
  flex: 0 0 auto;

  &:not(:last-child) {
    margin-right: 1.7rem;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }
}

.review-copy-radio-button-label {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.review-copy-radio-text {
  font-size: 1.4rem;
  line-height: 1.8rem;
  // text-transform: capitalize;
  color: rgba(#100b28, 0.74);
  transition: color 120ms;
}

.headline-info {
  font-size: 12px;
  margin-left: 6px;
  color: #a19fa9;
  vertical-align: initial;
  cursor: pointer;
}

.headline-tooltip {
  box-shadow: 0px 15px 24px #0000001f;
  .bp3-popover2-content {
    background: white !important;
    width: 186px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 768px) {
      width: 134px;
      height: 72px;
    }
  }
  &.bp3-popover2 .bp3-popover2-arrow-fill {
    fill: white;
  }
}
.headline-tooltip-content {
  letter-spacing: 0.01px;
  text-align: center;
  font-size: 12px;
  line-height: 16px;
  font-weight: 510;
  color: #100b28;
  text-transform: initial;
}

.review-copy-social-description {
  max-width: 768px;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 28.5px;
  color: #100b28;
  margin-top: 3.2rem;
  @media screen and (max-width: 1919px) {
    font-size: 14px;
    max-width: 620px;
    line-height: 2.4rem;
  }
  @media screen and (max-width: 979px) {
    font-size: 18px;
    max-width: 672px;
    line-height: 2.8rem;
  }

  @media screen and (max-width: 767px) {
    max-width: 327px;
    font-size: 13px;
    line-height: 2.1rem;
    & span {
      display: none;
    }
  }
}
