html {
  font-family: Euclid, Arial, Helvetica, sans-serif;
  //user-select: none;
  -webkit-tap-highlight-color: transparent;
  font-weight: 500;
  font-size: 10px;
  height: 100%;
  width: 100%;
  min-height: 100%;
  background-color: #fcfcfc;
  scrollbar-color: #c2c2c2 transparent;
  scrollbar-width: thin;
  touch-action: pan-x pan-y;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::-webkit-scrollbar-thumb {
  background: rgba(#c2c2c2, 0.8);
  border-radius: 4px;
  &:hover {
    background: rgba(#7d7d7e, 0.8);
  }
}
::-webkit-scrollbar-track {
  background: transparent;
  margin: 6px 0;
}
body {
  font-family: Euclid, Arial, Helvetica, sans-serif;
  height: 100%;
  position: relative;
  font-family: Euclid, Arial, Helvetica, sans-serif;
  &.overflow-hidden {
    overflow: hidden;
  }
}
#root {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 100;
}
.main-wrapper {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scrollbar-color: #5a5a5a #dedede;
  scrollbar-width: thin;
  //padding-top: 9rem;
}
.client-finder {
  display: grid;
  grid-template-rows: auto 11fr;
  min-height: 100%;
  width: 100%;
  //height: 100%;
}
.client-finder-content {
  width: 100%;
  height: 100%;
  background-color: #fcfcfc;
  &.manager-ad {
    overflow: hidden;
  }
  &:only-child {
    grid-row: span 2;
  }
}
.container {
  max-width: 150.4rem;
  width: 100%;
  margin: 0 auto;
  position: relative;
  transition: max-width 240ms;
}
.scroll-holder {
  position: relative;
  width: 100%;
  height: 100%;
}
.scrollable-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: thin;
  scrollbar-color: #5a5a5a #dedede;
}
:root {
  --brand-color: 0, 156, 189;
  --danger-color: 204, 51, 0;
  --border-color: 245, 245, 246;
  --border-color: 245, 245, 246;
  --border-light: 236, 235, 238;
  --border-dark: 239, 239, 239;
  --background: 16, 11, 40;
  --button-ad-performance: 152, 155, 161;
  --button-analytics: var(--background);
  --button-in-promotion: 152, 155, 161;
  --button-in-promotion-active: 132, 135, 142;
  --button-promote-again: 128, 206, 222;
  --button-promote-again-active: 76, 185, 208;
  --button-promote-listing: 0, 156, 189;
  --button-total-traffic: var(--background);
  --button-in-review: 132, 135, 142;
  --button-in-review-active: 152, 155, 161;
  --status-ended: var(--background);
  --status-no-ad: 152, 155, 161;
  --status-running: 0, 156, 189;
  --chart-exposure1: #323744;
  --chart-exposure2: #534b7c;
  --chart-exposure3: #019cbd;
  --chart-exposure4: #10abce;
  --chart-exposure5: #9eacd2;
  --chart-exposure6: #afd7e1;
  --chart-exposure7: #afd7e1;
  --chart-exposure8: #a5cad4;
  --chart-exposure9: #b9e3ed;
  --chart-exposure10: #a5cad4;
  --chart-exposure11: #9eacd2;
  --chart-exposure12: #9eacd2;
  --chart-exposure13: #afd7e1;
  --chart-exposure14: #b9e3ed;
  --toastify-color-success: #100b28;
}
