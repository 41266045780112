.accordion-list {
  padding-top: 3rem;

  li {
    border-bottom: 0.1rem solid #efefef;
    padding-bottom: 1.3rem;
    padding-top: 2.4rem;

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: none;
    }

    &.active {
      i.icon-down {
        opacity: 1;
        transform: rotate(180deg);
      }

      .accordion-text {
        margin-bottom: 1.2rem;
        margin-top: 1.6rem;
        overflow: visible;
        max-height: 120rem;
        opacity: 1;
      }
    }
  }

  .accordion-title {
    display: flex;
    align-items: center;
    color: rgba(var(--background), 1);
    line-height: 2.4rem;
    padding-right: 4rem;
    position: relative;
    font-size: 1.6rem;
    font-weight: 510;
    cursor: pointer;

    i {
      transition: all 0.3s ease-in-out;
      position: absolute;
      font-size: 0.8rem;
      opacity: 0.4;
      top: 0.7rem;
      right: 2rem;
    }

    &:hover {
      i {
        opacity: 1;
      }
    }
  }
//media for 1440px screen
  @media (max-width: 1919px) {
    padding-top: 2rem;

    .accordion-title {
      font-size: 1.3rem;
      line-height: 1.8rem;

      i {
        right: 1rem;
      }
    }

    li {
      padding-bottom: 1.9rem;
      padding-top: 1.6rem;

      &.active {
        padding-bottom: 1.7rem;

        .accordion-text {
          margin-top: 1.4rem;
          margin-bottom: 0;
        }
      }
    }
  }
  //media for 768px screen
  @media (max-width: 1439px) {
    padding-top: 1.4rem;

    li {
      padding-top: 2rem;
      padding-bottom: 1.8rem;

      &.active {
        .accordion-text {
          margin-top: 1rem;
          margin-bottom: 0.2rem;
        }
      }
      .accordion-title {
        font-size: 1.4rem;
        line-height: 2rem;

        i {
          right: 1rem;
          top: 0.5rem;
        }
      }

      .accordion-text {
        line-height: 2.2rem;
        font-size: 1.4rem;
      }
    }
  }
  //media for 375px screen
  @media (max-width: 766px) {
    padding-top: 0;

    .accordion-title {
      font-size: 1.4rem;
      line-height: 2rem;
    }

    li {
      padding-top: 1.6rem;
      padding-bottom: 1.9rem;
    }
  }
  }

  .accordion-text {
    transition: opacity .3s linear;
    color: rgba(var(--background), 0.72);
    line-height: 2.4rem;
    font-size: 1.5rem;
    font-weight: 500;
    overflow: hidden;
    max-height: 0;
    opacity: 0;
  }

.target-fix {
  position: fixed;
  display: block;
  left: 0;
  top: 0;
}