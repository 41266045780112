.choose-your-media-step{
  width: 100%;
}

.choose-your-media-step-title{
  width: 100%;
  max-width: 60.3rem;
  font-size: 1.8rem;
  line-height: 3.4rem;
  color: rgba(var(--background), 1);
  margin-bottom: 0.8rem;
}
.social-sites-row{
  display: inline-grid;
  grid-auto-flow: column;
  vertical-align: top;
  grid-gap: 3.2rem;
  max-width: 100%;
  padding-top: 3.2rem;
}
.social-site-column{
  flex: 0 0 auto;
  padding: 3.3rem 3.5rem 2.2rem 3.5rem;
  border: 0.1rem solid #DCDCE0;
  box-shadow: 0 1rem 2.5rem transparent;
  cursor: pointer;
  transition: all 240ms linear;
  height: 16rem;
  &.active{
    background-color: #ffffff;
    border-color: transparent;
    box-shadow: 0 1rem 2.5rem #F0F0F0;
    .social-site-img{
      &:first-child{
        opacity: 0;
      }
      &:last-child{
        opacity: 1;
      }
    }
    .social-site-c-plus{
      color: rgba(var(--background), 1);
    }
  }
  &.s-s-fb-insta{
    width: 32rem;
  }
  &.s-s-google{
    width: 16rem;
  }
}
.social-site-c-inner{
  display: flex;
  align-items: center;
  justify-content: center;
}
.social-site-c-inner-column{
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  &.plus {
    padding: 0 2.7rem;
  }
}
.social-site-c-plus{
  font-size: 1.2rem;
  color: rgba(#100B28, 0.54);
  transition: color 240ms;
}
.social-site-name{
  font-size: 1.5rem;
  line-height: 1.7rem;
  color: rgba(var(--background), 1);
  font-weight: 510;
  text-transform: uppercase;
  letter-spacing: 1.05px;
  margin-bottom: 0.8rem;
}
.social-site-images{
  position: relative;
  height: 4.8rem;
  width: 4.8rem;
  margin-bottom: 1.2rem;
}
.social-site-img{
  display: block;
  max-width: 4.8rem;
  transition: opacity 240ms;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  &:first-child{
    z-index: 10;
  }
  &:last-child{
    opacity: 0;
  }
}

.social-site-choose{
  outline: none;
  border: none;
  background: transparent;
  text-transform: capitalize;
  color: rgba(var(--brand-color), 1);
  font-size: 1.3rem;
  line-height: 1.6rem;
  width: 100%;
  cursor: pointer;
}