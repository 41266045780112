
.button {
  outline: none;
  padding: 0 1rem;
  height: 5.6rem;
  width: 100%;
  border: 0.1rem solid rgba(var(--background), 1);
  background-color: rgba(var(--background), 1);
  text-align: center;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #ffffff;
  letter-spacing: 0.32px;
  text-transform: capitalize;
  cursor: pointer;
  overflow: hidden;
  word-break: break-word;
  transition: background-color 240ms ease-in-out, color 240ms ease-in-out, border 240ms ease-in-out;
  font-weight: inherit;
  &.secondary {
    color: rgba(16, 11, 40, 0.72);
    background-color: rgba(16, 11, 40, 0.05);
    border-color: rgba(16, 11, 40, 0.05);
    font-weight: 600;
  }

  &:active {
    transform: scale(0.98);
  }

  &.disabled {
    background-color: #FCFCFC;
    border-color: rgba(#100B28, 0.26);
    color: rgba(var(--background), 1);
    cursor: default;
    pointer-events: none;
  }
}

.promote-listing {
  background-color:  rgba(var(--button-promote-listing), 1);
  border-color: rgba(var(--button-promote-listing), 1);
  //padding: 0 2.8rem;
  &.promote-again {
    background-color: rgba(var(--button-promote-again), 1);
    border-color:  rgba(var(--button-promote-again), 1);
    &:hover{
      background-color: rgba(var(--button-promote-again-active), 1);
    }
  }
  &.in-promotion{
    cursor: default;
    opacity: 1;
    background-color: rgba(var(--button-in-promotion), 1);
    border-color:  rgba(var(--button-in-promotion), 1);
    &:hover{
      background-color: rgba(var(--button-in-promotion-active), 1);
    }
  }
  &.budget-approval{
    cursor: default;
    opacity: 1;
    background-color: rgba(var(--button-in-promotion), 1);
    border-color:  rgba(var(--button-in-promotion), 1);
    &:hover{
      background-color: rgba(var(--button-in-promotion-active), 1);
    }
  }
  &.in-review{
    cursor: default;
    opacity: 1;
    background-color: rgba(var(--button-in-review), 1);
    border-color:  rgba(var(--button-in-review), 1);
    &:hover{
      background-color: rgba(var(--button-in-review-active), 1);
    }
  }
  &.medium-button{
    width: auto;
    //padding: 0 2.8rem;
    height: 4.8rem;
  }
  &.small-button{
    padding: 0 0.4rem;
    height: 3.2rem;
    font-size: 1.5rem;
    line-height: 1.9rem;
    flex: 1 1;
  }
  &:disabled{
    background-color: #CBCDD0;
    border: 0;
    cursor: default;
    pointer-events: none;
  }
}

.radio-button {
  display: none;
  &:checked + label {
    .steps-custom-radio-button {
      &:before {
        opacity: 1;
      }
    }

    .review-copy-radio-text {
      color: rgba(var(--background), 1);
      font-weight: 600;
    }
  }
}

.steps-custom-radio-button {
  flex: 0 0 auto;
  margin-right: 1.2rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  border: 0.1rem solid rgba(var(--background), 1);
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 1rem;
    height: 1rem;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: 50%;
    margin: auto;
    background-color: rgba(var(--background), 1);
    opacity: 0;
    transition: opacity 120ms ease-in-out;
  }
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  //display: none;
  //-webkit-appearance: none;
  font-size: 1.4rem;
  cursor: pointer;
}

input:-internal-autofill-selected {
  background-color: transparent !important;
  color: rgba(var(--background), 1) !important;
}

.not-clickable {
  user-select: none;
  cursor: auto;
}
.hidden-true {
  display: none !important;
  opacity: 0;
}
.spinner {
  z-index: 199;
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  background-color: #ffffffa1;

  &.cover {
    background-color: #ffffff;
  }
}
.toastBody{
  font-size: 14px;
}
.empty-card {
  position: relative;
  width: 100%;
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .empty-icon {
    color: #adadad;
    font-size: 5rem;
  }

  .empty-text {
    margin-top: 1.2rem;

    font-size: 1.6rem;
    text-align: center;
  }
}

@media screen and (max-width: 1610px) and (min-width: 980px){
  .button{
    height: 4.2rem;
    font-size: 1.3rem;
    line-height: 2rem;
    padding: 0 0.6rem;
  }
}